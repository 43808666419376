import React from 'react';

const Booking = React.lazy(() => import("../SkiUser/Booking/index"));
const BookingRequest = React.lazy(() => import("../SkiUser/Group/BookingRequest"));
const UpdateUser = React.lazy(() => import("../SkiUser/Users/UpdateUser"));
const UserManager = React.lazy(() => import("../SkiUser/Users/UserManager"));
const PropertyList = React.lazy(() => import("../SkiUser/Property/List/index"));
const PropertyWizard = React.lazy(() => import("../SkiUser/Property/FormWizard"));
const UpdateProperty = React.lazy(() => import("../SkiUser/Property/Update"));
const BookingManager = React.lazy(() => import("../SkiUser/Booking/List/index"));
const UpgradeGroup = React.lazy(() => import("../SkiUser/Group/Upgrade"));
const PurchaseSuccess = React.lazy(() => import("../SkiUser/Group/PurchaseSuccess"));
const ReceiptList = React.lazy(() => import("../SkiUser/Receipt/List"));
const ReceiptView = React.lazy(() => import("../SkiUser/Receipt/View"));
const PropertyAnalytics = React.lazy(() => import("../SkiUser/Analytics/PropertyViews"));
const UserLogin = React.lazy(() => import('../Auth/LoginScreen'));
const UserRegister = React.lazy(() => import('../Auth/RegisterScreen'));
const ForgotPassword = React.lazy(() => import('../Auth/ForgotPasswordScreen'));
const BookingsOverview = React.lazy(() => import('../SkiUser/Analytics/BookingsOverview'));
const OwnerSettlement = React.lazy(() => import('../SkiUser/Booking/OwnerSettlement/index'));
const PropertySort = React.lazy(() => import('../SkiUser/Property/Sortable/index'));
const MyAccount = React.lazy(() => import('../SkiUser/Users/MyAccount/index'));
const Product = React.lazy(() => import('../SkiUser/Product/index'));
const Admin = React.lazy(() => import('../Admin/index'));
const Settings = React.lazy(() => import('../SkiUser/Settings/index'));
const OnboardingResponse = React.lazy(() => import('../SkiUser/OnboardingResponse/index'));
const AreaList = React.lazy(() => import('../SkiUser/Area/index'));
const MasterManagement = React.lazy(() => import('../SkiUser/Master/index'));
const CleaningConfig = React.lazy(() => import('../SkiUser/CleaningConfig/index'));

const routes = [
    {
        'path': '/request/booking/:key/:id',
        'component': BookingRequest,
        'search': false
    },
    {
        'path': '/properties',
        'component': PropertyList,
        'search': false
    },
    {
        'path': '/property/create',
        'component': PropertyWizard,
        'search': false
    },
    {
        'path': '/property/sort',
        'component': PropertySort,
        'search': false
    },
    {
        'path': '/property/edit/:id',
        'component': UpdateProperty,
        'search': false
    },
    {
        'path': '/bookings',
        'component': BookingManager,
        'search': false
    },
    {
        'path': '/booking/',
        'component': Booking,
        'search': false
    },
    {
        'path': '/user/update',
        'component': UpdateUser,
        'search': false
    },
    {
        'path': '/user/product',
        'component': Product,
        'search': false
    },
    {
        'path': '/user/settings/',
        'component': Settings,
        'search': false
    },
    {
        'path': '/user/area/',
        'component': AreaList,
        'search': false
    },
    {
        'path': '/user/master/',
        'component': MasterManagement,
        'search': false
    },
    {
        'path': '/user/cleaning-configuration/',
        'component': CleaningConfig,
        'search': false
    },
    {
        'path': '/user',
        'component': UserManager,
        'search': false,
        'exact': true
    },
    {
        'path': '/upgrade',
        'component': UpgradeGroup,
        'search': false
    },
    {
        'path': '/purchase',
        'component': PurchaseSuccess,
        'search': false
    },
    {
        'path': '/receipts',
        'component': ReceiptList,
        'search': false
    },
    {
        'path': '/receipt/:id',
        'component': ReceiptView,
        'search': false
    },
    {
        'path': '/analytics',
        'component': PropertyAnalytics,
        'search': false
    },
    {
        'path': '/login',
        'component': UserLogin,
        'search': false
    },
    {
        'path': '/register',
        'component': UserRegister,
        'search': false
    },
    {
        'path': '/forgot-password',
        'component': ForgotPassword,
        'search': false
    },
    {
        'path': '/booking-overview',
        'component': BookingsOverview,
        'search': false
    },
    {
        'path': '/min-sida',
        'component': MyAccount,
        'search': false
    },
    {
        'path': '/connect-onboarding',
        'component': OnboardingResponse,
        'search': false
    },
    {
        'path': '/owner-settlement',
        'component': OwnerSettlement,
        'search': false
    },
    {
        'path': '/admin',
        'component': Admin,
        'search': false
    }
];
export default routes;