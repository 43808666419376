import React from 'react';
import FormContext from './FormContext';

const ContextForm = ({ source, errMsgShow, errors, onSubmit, children }) => {
    errors = errors? errors: {};
    return (<form method="POST" onSubmit={onSubmit}>
        <FormContext.Provider value={{ source, errMsgShow, errors }}>
            {children}
        </FormContext.Provider>
    </form>)
}
export default ContextForm;