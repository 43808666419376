import React from 'react';
import FormContext from './FormContext';

const Submit = ({ label, onChange, ...props}) => {

    return <FormContext.Consumer>
        {context => {
            return <button className='mr-10 skibtn btn-secondary' type="submit" {...props}>{label}</button>
        }}
    </FormContext.Consumer>
}
export default Submit;