import React from 'react'
import { isEmpty } from '../../helpers';

function Tabs(props) {
    
    let { setState, isTab, providerSiteFromKey, prices, property } = props
    
    function clickOut() {
        let { property } = props;
		var url = property.InfoUrl;
		if(url && (url.indexOf('360online.se') > 0 || url.indexOf('safsen') > 0)) {
			url = "http://" +providerSiteFromKey(property.ProviderKey);
		}
		
        let modalProperty = {
            Url: url,
            Name: property.Name,
            ProviderKey: property.ProviderKey,
			Image: property.Image,
			IsPayingPartner: property.IsPayingPartner
        };
        setState({modalProperty});
    }

    return (
        <div className="property-info-tabs">
            <label onClick={() => setState({tabDisplay: 'INFO'})} className={`property-info-tab ${isTab('INFO')?'active':''}`}>Beskrivning</label>
            <label onClick={() => setState({tabDisplay: 'MEDIA'})} className={`property-info-tab ${isTab('MEDIA')?'active':''}`}>Bilder</label>
            {!isEmpty(prices) && 
                <label onClick={() => setState({tabDisplay: 'PRICE'})} className={`property-info-tab ${isTab('PRICE')?'active':''}`} style={{backgroundColor:'#7bb17a'}}>Priser / boka</label>}
            {!isEmpty(property.AllowStandaloneCleaning) && property.AllowStandaloneCleaning=='1' && 
                <label onClick={() => setState({tabDisplay: 'CLEANING'})} className={`property-info-tab ${isTab('CLEANING')?'active':''}`}>Städning</label>}
            {(isEmpty(prices) && property.ProviderKey !== 'EBook') &&  
                <label onClick={() => clickOut()} className={`property-info-tab ${isTab('PRICE')? 'active':''}`} style={{backgroundColor:'#7bb17a'}}>Priser / boka</label>}
            {(property.ProviderKey === 'EBook' && !isEmpty(property.MapLng) && !isEmpty(property.MapLat)) && 
                <label onClick={() => setState({tabDisplay: 'MAP'})} className={`property-info-tab ${isTab('MAP')?'active':''}`}>Karta</label>}
            {property.ProviderKey === 'EBook' && <>
                <label onClick={() => setState({tabDisplay: 'CONTACT'})} className={`property-info-tab ${isTab('CONTACT')? 'active':''}`}>Ställ en fråga</label>
            </>}
        </div>
    )
}
export default Tabs