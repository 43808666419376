import Ajax from './Ajax';
import api from '../config/api';

const FetchPartner = {
    get
}
function get(partner) {
    return new Promise((resolve, reject) => {
        let requestData = {
            operation: 'getPartner',
            UrlKey: partner
        }
        Ajax.call(api.partnerService, requestData).then(data => {
            if(data.success) {
                resolve(data.partner);
            } else {
                resolve({})
            }
        }).catch(e => reject(new Error('invalid_partner')));
    })    
}
export default FetchPartner;