import { Auth } from 'aws-amplify';

var controller;
const Ajax = {    
    call,
    abort
}
/**
 * calls the ajax
 */
async function call(url, body, method = "POST") {
    controller = new AbortController();
    var signal = controller.signal;
    const headers = { "Content-Type": "application/json" };
    var request = await getToken(body, headers);
    body = JSON.stringify(request);    
    return fetch(url, { signal, method, headers, body })
        .then(res => res.text())        
        .then(response => {            
            if(response.length > 0) {
                try {
                    return JSON.parse(response);
                } catch(e) {
                    return {}
                }                
            } else {
                return {};
            }
        }).catch(error => {throw new Error(error)});
}

async function getToken(body, headers) {
    return new Promise((resolve, reject) => {
        if(body.noAuth) {
            delete body.noAuth;
            resolve(body);
            return;
        }
        Auth.currentSession()
            .then(data => {
                headers.Authorization = `Bearer ${data.idToken.jwtToken}`;
                resolve(body);
            })
        .catch(() => resolve(body));
    })    
}
/**
 * aborts the ajax
 */
function abort() {
    controller.abort();
}

export default Ajax;