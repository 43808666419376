import React from 'react'
import { isEmpty } from '../../helpers';
import { Row } from '../../Theme';

function createMarkup(html) { return { __html: html } }

function HeaderImage(props) {

    let { property, headerImage, images } = props

    return (<>
        {!isEmpty(images) && <Row>
            <div className="col-xs-12">
                <div style={{display:'flex',justifyContent:'center',alignItems: 'flex-end',height:'420px',backgroundPosition:'50%',backgroundSize:'cover',backgroundImage:`url(${headerImage})`}}>
                    <div style={{position:'relative',padding:'20px 50px',backgroundColor:'#fff',borderRadius:'4px 4px 0 0'}}>
                        <h3 style={center} dangerouslySetInnerHTML={createMarkup(property.Name)}></h3>
                        <div style={center}>{property.Area}</div>
                        <div style={center}>{property.Resort}, {property.Country}</div>
                    </div>
                </div>
            </div>
        </Row>}
    </>)
}
var center = {textAlign:'center'}
export default HeaderImage