import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { alertMessage } from '../actions/alertAction';

const AlertMessage = props => {

    useEffect(() => {
        return () => props.dispatch(alertMessage({type: '', message: ''}));
    }, [])

    useEffect(() => {
        let { message } = props.alert;
        if(message !== '') {
            setTimeout(() => props.dispatch(alertMessage({type: '', message: ''})), 5000);
        }
    }, [props.alert])

    let { type, message } = props.alert;
    if(message === '' || typeof message === undefined) {
        return null;
    }
        
    return (
        <div className={`alert alert-${type}`}>
            {message}
        </div>
    )
}

const mapStateToProps = state => ({ alert: state.alert })
export default connect(mapStateToProps)(AlertMessage);