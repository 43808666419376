import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { isEmpty, PostLoading, propertyInfo, providerSiteFromKey } from '../../helpers';
import { Row } from '../../Theme';
import PropertyInfoSearch from '../Components/PropertyInfoSearch';
import PriceDisplay from '../Components/PriceDisplay';
import PropertyInfoError from '../Components/PropertyInfoError';
import PropertyModal from './PropertyModal';

const ContactOwner = React.lazy(() => import('../Components/ContactOwner'));
const PropertyOnSale = React.lazy(() => import('../Components/PropertyOnSale'));
const PagePassword = React.lazy(() => import('../Components/PagePassword'));
const MapLocation = React.lazy(() => import('../Components/MapLocation'));

function createMarkup(html) {
    return { __html: html };
}

function PropertyInfo(props) {

    var [ state, setState ] = useState({
        property: null,
        keyWords: [],
        images: [],
        error: false,
        modalProperty: {},
        prices: {},
        loading: true,
        protectedPage: false,
        verifyEntity: '',
        tabDisplay: 'MAIN',
        showSearchBox:false
    });

    const { property, error, prices, loading, protectedPage, verifyEntity,
        tabDisplay, images, showSearchBox, keyWords } = state;

    useEffect(() => {
        fetchProperty();
    }, [props.slug])

    /** fetches the property */
    function fetchProperty() {
        setState(prev => ({...prev, loading: true }));
        let updateAnalytics = true;
        propertyInfo(props.slug, false, updateAnalytics)
            .then(info => setState(prev => ({...prev, ...info, loading: false})))
            .catch(() => setState(prev => ({...prev, error: true, loading: false})))
    }

    function passwordVerified() {
        setState(prev => ({...prev, protectedPage: false }));
        fetchProperty();
    }
	
	function keyWordIcon(keyword) {
		keyword = keyword.toLowerCase();
		
		if(keyword.indexOf('bastu') > -1) return 'hot-tub';
		if(keyword.indexOf('jacuzzi') > -1) return 'hot-tub';
		if(keyword.indexOf('garage') > -1) return 'parking';
		if(keyword.indexOf('parkering') > -1) return 'parking';
		if(keyword.indexOf('ski-in') > -1) return 'forward';
		if(keyword.indexOf('ski in') > -1) return 'forward';
		if(keyword.indexOf('ski-out') > -1) return 'forward';
		if(keyword.indexOf('ski out') > -1) return 'forward';
		if(keyword.indexOf('kaffe') > -1) return 'coffee';
		if(keyword.indexOf('tv') > -1) return 'tv';	
		if(keyword.indexOf('förråd') > -1) return 'door-closed';	
		if(keyword.indexOf('skåp') > -1) return 'door-closed';
		if(keyword.indexOf('eldstad') > -1) return 'fire-alt';	
		if(keyword.indexOf('kamin') > -1) return 'fire';	
		if(keyword.indexOf('sovrum') > -1) return 'bed';	
		if(keyword.indexOf('vatten') > -1) return 'tint';
		if(keyword.indexOf('micro') > -1) return 'minus-square';
		if(keyword.indexOf('bestick') > -1) return 'utensils';
		if(keyword.indexOf('disk') > -1) return 'utensils';
		if(keyword.indexOf('soffa') > -1) return 'couch';
		if(keyword.indexOf('torkskåp') > -1) return 'mitten';
		if(keyword.indexOf('frys') > -1) return 'snowflake';
		if(keyword.indexOf('ugn') > -1) return 'minus-square';
		if(keyword.indexOf('utemöbler') > -1) return 'chair';
		if(keyword.indexOf('säng') > -1) return 'bed';
		
		return 'check';
    }
    
    function setTab(tab) {
        setState(prev => ({...prev, tabDisplay: tab }));
    }

    function tabClasses(tab) {
        return `property-info-tab ${tabDisplay === tab? 'active': ''}`
    }

    function printLabels() {
        return <ul>
            {keyWords.map((item, index) =>
                <li key={index} style={{ listStyle:'none' }}>
                    <i style={{width:'20px',color:'#032bab',marginRight:'10px'}} className={`fas fa-${keyWordIcon(item)}`}></i>
                    <span dangerouslySetInnerHTML={createMarkup(item)} />
                </li>
            )}
            {!isEmpty(property.labels) && <>
                {property.labels.map((item, index) =>
                    <li key={index} style={{listStyle:'none'}}>
                        <i style={{width:'20px',color:'#032bab',marginRight:'10px'}} className={`fas fa-${keyWordIcon(item.Value)}`}></i>
                        {item.LabelName} {item.Value !== 'true' && `: ${item.Value}`}
                    </li>
                )}
            </>}
        </ul>
    }

    function clickOut() {
        var url = property.InfoUrl;
		if(url && (url.indexOf('360online.se') > 0 || url.indexOf('safsen') > 0)) {
			url = "http://" +providerSiteFromKey(property.ProviderKey);
		}
		
        let modalProperty = {
            Url: url,
            Name: property.Name,
            ProviderKey: property.ProviderKey,
			Image: property.Image,
			IsPayingPartner: property.IsPayingPartner
        };
        setState(prev => ({...prev, modalProperty}));
    }

    function emptyModal() { 
        setState(prev => ({...prev, modalProperty: {} }))
    }

    var propertyKey = '';
    if(property != null) {
        propertyKey = property.PropKey? property.PropKey: property.PropertyKey;
    }
    
    return (<>
        {protectedPage && <PagePassword verifyEntity={verifyEntity} 
            passwordVerified={passwordVerified.bind(this)} />}

        {(property != null && property.ProviderKey != null) && <>
            {!isEmpty(images) && <Row>
                <div className="col-xs-12">
                    <div style={{display:'flex',justifyContent:'center',alignItems: 'flex-end',height:'420px',backgroundPosition:'50%',backgroundSize:'cover',backgroundImage:`url(${images[0].original.replace(' ','%20')})`}}>
                        <div style={{position:'relative',padding:'20px 50px',backgroundColor:'#fff',borderRadius:'4px 4px 0 0'}}>
                            <h3 style={{textAlign:'center'}} dangerouslySetInnerHTML={createMarkup(property.Name)}></h3>									
                            <div style={{textAlign:'center'}}>{property.Area}</div>
                            <div style={{textAlign:'center'}}>{property.Resort}, {property.Country}</div>
                        </div>
                    </div>
                </div>
            </Row>}
            <Row>
                <div className="col-xs-12" style={{margin:'15px',display:'flex',alignItems:'center',flexDirection:'column'}}>
                    <div className="property-info-tabs">
                        <label onClick={() => setTab('MAIN')} className={tabClasses('MAIN')}>Beskrivning</label>
                        <label onClick={() => setTab('IMAGES')} className={tabClasses('IMAGES')}>Bilder</label>								  
                        {!isEmpty(prices) && 
                        <label onClick={() => setTab('PRICE')} className={tabClasses('PRICE')} style={{backgroundColor:'#e05f03'}}>Priser / boka</label>
                        }
                        {isEmpty(prices) && 
                            <label onClick={() => clickOut()} className={tabClasses('PRICE')} style={{backgroundColor:'#e05f03'}}>Priser / boka</label>
                        }
                        {(property.ProviderKey === 'EBook' && !isEmpty(property.MapLng) && !isEmpty(property.MapLat)) &&                             
                            <label onClick={() => setTab('MAP')} className={tabClasses('MAP')}>Karta</label>		      
                        }
                        {property.ProviderKey === 'EBook' && <>
                            <label onClick={() => setTab('CONTACT')} className={tabClasses('CONTACT')}>Kontakta ägaren</label>	    
                        </>}
                    </div>
                </div>
            </Row>
            <Row>
                <div className={`col-xs-12 col-md-${showSearchBox?'9':'12'}`}>
                    <div className="property-info-panels">
                        {tabDisplay==='MAIN' && <>
                            <h3 className="no">Beskrivning</h3>
                            <div className="property-info-panel row element-linebreaks" id={'property-info-0'}>
                                <div className="col-xs-12 col-md-8">
                                    {property.AllowSale && property.AllowSale === '1' && <React.Suspense fallback=''>
                                        <PropertyOnSale property={property} />
                                    </React.Suspense>}
                            
                                    <div dangerouslySetInnerHTML={createMarkup(property.Description)}></div>
                                    Källa: {providerSiteFromKey(property.ProviderKey)}                                   
                                </div>
                                <div className="col-xs-12 col-md-4">
                                    {printLabels()}
                                </div>
                            </div>
                        </>}

                        {tabDisplay === 'IMAGES' &&
                            <div className="property-info-panel" id={'property-info-1'}>
                                <h3 className="no">Bilder</h3>
                                Bildkälla: {providerSiteFromKey(property.ProviderKey)}
                                {!isEmpty(state.images) && <ImageGallery items={state.images} thumbnailPosition={'bottom'} showFullscreenButton={false} showPlayButton={false} />}
                            </div>}

                        {tabDisplay === 'PRICE' &&
                            <div className="property-info-panel" id={'property-info-2'}>
                                {!isEmpty(prices) && <PriceDisplay property={property} {...props.match.params} prices={prices} />}
                            </div>}

                        {tabDisplay === 'MAP' &&
                            <div className="property-info-panel" id={'property-info-3'}>
                                <h3 className="no">Karta</h3>
                                <MapLocation property={property} />
                            </div>}

                        {tabDisplay === 'CONTACT' && <ContactOwner ID={property.Id} />}
                    </div>
                </div>
                {showSearchBox && <div className="col-xs-12 col-md-3">
                    <PropertyInfoSearch PropertyKey={propertyKey} ProviderKey={property.ProviderKey} PropertyName={property.Name} />				                   
                </div>}
            </Row>
        </>}
        {loading && <Loading />}
        {((property == null || property.ProviderKey === null) && !loading && !protectedPage && !error) && <>
            <h3>No records found.</h3>
        </>}
        {error === true && <Row>
            <div className="col-xs-12">
                <PropertyInfoError />
            </div>
        </Row>}
        {(!isEmpty(state.modalProperty)) && 
            <PropertyModal property={state.modalProperty} emptyModal={emptyModal.bind(this)} />}
    </>);
}
const Loading = () => (
    <div className="container">
        <div className="row">
            <div className="col-xs-12 text-center">
                <h3>Håll i dig, vi laddar önskat innehåll!</h3>
                <PostLoading />
            </div>
        </div>
    </div>

)

export default PropertyInfo;