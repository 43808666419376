import React from 'react';
import { PropertyImage } from '../../Components';
import { providerSiteFromKey, priceFormat } from '../../helpers';
import { Link } from 'react-router-dom';

function Sponsored(props) {
    let { sponsered } = props;
    return (<>
        <div className="row">
            <div className="col-xs-12" style={sponsorRow}>
                <div style={{padding: '0 15px'}}><i>Utvalda/sponsrade lediga boenden som matchar din sökning:</i></div>
                {sponsered.map((item, index) => (
                    <div className="col-xs-12 col-md-6" key={index}>
                        <div style={{padding: '20px 0'}}>
                            <div style={{position: 'relative', float: 'left'}}>
                                <PropertyImage src={item.Url} alt={item.Name} />
                                <div className="result-img-copyright">Bildkälla: {providerSiteFromKey(item.ProviderKey)}</div>
                            </div>
                            <div className="resort-heading-wrapper">
                                <img src={`/images/icons/${item.Country}-icon.png`} alt={item.Country} className="resort-country" />
                                <h4 className="resort-heading">{item.Resort} - {item.Name}</h4>
                            </div>
							{item.AccomodationType!=null && item.AccomodationType!=="" && 
                           		<div>Typ av boende: {item.AccTypeName}</div>
							}
                            <div>Pris: {priceFormat(item.Price)} {item.Currency}</div>
                            <div style={{paddingTop:'10px'}}>
                                <Link to={item.Slug} className="skibtn btn-secondary">Läs mer och boka</Link>
                            </div>
                        </div>
                    <div style={{clear:'both'}}></div>
                </div>))}
            </div>
        </div>
		<div className="row">
			<div className="col-xs-12" style={{paddingTop: '20px'}}>
				<h3 style={{marginBottom:'0px'}}>Ditt sökresultat</h3>
			</div>
		</div>
    </>);
}
const sponsorRow = {
    padding: '5px 0px 20px 0px',
    backgroundColor: '#f1f1f1'	
}
export default Sponsored;