import React, { Component } from 'react';
import { Row } from '../../Theme';
import { isEmpty, priceDisplayHeader as header, priceFormat } from '../../helpers';
import { Link } from 'react-router-dom';
import { getWeekNumber, dateSplit } from '../../lib/DateZone.js';
import PriceModal from '../Components/PriceModal';
import Ajax from '../../api/Ajax';
import api from '../../config/api';

class PriceTab extends Component {


    constructor(props) {
        super(props);
        this.state = {
            propertyID: this.props.property.Id,
            dropdown: [],
            monthlyWeeks: [],
            modalOpen: true,
            timer: 7,
            modalData: {},
            bookedDates: [],
            freeDates: []
        }
        this.prices = {};
        this.headers = {};
        this.timeOut = '';

		this.priceDisplayLinkStart = (!isEmpty(this.props.GroupPageUrl) && this.props.GroupPageUrl!=="") ? '/ebook/' +this.props.GroupPageUrl : '';		
    }

    /** fetches the price */
    componentDidMount() {
        this.prices = this.props.prices;
        this.generateDateDropdown();
        let firstDate = dateSplit(this.prices[0].arrivalDate);
        let month = firstDate.getMonth();
        let year = firstDate.getFullYear();
        this.createWeeks(month, year);
    }

    generateDateDropdown = () => {
        var dropdown = [];
        var preventDuplicate = [];
        var prices = this.prices;
        for(let i in prices) {
            let _aDate = dateSplit(prices[i].arrivalDate);
            let month = _aDate.getMonth() + 1;
            let year = _aDate.getFullYear();
            let monthYear = `${month}_${year}`;            
            if((preventDuplicate.indexOf(monthYear) === -1)) {
                preventDuplicate.push(monthYear);
                dropdown.push({ monthYear, show: _aDate.toString("MMMM yyyy") });
            }
        }
        this.setState({ dropdown });
    }

    createWeeks = (month, year) => {
        let weeks = this.getWeeksInMonth(month, year);
        this.loopThroughWeeks(weeks, month, year);
    }

    loopThroughWeeks = (weeks, month, year) => {
        let prices = weeks.map((week, i) => {
            let date = new Date(year, month, week.start);
            return this.setPrice(date);
        });
        let bookedDates = [], freeDates = [];
        this.prices.forEach(price => {
            let aObj = dateSplit(price.arrivalDate);
            let dObj = dateSplit(price.departureDate);
            let aDate = aObj.getTime();
            let dDate = dObj.getTime();
            if(price.BookingID != null && price.BookingID !== '') {
                if((dDate - aDate) / (1000 * 3600 * 24) === 7 && aObj.getDay() === 0) {
                    let thursday = aObj.setDate(aObj.getDate() + 4);
                    bookedDates.push({ aDate, dDate: thursday, arrivalDate: new Date(aDate).toString('yyyy-MM-dd'), departureDate: new Date(thursday).toString('yyyy-MM-dd') });
                    let nextSunday = aObj.setDate(aObj.getDate() + 3);
                    bookedDates.push({ aDate: thursday, dDate: nextSunday, arrivalDate: new Date(thursday).toString('yyyy-MM-dd'), departureDate: new Date(nextSunday).toString('yyyy-MM-dd') });
                } else {
                    bookedDates.push({ aDate, dDate, arrivalDate: price.arrivalDate, departureDate: price.departureDate });
                }
            } else {
                freeDates.push({ aDate, dDate, arrivalDate: price.arrivalDate, departureDate: price.departureDate });
            }
        });
        let _dates = bookedDates.filter((d, index, self) =>
            index === self.findIndex((t) => (
                t.aDate === d.aDate && t.dDate === d.dDate
            ))
        )
        bookedDates = [];
        _dates.forEach(d => {
            var found = false;
            this.prices.forEach(price => {
                if(d.aDate === dateSplit(price.arrivalDate).getTime() && d.dDate === dateSplit(price.departureDate).getTime()) {
                    if(price.BookingID != null) {
                        bookedDates.push(d);
                    }
                    found = true;
                }
            });
            if(!found) {
                bookedDates.push(d);
            }
        })
        this.setState({  monthlyWeeks: prices, bookedDates, freeDates });
    }

    /** sets the price to display according to date */
    setPrice = date => {
        let { monday, sunday } = header.getWeekDaysOnly(date);
        const prices = this.prices.filter((price, i) => {
            let arrivalTime = dateSplit(price.arrivalDate).getTime();
            return (arrivalTime <= sunday.getTime() && arrivalTime >= monday.getTime());
        });
        return {headers: header.dateRange(monday, sunday), prices: prices};
    }

    getWeeksInMonth(month, year) {
        var weeks = [],
            firstDate = new Date(year, month, 1),
            lastDate = new Date(year, month + 1, 0), 
            numDays = lastDate.getDate();
        var start = 1;
        let firstDay = firstDate.getDay();
				
        var end = firstDay === 0? 1: (7 - (firstDay - 1));
        while(start <= numDays) {
            weeks.push({ start, end });
            start = end + 1;
            end = end + 7;
            if(end > numDays)
                end = numDays;    
        }        
        return weeks;
    }

    handleMonthChange = e => {
        var monthYear = e.target.value;
        monthYear = monthYear.split('_');
        let month = monthYear[0] - 1;
        let year = monthYear[1];
        this.createWeeks(month, year);
    }
	
	printDaysBefore = (date) => {
		var html = [];		
        var startDay = new Date(date);	
		var daysToAddBefore = (startDay.getDay() === 0 ? 6 : (startDay.getDay() - 1));
		for(let i = 0; i < daysToAddBefore; i++) {
			var prevMonth = new Date(startDay.getFullYear(), startDay.getMonth(), 0);
			var displayMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth(), prevMonth.getDate()-daysToAddBefore+i+1)			
			html.push(<td className="price-other-month" key={'db' +i}>{this.outputDate(displayMonth,"", "dd MMM")}</td>);
		}		
		return html;
	}
	
	printDaysAfter = (date) => {
		var html = [];		
		var lastDay = new Date(date);
		var nextMonth = new Date(lastDay.getFullYear(), lastDay.getMonth() + 1, 1);		
		var daysToAddAfter = (lastDay.getDay() === 0 ? 0 : (7 - lastDay.getDay()));
		for(let i = 0; i < daysToAddAfter; i++) {
			let displayMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), i + 1);
			html.push(<td className="price-other-month" key={'da' +i}>{this.outputDate(displayMonth, "", "dd MMM")}</td>);
		}
		return html;
	}
	
	outputDate = (date, content, format) => {
		if(!format || format==="") {format = "dd";};
		return <div><div>{date.toString(format)}</div><div>{content}</div></div>;
	}
	
	outputDateWithPrice = (date, prices, isBooked) => {
        var { property } = this.props;
        var content = [];
		if(prices && prices.length > 0) {
            var priceMatch = prices.filter(price => price.arrivalDate === date.toString("yyyy-MM-dd"));
			if(priceMatch && priceMatch.length > 0) { 
				priceMatch.forEach((price, i) => {
                    let priceToShow = price.price;
                    let origPrice = 0;
                    if(price.discount > 0) {
                        priceToShow = priceToShow - ((priceToShow / 100) * price.discount);
                        origPrice = price.price;
                    }
                    content.push(<div key={i}>
                        <div>{`${price.days} nätter`}: </div>
                        {((price.BookingID === '' || price.BookingID === null)) && <>
                            {this.allowedToBook(property)?<Link to={`${this.priceDisplayLinkStart}/book-property?propertyId=${property.Id}&dateFrom=${price.arrivalDate}&dateTo=${price.departureDate}`}>
                                {priceFormat(priceToShow)} kr &gt;
                                {origPrice > 0 && <div><s>{origPrice} kr</s></div>}
                            </Link>:(isEmpty(price.priceUrl)?<small>{priceFormat(priceToShow)} kr</small>: 
                                <small className="prop-link" onClick={() => this.openPriceModal(price)}>{priceFormat(priceToShow)} kr</small>)
                            }
                        </>}
                        {((price.BookingID !== '' && price.BookingID !== null)) && <s>{`${priceFormat(priceToShow)} kr`}</s>}
                    </div>);
				});
			}
		}
		
		return this.outputDate(date, content);
    }

    allowedToBook(property) {
        return property.PropertyType === 1 || property.PropertyType === 3
    }
    
    openPriceModal = price => {
        let { property } = this.props;
        var image = '';
        if(property.images[0]) {
            image = property.images[0].Url;
        }
        let params = {
            Url: price.priceUrl,
            Name: property.Name,
            Image: image,
            ProviderKey: property.ProviderKey
        };
        this.updateAnalytics(property);
        this.setState({ modalData: params });
    }

    updateAnalytics = property => {
        let { ProviderKey, PropKey } = property;
        let requestData = {
            operation: 'updateAnalytics',
            type: '3',
            propertyKey: PropKey, providerKey: ProviderKey,
            noAuth: true
        }
        Ajax.call(api.propAnalyticsService, requestData)
            .catch(e => '');
    }

    emptyModal = () => this.setState({modalData: {}})

    printCell(i, header, week) {
        let backgroundColor = '#ddd';
        let className = '';
        let { bookedDates, freeDates } = this.state;
        let hd = new Date(header.date);
        let headerDate = `${hd.getFullYear()}-${hd.getMonth()}-${hd.getDate()}`;
        var isBooked = false;
        if(!isEmpty(bookedDates)) {
            for(let i = 0; i < bookedDates.length; i++) {
                var bookedDate = bookedDates[i];
                let bad = new Date(bookedDate.aDate);
                let dad = new Date(bookedDate.dDate);
                let aDate = `${bad.getFullYear()}-${bad.getMonth()}-${bad.getDate()}`;
                let dDate = `${dad.getFullYear()}-${dad.getMonth()}-${dad.getDate()}`;
                if(header.date >= bookedDate.aDate && header.date < bookedDate.dDate) {
                    if(headerDate === aDate) {
                        var a = false
                        for(let i = 0; i < freeDates.length; i++) {
                            if(bookedDate.aDate === freeDates[i].dDate) {
                                a = true;
                                break;
                            }
                        }
                        className = a? 'one-intersect': ''
                    }
                    backgroundColor = '#f29f48';
					isBooked = true;
                    break;
                } else if(headerDate === dDate) {
                    var b = false
                    for(let i = 0; i < freeDates.length; i++) {
                        if(bookedDate.dDate === freeDates[i].aDate) {
                            b = true;
                            break;
                        }
                    }
                    className = b? 'two-intersect': ''
                }
            }
        }
        var date = new Date(header.date);
		if(!isBooked) {
            for(let i = 0; i < this.prices.length; i++) {
                let price = this.prices[i];
                let priceFromDate = dateSplit(price.arrivalDate);
                let priceToDate = dateSplit(price.departureDate);
                if((header.date >= priceFromDate.getTime() && header.date <= priceToDate.getTime()) && price.BookingID == null) {
                    backgroundColor = '#7bb17a';
                    break;
                }
            }
		}
        return <td key={i} style={{backgroundColor}} className={`prop-info-table-cell ${className}`}>
            {this.outputDateWithPrice(date, week.prices, isBooked)}
        </td>
    }

    render() {
        var { dropdown, monthlyWeeks } = this.state;
        return (<Row>
            {Object.keys(dropdown).length > 0 && <div className="col-xs-12" style={{marginTop:'20px'}}>
                <h3>Prislista (i kr/SEK)</h3>
                <div className="form-group row">
                    <div className="col-sm-6">
                        Välj månad:
                        <select className="form-control" onChange={this.handleMonthChange}>
                            {dropdown.map((price, i) => {
                                return <option value={price.monthYear} key={i}>{price.show}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>}
			<div className="col-xs-12" style={{marginBottom:'10px'}}>
				<div className="row">							
					<div className="col-xs-12">
					<div style={{float:'left',width:'25px',height:'25px',backgroundColor:'#7bb17a'}}></div>
					<div style={{paddingRight:'20px',paddingLeft:'5px',float:'left'}}>Ledigt</div>									
					<div style={{float:'left',width:'25px',height:'25px',backgroundColor:'#f29f48'}}></div>
					<div style={{paddingRight:'20px',paddingLeft:'5px',float:'left'}}>Upptaget</div>
					<div style={{float:'left',width:'25px',height:'25px',backgroundColor:'#ddd'}}></div>
					<div style={{paddingLeft:'5px',float:'left'}}>Ej tillgängligt</div>
					</div>
				</div>
			</div>
				<div className="col-xs-12">
                    <table className={`table table-bordered table-striped property-price-table`}>
						<thead>
							<tr className="price-calendar-row">
                                <td className="week">V.</td>
								<td>Mån</td>
								<td>Tis</td>
								<td>Ons</td>
								<td>Tor</td>
								<td>Fre</td>
								<td>Lör</td>
								<td>Sön</td>
							</tr>
						</thead>
                        {!isEmpty(monthlyWeeks) && <tbody>
                        {monthlyWeeks.map((week, i) => (
			                <React.Fragment key={i}>
                                <tr className="price-calendar-row">
                                    <td className="week">{getWeekNumber(new Date(week.headers[0].date))}</td>
                                    {this.printDaysBefore(week.headers[0].date)}
                                    {week.headers.map((header, i) => this.printCell(i, header, week))}
                                    {this.printDaysAfter(week.headers[week.headers.length - 1].date)}
                                </tr>
							</React.Fragment>
			            ))}
                        </tbody>}
					</table>
                </div>
                {(!isEmpty(this.state.modalData)) && 
                <PriceModal property={this.state.modalData} emptyModal={this.emptyModal.bind(this)} />}
            <div className="col-xs-12">Klicka på ett pris för gå vidare till bokning.</div>
        </Row>)
    }  
}
export default PriceTab;