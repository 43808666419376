import React from 'react';
import PropertySearch from '../SkiPublic/SearchProperty/PropertySearch.js';
import MediaPartner from '../SkiPublic/MediaPartner.js';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

const AppLayout = ({ children, search, location, header, footer }) => (
    <>
        <MediaPartner location={location} />
        {(typeof header === 'undefined' || header === true) && <Header />}
        {(typeof search === 'undefined' || search) && <PropertySearch />}
        {children}
        {(typeof footer === 'undefined' || footer === true) && <Footer />}
    </>
);
export default AppLayout;