import React from 'react';
import '../styles/drawer.css';

export default function drawer(props) {
    let { isOpen, style } = props;
    return <section className={`drawer ${isOpen? 'is-active is-visible': ''}`} id="drawer-name">
        {isOpen && <div className="drawer__overlay" tabIndex="-1" onClick={() => props.onClose()}></div>}
        <div className="drawer__wrapper" style={style? {...style}: {}}>
            <div className="drawer__header">
                <div className="drawer__title">{props.title}</div>
                <button className="drawer__close" onClick={() => props.onClose()} aria-label="Close Drawer"></button>
            </div>
            <div className="drawer__content">
                {props.children}
            </div>
        </div>
    </section>
}