/** returns the price with price format */
export const price = n => {
    let decPlaces = 2,
    decSeparator = ',',
    thouSeparator = '.',
    sign = n < 0 ? "-" : "";
    let i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "";
    let j;
    j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
}    

/**returns the vat */
export const vat = (country, amount, userType) => {
    if(country === 1) {
        if(userType === '1') {
            return price(amount - (amount / 1.25))
        }
    }
    return 0;
}

/**returns the net price. */
export const netPrice = (country, amount, userType) => {
    if(country === 1) {
        if(userType === '1') {
            return price(amount / 1.25)
        }            
    }
    return price(amount);
}