import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from '../helpers';
import { partnerFetch } from '../actions/partnerActions';
import FetchPartner from '../api/FetchPartner';

class MediaPartner extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            iconClass: "fa-chevron-down",
            isOpen: true
        }
    }

    /** gets the partner after mounting */
    componentDidMount() {
        this._isMounted = true;
        this.getPartner();
    }

    /** gets the partner if the route changes */
    componentDidUpdate(prevProps) {
        if(prevProps.location !== this.props.location) {
            this.getPartner();
        }
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** gets the partner */
    getPartner = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let partnerKey = params.get('partner');
        if(partnerKey !== '' && partnerKey !== null) {
            FetchPartner.get(partnerKey).then(data => this.props.dispatch(partnerFetch(data)))
        } else {
            this.props.dispatch(partnerFetch({}))
        }
    }

    /** collapse the area */
    collapse = () => {
        var { iconClass, isOpen } = this.state;
        iconClass = iconClass === 'fa-chevron-down'? 'fa-chevron-up': 'fa-chevron-down';
        isOpen = isOpen? false: true;
        this.setState({ iconClass, isOpen });
    }

    /** creates the markup before setting the HTML */
    createMarkup(html) {
        return { __html: html };
    }
    
    /** unescape the HTML */
    unescapeHtml(str) {
        var map = { amp: '&', lt: '<', le: '≤', gt: '>', ge: '≥', quot: '"', '#039': "'" };
        return str.replace(/&([^;]+);/g, (m, c) => map[c] || '');
    }

    render() {
        var { partner } = this.props;
        var { iconClass, isOpen } = this.state;
        return (
            <React.Fragment>
                {!isEmpty(partner) && <div className="media-partner-box">
                    <div className="media-partner-title">
                        <div className="row">
                            <div className="col-sm-9">
                                I samarbete med: <a href={partner.WebsiteUrl} title={partner.Name} rel="noopener noreferrer" target="_blank"><img alt={partner.Name} className="media-partner-title-logo" src={partner.LogoUrl} /></a>
                            </div>
                            <div className="col-sm-3 text-center">
                                <i className={`fa ${iconClass}`} style={{cursor: 'pointer'}} onClick={this.collapse}></i>
                            </div>
                        </div>
                    </div>
                    <div className={`${!isOpen? 'inactive': ''} media-partner-desc`}>
                    <span dangerouslySetInnerHTML={this.createMarkup(this.unescapeHtml(partner.TextDescription))}></span>
                    </div>
                </div>}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return { partner: state.partner.partner };
}
export default connect(mapStateToProps)(MediaPartner);