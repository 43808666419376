import React from "react";
import { ConfirmSignUp } from "aws-amplify-react";
import { Card, Container, Row, Fluid, FormRow } from '../Theme';
import Auth from '@aws-amplify/auth';
import Modal from 'react-responsive-modal';
import { Link } from "react-router-dom";
import { isEmpty } from "../helpers";
import { toast } from "react-toastify";

class ConfirmRegister extends ConfirmSignUp {
    
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        this.state = { 
            loading: false, 
            showConfModal: false, 
            code: '',
            errors: {},
            username: ''
        };
    }
    
    confirm() {
        let errors = this.validate();
        if(!isEmpty(errors)) {
            return false;
        }
		var username = this.usernameFromAuthData() || this.state.username;
		const { code } = this.state;
		if (!Auth || typeof Auth.confirmSignUp !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
		}
        this.setState({ loading: true });
		Auth.confirmSignUp(username, code)
			.then(() => {
                this.setState({ loading: false, showConfModal: true });
            })
			.catch(err => {
                this.setState({loading: false});
                if(err.code === 'UserNotFoundException') {
                    toast.error('Användarnamn / klient-id-kombination hittades inte');
                } else if(err.code === 'NotAuthorizedException') {
                    toast.error('Användaren kan inte bekräftas');
                } else if(err.code === 'CodeMismatchException') {
                    toast.error('Ogiltig verifieringskod, kontrollera din e-post och försök igen.');
                } else {
                    toast.error('Något gick fel, försök igen.');
                }
            });
    }
    
    validate = () => {
        const username = this.usernameFromAuthData() || this.state.username;
        const { code } = this.state;
        let errors = {};
        if(username === '') {
            errors.username = true;
        }
        if(code === '') {
            errors.code = true;
        }
        this.setState({ errors });
        return errors;
    }

    resend = () => {
		if (!Auth || typeof Auth.resendSignUp !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
        }
        const username = this.usernameFromAuthData() || this.state.username;
		Auth.resendSignUp(username)
			.then(() => toast.error('En ny kod har blivit skickad till din e-post adress!'))
			.catch(() => toast.error('Något gick fel, försök igen.'));
	}

    render() {
        let { authState } = this.props;
        if(authState !== 'confirmSignUp') { return null; }
        let { loading, showConfModal, errors } = this.state;
        const username = this.usernameFromAuthData();
        return (
            <Fluid className="page-wrapper">
                <Container>
                    <Row>
                        <div className="col-sm-8">
                            <Card>
                                <FormRow>
                                    <div className="col-xs-12">
                                        <div className="tab-title">Tack för att du registrerat dig</div>
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        <div>För oss är säkerhet av högsta prioritet och vi har därför skickat ett mail till din e-postadress. Vänligen klicka på länken i mailet för att bekräfta ditt konto. Vi ses snart igen!<br/><br/>Om du stöter på något problem så tveka inte att kontakta oss på: <a href="mailto:support@skinova.se">support@skinova.se</a></div>
                                    </div>
                                </FormRow>
							
							{false && <>
                                <FormRow>
                                    <div className={`col-sm-8 ${errors.username? 'has-error': ''}`}>
                                        <label>Användarnamn</label>
                                        <input className="form-control" id="username" key="username" name="username"
                                            onChange={e => this.setState({ username: e.target.value })} type="text" disabled={username}
                                            value={username ? username : ""} />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className={`col-sm-8 ${errors.code? 'has-error': ''}`}>
                                        <label>Bekräftelsekod</label>
                                        <input
                                            className="form-control" type="text"
                                            autoFocus
                                            placeholder='Ange din bekräftelsekod här'
                                            key="code"
                                            name="code"
                                            autoComplete="off"
                                            onChange={e => this.setState({ code: e.target.value })}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                    Har du inte fått någon kod?
                                    <div onClick={this.resend} className="duplicate-anchor1">Skicka ny bekräftelsekod</div>
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-sm-8">
                                        {loading === false && <button className="skibtn btn-secondary mr-10" type="button" onClick={this.confirm}>
                                            Bekräfta ditt konto
                                        </button>}
                                        {loading === true && <button className="skibtn btn-secondary loading-btn mr-10">
                                            <i className="fa fa-spinner fa-spin"></i>Bekräftar...
                                        </button>}
                                        <Link to="/login" className="skibtn btn-secondary">
                                            Ångra
                                        </Link>
                                    </div>
                                </FormRow>
							</>}
                            </Card>
                        </div>
                    </Row>
                    {showConfModal && <Modal open={true} onClose={() => this.setState({showConfModal: false})} 
                        blockScroll={false} closeOnOverlayClick={false}>
                        <div className="modal-wrapper">
                            <div className="modal-header">
                                <h3>Ditt konto är nu bekräftat</h3>
                            </div>
                            <div className="modal-body">
                                <FormRow>
                                    <div className="col-xs-12">
                                        Klicka på knappen nedan för att komma till inloggningssidan för att logga in med ditt nya konto.                                         
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        <Link to='/login' className="skibtn btn-secondary">Till inloggningen</Link>
                                    </div>
                                </FormRow>
                            </div>
                        </div>
                    </Modal>}
                </Container>
            </Fluid>
        );
    }
}
export default ConfirmRegister;