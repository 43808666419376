import React from "react";
import { SignUp } from "aws-amplify-react";
import Auth from '@aws-amplify/auth';
import { Card, Container, Row, Fluid, FormRow } from '../Theme';
import { isEmpty } from "../helpers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Ajax from "../api/Ajax";
import api from "../config/api";
import validate from '../lib/validate';

class Register extends SignUp {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            errors: {},
            loading: false
        }
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    /** validates the registration form */
    validate() {
        let rules = {email: {required: true, email: true}, password: {required: true}};
        let errors = validate(this.state, rules);
        this.setState({ errors });
        return errors;
    }

    /** sign up the user */
    signUp() {
        let errors = this.validate();
        if (!isEmpty(errors) > 0) {
          return false;
        }
        var { email, password } = this.state;
        const signup_info = {
            username: email,
            password: password,
            attributes: {}
        };
        this.setState({ loading: true });
        let params = {
            operation: 'validateUserRegistration',
            username: email,
            email: this.state.email,
            noAuth: true
        };
        signup_info.attributes['email'] = this.state.email;
        Ajax.call(api.eBookUserService, params)
            .then(data => {
                if(data.success) {
                    Auth.signUp(signup_info).then(data => {
                        this.setState({loading: false});
                        this.changeState('confirmSignUp', this.state.email)
                    })
                    .catch(err => {
                        this.setState({loading: false});
                        switch(err.code) {
                            case 'InvalidPasswordException':
                                toast.error('Vänligen ange ett lösenord med minst 8 tecken.');
                                break;
                            case 'UsernameExistsException':
                                toast.error('Denna e-postadress används redan. Vänligen logga in med ett befintligt konto eller använd en annan e-postadress.');
                                break;
                            case 'InvalidParameterException':
                                toast.error('Vänligen kontrollera dina uppgifter så att du angett korrekt e-post samt ett lösenord med minst 8 tecken (endast bokstäver och siffror).');
                                break;
                            default:
                                toast.error('Försök igen senare');
                        }
                    })
                } else if(data.errors) {
                    var errors = {...data.errors}
                    this.setState({ errors });
                } else {
                    toast.error('Fel vid validering av användare, försök igen.');
                }
                this.setState({loading: false});
            }).catch(() => toast.error('Fel vid validering av användare, försök igen.'));
    }

    handleInputChange = e => this.setState({[e.target.name]: e.target.value})

  render() {
      const { authState } = this.props;
      const { errors, loading } = this.state;
      if(authState !== 'signUp') { return null; }
        return (
            <Fluid className="page-wrapper">
                <Container>
                    <Row>
                        <div className="col-xs-12">
                            <Card>
                                <FormRow>
                                    <div className="col-xs-12">
                                        <div className="tab-title">Skapa nytt konto</div>
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className={`col-sm-6 ${errors.email? 'has-error': ''}`}>
                                        <div><label>E-post*</label></div>
										<div>När du registrerat dig nedan så skickar vi en kod till din e-post adress som verifikation. Var noga med att ange rätt adress!</div>
                                        <input className="form-control" id="email" key="email" name="email"
                                            onChange={this.handleInputChange} type="text" autoComplete="off" placeholder="" />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className={`col-sm-6 ${errors.password? 'has-error': ''}`}>
                                        <div><label>Lösenord*</label></div>
										<div>Ditt lösenord ska bestå av minst 8 tecken.</div>
                                        <input className="form-control" id="password" key="password" name="password"
                                            onChange={this.handleInputChange} type="password" autoComplete="off" placeholder=""/>
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        {loading === false && <button onClick={this.signUp} type='button' className="skibtn btn-secondary">
                                            Skapa konto
                                        </button>}
                                        {loading === true && <button className="skibtn btn-secondary loading-btn" type="button">
                                            <i className="fa fa-spinner fa-spin"></i>Skickar...
                                        </button>}
                                    </div>                                
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        Har du redan ett konto? <br/>Eller har du påbörjat en registrering och vill fylla i din bekräftelsekod igen? <br/>
                                        <Link to="/login">
                                             Logga in här
                                        </Link>
                                    </div>
                                </FormRow>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </Fluid>
        );
    }
}
export default Register;