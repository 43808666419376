import React, { Component } from 'react';
import { setSearchDatePicker } from '../../helpers';
import SearchDropdown from './SearchDropdown';
import $ from 'jquery';

class SearchForm extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            properties: []
        }
        this.fromDate = React.createRef();
        this.toDate = React.createRef();
    }

    /** fetches the properties after mounting */
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    componentDidUpdate(prevProps) {
        if(prevProps.fromDateFormat !== this.props.fromDateFormat) {
            this.setDatePicker();
        }
    }

    setDatePicker() {
        setSearchDatePicker(this.fromDate, this.toDate, this.setDatePickerState, this.props.fromDateFormat);
    }

    setDatePickerState = state => this.props.setDatePickerState(state);

    showFromDatePicker = () => $(this.fromDate.current).datepicker("show")
    showToDatePicker = () => {
        if( this.toDate.current.classList.contains('hasDatepicker') ) {
            $(this.toDate.current).datepicker("show");
        }
    }

    render() {
        const { destinations, quantity, fromDate, toDate } = this.props;
        return (
            <section className="search-accommodation">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <h2>Sök boende</h2>
                        </div>
                        <div className="col-lg-9">
                            <div className="search-filter-wrapper">
                                <div className="row-flex">
                                    <div className="col border-right border-right-left">
                                        <div className="form-group form-select-wrap search-wrapper">
                                            <div className="input-group-select">
                                                <SearchDropdown options={destinations} dest={true} />
                                            </div>
                                            <label>Resmål</label>
                                        </div>
                                    </div>
                                    <div className="col border-right">
                                        <div className="form-group form-select-wrap search-wrapper">
                                            <div className="input-group-select">
                                                <SearchDropdown options={quantity} qty={true} />
                                            </div>
                                            <label>Antal bäddar</label>
                                        </div>
                                    </div>
                                    <div className="col border-right">
                                        <div className="form-group fromDateErr">
                                            <label>Ankomst
                                                <i className="ski-icon-calendar from-date-icon pull-right" onClick={this.showFromDatePicker}></i>
                                            </label>
                                            <div className="input-group">
                                                <input className="color-change" type="text" name="from_date" ref={this.fromDate} 
                                                    readOnly placeholder="YYYY-MM-DD" value={fromDate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col border-right">
                                        <div className="form-group toDateErr">
                                            <label>Avresa
                                                <i className="ski-icon-calendar to-date-icon pull-right" onClick={this.showToDatePicker}></i>
                                            </label>
                                            <div className="input-group">
                                            <input className="color-change" type="text" name="to_date" ref={this.toDate} 
                                                readOnly placeholder="YYYY-MM-DD" value={toDate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group form-submit-btn">
                                            <button type="button" className="btn-submit-wrap" onClick={this.props.doSearch}><span>SÖK</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default SearchForm;