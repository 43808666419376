import React, { Component } from 'react';
import { isScriptIncluded } from '../../helpers';

class MapLocation extends Component {
    _isMounted = false;
    mapCreated = false;

    constructor(props) {
        super(props);        
        this.googleMapRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
		this.showMap();
    }

	
    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** inits the google map and includes the script */
    initMap = () => {
        let googleScript = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC986990VxpqFnuYolTpKOB_FogBznpVrA&libraries=places`;
        if(!isScriptIncluded(googleScript)) {
            const script = document.createElement("script");
            script.src = googleScript;
            document.body.appendChild(script); 

            script.addEventListener('load', () => {
                this.googleMap = this.createGoogleMap()
                this.createMarker()
            })
        } else {
            this.googleMap = this.createGoogleMap()
            this.createMarker()
        }
    }

    /** creates the google map */
    createGoogleMap = () => {
        let { MapLng, MapLat } = this.props.property;
        return new window.google.maps.Map(this.googleMapRef.current, {
            zoom: 14,
            center: {
              lat: parseFloat(MapLat),
              lng: parseFloat(MapLng),
            },
        })
    }

    /** creates the google map marker */
    createMarker = () => {
        let { MapLng, MapLat } = this.props.property;
        new window.google.maps.Marker({
            position: { lat: parseFloat(MapLat), lng: parseFloat(MapLng) },
            map: this.googleMap,
        })
    }

    showMap = () => {
		if(!this.mapCreated) {
            this.initMap();
        }
    }

    render() {        
        return (
			<div id="google-map" ref={this.googleMapRef}></div>
        );
    }
}
export default MapLocation;