import React from 'react'
import { isEmpty, providerSiteFromKey } from '../../helpers';

const PropertyOnSale = React.lazy(() => import('../Components/PropertyOnSale'));

function createMarkup(html) {
    return { __html: html };
}

function InfoTab(props) {

    function keyWordIcon(keyword) {
		keyword = keyword.toLowerCase();
		
		if(keyword.indexOf('bastu') > -1) return 'hot-tub';
		if(keyword.indexOf('jacuzzi') > -1) return 'hot-tub';
		if(keyword.indexOf('garage') > -1) return 'parking';
		if(keyword.indexOf('parkering') > -1) return 'parking';
		if(keyword.indexOf('ski-in') > -1) return 'forward';
		if(keyword.indexOf('ski in') > -1) return 'forward';
		if(keyword.indexOf('ski-out') > -1) return 'forward';
		if(keyword.indexOf('ski out') > -1) return 'forward';
		if(keyword.indexOf('kaffe') > -1) return 'coffee';
		if(keyword.indexOf('tv') > -1) return 'tv';	
		if(keyword.indexOf('förråd') > -1) return 'door-closed';	
		if(keyword.indexOf('skåp') > -1) return 'door-closed';
		if(keyword.indexOf('eldstad') > -1) return 'fire-alt';	
		if(keyword.indexOf('kamin') > -1) return 'fire';	
		if(keyword.indexOf('sovrum') > -1) return 'bed';	
		if(keyword.indexOf('vatten') > -1) return 'tint';
		if(keyword.indexOf('micro') > -1) return 'minus-square';
		if(keyword.indexOf('bestick') > -1) return 'utensils';
		if(keyword.indexOf('disk') > -1) return 'utensils';
		if(keyword.indexOf('soffa') > -1) return 'couch';
		if(keyword.indexOf('torkskåp') > -1) return 'mitten';
		if(keyword.indexOf('frys') > -1) return 'snowflake';
		if(keyword.indexOf('ugn') > -1) return 'minus-square';
		if(keyword.indexOf('utemöbler') > -1) return 'chair';
		if(keyword.indexOf('säng') > -1) return 'bed';
		
		return 'check';
    }

    let { property, keyWords } = props
    return (<>
        <div className="col-xs-12 col-md-8">
            {property.AllowSale && property.AllowSale === '1' && <React.Suspense fallback=''>
                <PropertyOnSale property={property} />
            </React.Suspense>}
            <div dangerouslySetInnerHTML={createMarkup(property.Description)}></div>
            Källa: {providerSiteFromKey(property.ProviderKey)}
        </div>
        <div className="col-xs-12 col-md-4">
            <ul>
                {keyWords.map((item, index) =>
                    <li key={index} style={{listStyle:'none'}}>
                        <i style={{width:'20px',color:'#032bab',marginRight:'10px'}} className={`fas fa-${keyWordIcon(item)}`}></i>
                        <span dangerouslySetInnerHTML={createMarkup(item)} />
                    </li>
                )}
                {!isEmpty(property.labels) && <>
                    {property.labels.map((item, index) =>
                        <li key={index} style={{listStyle:'none'}}>
                            {item.Icon? <img src={item.Icon} alt={item.LabelName} className="label-icon" />:
                            <i style={{width:'20px',color:'#032bab',marginRight:'10px'}} className={`fas fa-${keyWordIcon(item.Value)}`}></i>}
                            {item.LabelName} {item.Value !== 'true' && `: ${item.Value}`}
                        </li>
                    )}
                </>}
            </ul>
        </div>
    </>)
}
export default InfoTab