import React, { Component } from 'react';
import { isEmpty, priceFormat  } from '../../helpers';
import { FormRow, Radio, Form } from '../../Theme';
import $ from 'jquery';
import '../../styles/jquery-ui.css';
import 'jquery-ui/ui/widgets/datepicker.js';
import { Link } from 'react-router-dom';
import api from '../../config/api.js';
import Ajax from '../../api/Ajax';

class CleaningTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            propertyID: this.props.property.Id,
            cleaningPrice: this.props.cleaningPrice,
            periodStart: '',
            disabledDatesForProperty: []
        }
        //this.setBookedDatesForProperty(this.props.property.Id);
        this.periodStart = React.createRef();
        this.priceDisplayLinkStart = (!isEmpty(this.props.GroupPageUrl) && this.props.GroupPageUrl!=="") ? '/ebook/' +this.props.GroupPageUrl : '';	
    }

    componentDidMount() {
        this.setBookedDatesForProperty(this.props.property.Id);
    }

     setBookedDatesForProperty(propertyId){
        var params = {
            operation: 'bookingCleaningListFromProperty',
            propertyID: propertyId,
        };

        Ajax.call(api.bookingService, params).then(response => {
            let { cleaningPrice } = this.state;
            if(response.bookedDates) {
                this.setState({ disabledDatesForProperty: response.bookedDates });                
                this.createDatePicker(cleaningPrice.BookingRestrictDays, response.bookedDates);
            } else {
                this.createDatePicker(cleaningPrice.BookingRestrictDays, []);
            }            
        }).catch(e => e)
    }


    /** creates the date picker for period start */
    createDatePicker = (minDays, disabledDatesForProperty) => {   				
		var minDate = new Date();
		minDate.setDate(minDate.getDate() + minDays);
		
        $(this.refs.periodStart).datepicker({
            dateFormat: 'yy-mm-dd',
            showOtherMonths: true,
            firstDay: 1,
            minDate: minDate,           
            onSelect: date => {
                this.setState({periodStart: date})
            },
            beforeShowDay: date => {
                var day = date.getFullYear() +'-' +('0' +parseInt(date.getMonth()+1)).slice(-2) +'-' +('0' +date.getDate()).slice(-2);  
                var isValid = disabledDatesForProperty==null || disabledDatesForProperty.length==0 || !disabledDatesForProperty.includes(day);
                return [isValid];                                
            }
        });
    }


    render() {
        var { cleaningPrice, periodStart, propertyID, disabledDatesForProperty } = this.state;
        return (<div>
             
                <div className="row">
                    <div className='col-md-12' style={{marginBottom:'10px'}}>
                        <h3 className="no">Bokning av städ</h3>
                    </div>
                </div>
                <div className="row">
                    <div className='col-xs-12 col-md-6'>
                        <div>
                        För en bekväm avresestädning i ditt fjällboende, låt oss ta hand om städningen. Vårt erfarna team ser till att du kan avsluta din vistelse i fjällen utan oro över rengöring. Boka nu och få en problemfri avslutning på din semester.
                        </div>
                        <div style={{marginTop:'20px',fontSize:'16px'}}>
                            <h3>Pris: {priceFormat(cleaningPrice.CleaningFee)}kr </h3>
                            (med möjlighet till RUT-avdrag)
                        </div>
                    </div>

                    <div className='col-xs-12 col-md-6'>
                        <div>Välj datum för städning</div>
                        <div style= {{width:'min-content',marginTop:'10px'}}>
                            <div ref='periodStart' name="periodStart" id="periodStart"></div>
                            <div style= {{marginTop:'10px',marginBottom:'10px'}}>
                                {periodStart=='' &&<div>Vänligen välj datum i kalendern ovan</div>}
                                {periodStart!='' &&<div>Valt datum: {periodStart}</div>}
                            </div>
                            <div style= {{marginTop:'10px',marginBottom:'10px'}}>
                            <Form>
                                <Radio name="productSelect1" checked={true} value="card" title={'Avresestädning, ' +priceFormat(cleaningPrice.CleaningFee) +'kr'} />
                            </Form>
                            </div>
                            <div>
                                <label className="property-info-tab" style={{marginTop:'10px',width:'100%',textAlign:'center', backgroundColor: periodStart=='' ? '#c0c0c0': '#0352e0'}}>
                                    <Link to={`${this.priceDisplayLinkStart}/book-property?cleaning=1&propertyId=${propertyID}&dateFrom=${periodStart}`} style={{color:'#fff'}}>
                                        Boka städning                             
                                    </Link>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
export default CleaningTab;