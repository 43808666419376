import validate from '../lib/validate';

export default function propertyValidate(fields) {
    let rules = {
        Name: {required: true},
        Description: {required: true},        
        Beds: {required: true},
        ExtraBeds: {required: true},
        Country: {required: true},
        Destination: {required: true},
        Resort: {required: true},
        Area: {required: true},
        Size: {required: true, swedishNumber: true}
    };
    return validate(fields, rules);
}