import React from 'react';
import { RichText } from 'prismic-reactjs';

/** renders the dynamic content */
function Content(props) {

    /** created the markup before dangerously setting the markup */
    function createMarkup(html) {
        return { __html: html };
    }

    /** removes the unwanted special characters */
    function unescapeHtml(str) {
        var map = { amp: '&', lt: '<', le: '≤', gt: '>', ge: '≥', quot: '"', '#039': "'" };
        return str.replace(/&([^;]+);/g, (m, c) => map[c] || '');
    }
    return (
        <div className="container-fluid">
            <div className="row" style={{backgroundColor:`${props.primary['background-color']}`}}>
                <div className="container">
                    <div className={`dynamic-content-area row content-template padding-${props.primary['padding']} align-${props.primary['align']}`}>
                        {props.primary['content'][0].type !== 'preformatted' && RichText.render(props.primary['content'])}
                        {props.primary['content'][0].type === 'preformatted' && <span dangerouslySetInnerHTML={createMarkup(unescapeHtml(props.primary['content'][0].text))}></span>}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Content;