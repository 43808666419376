import React from 'react';
import { RichText } from 'prismic-reactjs';

/** renders the large campaign on the home page. */
function LargeCampaign(props) {
    return (
        <div className="container-fluid">
            <div className="row" style={{backgroundColor:`${props.primary['background-color']}`}}>
                <div className="container content-section large-campaign-template" style={{backgroundColor:'#fff'}}>
                {props.sliceItems.map((item, i) => (
                    <div className={`row large-campaign ${(i%2 === 0? 'reverse': '')}`} key={i}>
                        <div className="col-xs-12 col-md-6 image-element">
                            <div className="img-wrap" style={{width: '80%', height: '80%'}}>
                                <img src={item.image.url} alt={item.image.alt} title="" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 text-element">
                            {RichText.render(item['content'])}
                        </div>
                    </div>
                ))}                    
                </div>
            </div>
        </div>
    );
}
export default LargeCampaign;