import React from 'react';

function Description(props) {
    function createMarkup(html) {
        return { __html: html };
    }

    function strip(html) {
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
     }

    function cutString(s, n) {
        var cut = s.indexOf(' ', n);
        if(cut === -1) return s;
        return s.substring(0, cut)
    }

    var desc = props.description;
    if(!props.noStrip) {
        desc = strip(props.description);
    }
    if(props.generateSummary) {
        desc = cutString(desc, 300);
    }
    if(props.numChars) {
        desc = cutString(desc, props.numChars);
    }
    desc = createMarkup(desc);
    
    return (<span dangerouslySetInnerHTML={desc}></span>)
}

export default Description;