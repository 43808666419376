import api from '../config/api.js';
import Ajax from './Ajax';

class EbookUser {
    /**
     * gets the user by ID
     */
    getUserBySkiPortal() {
        var requestData = {
            operation: "getUserBySkiPortal",
        };
        return Ajax.call(api.eBookUserService, requestData);
    }
}
export default EbookUser;