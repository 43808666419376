import React, { Component } from 'react';
import { isEmpty } from '../../helpers';

class SearchDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            unique: Date.now()
        }
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        let { isOpen, unique } = this.state;
        let {dest, qty, options} = this.props;
        return (
            <div className={`btn-group bootstrap-select color-change ${unique}`} ref={this.wrapperRef}>
                <button type="button" className="btn dropdown-toggle btn-default" title="Alla" onClick={() => this.setState({isOpen: !isOpen})}>
                    <span className="filter-option pull-left">Alla</span>&nbsp;<span className="glyphicon glyphicon-menu-down search-caret"></span>
                </button>
                <div className={`dropdown-menu ${isOpen? 'open': ''}`}>
                    <ul className="dropdown-menu inner" role="menu">
                        {(dest && !isEmpty(options)) && options.map((destination, i) => (
                            <li className="item" onClick={this.setQuantity} key={i}>{destination.Name}</li>
                        ))}
                        {(qty && !isEmpty(options)) && options.map((qty, i) => (
                            <li className="item" onClick={this.setQuantity} key={i}>{qty}</li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}
export default SearchDropdown;