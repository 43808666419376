import Prismic from 'prismic-javascript';

function PrismicAPI(queryParams) {
    return new Promise((resolve, reject) => {
        Prismic.getApi("https://skinova.prismic.io/api/v2").then(api => {
            return api.query(
                Prismic.Predicates.any("document.type", queryParams)
            );
        }).then(response => resolve(response)).catch(() => reject(('Problem loading Data.')))
    })
}

export default PrismicAPI;