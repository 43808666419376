import api from "../config/api";
import { authHeader } from "../helpers";

const ProviderService = {
    login,
    logout,
    getTrackings
};
/**
 * calls the login API
 */
function login(username, password) {
    return new Promise((resolve, reject) => {
        let data = JSON.stringify({ operation: 'login', username, password });
        const requestData = {
            method: "POST",
            headers: authHeader(),
            body: data
        };
        return fetch(api.providerService, requestData)
            .then(response => {
                return response.json();
            }).then(response => {
                if(response.success && response.user !== 0) {
                    localStorage.setItem("provider", JSON.stringify(response.user));
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
    });
}
/**
 * logout for the provider
 */
function logout() {
    localStorage.removeItem("provider");
}
/**
 * gets the trackings of the providers
 */
function getTrackings(ProviderKey) {
    return new Promise((resolve, reject) => {
        let data = JSON.stringify({ operation: 'getTrackings', ProviderKey: ProviderKey });
        fetch(api.providerService, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data,
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if(response.success) {                    
                resolve(response);
            } else {
                resolve(false);
            }                 
        }).catch(() => {
            resolve(false);
        });
    })    
}

export default ProviderService;
