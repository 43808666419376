import React from 'react';

function Table(props) {
    return (
        <div className="table-responsive">
            <table className={`table table-bordered table-striped ${props.className || ''}`}>
                {props.cols && <thead><tr>
                {props.cols.map((col, index) => (
                        <th key={index}>{col}</th>
                ))}
                </tr></thead>}
                <tbody>{props.children}</tbody>
            </table>
        </div>
    );
}
export default Table;