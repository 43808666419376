/**
 * parses the url hash
 */
export default function parseHash(hash) {
    hash = hash.substring(1);
    var params = {}
    hash.split('/').map(hk => { 
        let temp = hk.split('='); 
        return params[temp[0]] = temp[1] 
    });
    return params;
}