export const HEADER_FETCH = "HEADER_FETCH";
export const MENU_FETCH = "MENU_FETCH";
export const FOOTER_FETCH = "FOOTER_FETCH";
export const SET_HISTORY = "SET_HISTORY";
/**
 * returns action for layout fetch init
 */
export const headerFetch = data => ({
    type: HEADER_FETCH,
    payload: {data}
});
/**
 * returns action for layout fetch success
 */
export const menuFetch = data => ({
    type: MENU_FETCH,
    payload: { data }
});
/**
 * returns action for layout fetch failed
 */
export const footerFetch = data => ({
    type: FOOTER_FETCH,
    payload: { data }
});
/**
 * returns action for setting location history
 */
export const setHistory = data => ({
    type: SET_HISTORY,
    payload: { data }
});