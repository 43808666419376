import React from 'react';
import { RichText } from 'prismic-reactjs';
import { isEmpty} from '../../helpers';

/** renders the columns */
function Columns(props) {
    return (
        <div className={`container${props.primary['full-width']===true ? '-fluid' : ''} columns-template`} style={{backgroundColor:`${props.primary['background-color']!=='' ? props.primary['background-color'] : 'unset'}`,backgroundImage:`url(${props.primary['background-image']!==null ? props.primary['background-image'].url : 'unset'})`,backgroundSize:'cover',backgroundPosition:'center',display:`${props.primary['hide']===true ? 'none' : ''}`}}>
            <div className="row equal">
                {props.sliceItems.map((item, i) => (
                    <div key={i} style={{padding:`${item['padding']}`,backgroundImage:`url(${item['background-image']!==null ? item['background-image'].url : 'unset'})`,backgroundSize:'cover',backgroundPosition:'center',minHeight:`${item['min-height']!=='' ? item['min-height'] : 'unset'}`,backgroundColor:`${item['background-color']!=='' ? item['background-color'] : 'unset'}`}} className={`${item['css-class']} col-md-${item['desktop-columns']} col-xs-${item['mobile-columns']} ${item['class-name']}`}>
						<div style={{display:'table',height:'100%',width:'100%'}}>
	                        <div style={{display:'table-cell',verticalAlign:item['vertical-align'],height:'100%',textAlign:item['content-align']}}>
	                            {RichText.render(item.content)}
								
								{!isEmpty(item['button-text']) && 
									<div className="skibtn btn-secondary">
										<a href={`${item['button-link'].url}`}>{item['button-text']}</a>
									</div>}
	                        </div>
	                    </div>
					</div>
                ))}                
            </div>
        </div>
    );
}
export default Columns;