import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

/** renders the dynamic content */

class ImageLightbox extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
	}

    render() { 		
		const { isOpen } = this.state;
        return(
		
            <div className="row">
				<div className="container">
				<div className="row">
				<span style={{cursor:'pointer',color:this.props.primary['text-color']}} onClick={() => this.setState({ isOpen: true })}>
		          {this.props.primary['link-text']}
		        </span>
		
		        {isOpen && (
		          <Lightbox
		            mainSrc={this.props.primary['image'].url}		            
		            onCloseRequest={() => this.setState({ isOpen: false })}		            
		          />
		        )}
				</div>
				</div>
            </div>
        );
    };
}
export default ImageLightbox;