import React from 'react';
import FormContext from './FormContext';
import '../../styles/checkbox.css'

const Checkbox = ({ title, name, value, checked, onChange, ...props }) => {
    
    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    function labelClickHandler() {
        if(props.labelClickHandler) {
            props.labelClickHandler();
        }
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            const errMsgShow = context? context.errMsgShow: true;
            return(<>
                <label className="check_container">                                
                    <input type="checkbox" name={name} value={value} checked={checked} onChange={changeHandler} />
                    <span className="checkedmark"></span>
                </label>
                <span className="tnc-link check-label" onClick={labelClickHandler}>{title}</span>
                {(errMsgShow && errors[name]) && <div className="help-block error">{errors[name]}</div>}
            </>)
        }}
        </FormContext.Consumer>
}
export default Checkbox;