import React from 'react';
import NewList from '../../Components/Property/NewList';
import BookingsOverviewTable from '../../Components/BookingsOverviewTable';

/** shows the random properties on the dynamic content component */
function PropertyShow(props) {
    let { overview, properties } = props.data;
    return (<>



        <NewList properties={properties} />
        {overview.monthEnds && overview.monthEnds[0].MaxDate && <BookingsOverviewTable data={overview} noBack={true} />}
    </>)
}
export default PropertyShow;