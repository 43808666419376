import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Auth } from 'aws-amplify';
import { isSearchHash } from '../helpers';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

class AmplifyBar extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { redirectTo: '' }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    signOut = () => {
        Auth.signOut()
    }

    render() {
        if(this.props.location !== "" && isSearchHash(this.props.location)) {
            return null;
        }
        let { authUser } = this.props;
        let username = authUser.userEmail? authUser.userEmail: '';
        return (<>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 text-right logout-bar">
						<div className="container">
			                <div className="row">
			                    <div className="col-sm-12">
                                    <Wrapper>
			                            <b>Inloggad som</b>: <Link to="/user" style={linkStyle}>{username}</Link> | <span className="navigable" onClick={this.signOut}>Logga ut</span>
                                    </Wrapper>
								</div>
			                </div>
			            </div>
                    </div>
                </div>
            </div>
        </>)
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.authUser.user
    }
}
const linkStyle = {
    color: '#fff'
}
const Wrapper = styled.div`
    border: 2px solid #00d1ff;
    background-color: #00d1ff;
    color: #fff;
    display: inline-block;
    padding: 7px 16px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border-radius: 15px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
`
export default connect(mapStateToProps)(AmplifyBar);