import React, {Component} from 'react';
import api from '../../config/api'
import Ajax from '../../api/Ajax';
import { datus, setSearchDatePicker, isEmpty, replaceSpecialChars } from '../../helpers';
import {Container, Row} from '../../Theme';
import SearchResults from './SearchResults';
import NoResult from './NoResult';
import SearchLoading from './SearchLoading';
import $ from 'jquery';
import config from '../../config/config';
import '../../styles/search.css';
import PartnerSearchForm from '../../Partner/SearchEngine/SearchForm';

class PropertySearch extends Component {
    _isMounted = false;
    _datePicker = false;

    constructor(props) {
        super(props);
			
        this.state = {
            fromDate: '',
            toDate: '',
            quantity: 1,
            destination: '',
            propertyKey: '',
            providerKey: '',
            groupHeader: '',
            searchGetCount: 0,
            userGroupToken: '',
            searchLoading: 0,
            searchComplete: false,
            destinations: [],
            limitType: ''
        }
        this.fromDate = React.createRef();
        this.toDate = React.createRef();
        this.fromDateFormat = '';
        this.toDateFormat = '';
        this.searchGetCount = 0;
    }

    /** sets dates before mounting */
    componentDidMount() {
        this._isMounted = true;
        if(!this.setDefaultSearch()) {
            this.initDates();
        }
        this.fetchDestinations();
        if(!this.props.viewFor) {
            setSearchDatePicker(this.fromDate, this.toDate, this.setDatePickerState, this.fromDateFormat);
        }
    }

    /** remove the loader if the route changes */
    componentDidUpdate() {
        var hash = window.location.hash;
        var {searchComplete, searchLoading} = this.state;
        if(hash === '' && (searchComplete || searchLoading !== 0)) {
            this.setState({searchLoading: 0, searchData:[], searchComplete: false});
        }
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** init the date or the date fields */
    initDates = () => {
        const { dateFrom, dateTo } = datus.defaultDate();
        this.fromDateFormat = datus.actualFormat(dateFrom);
        this.toDateFormat = datus.actualFormat(dateTo);
        this.setState({fromDate: this.fromDateFormat, toDate: this.toDateFormat});
    }

    /** callback for the date picker */
    setDatePickerState = state => {
        if(state.fromDate) {
            this.fromDateFormat = state.fromDate;
            this.setState({fromDate: state.fromDate});
        }
        if(state.toDate) {
            this.toDateFormat = state.toDate;
            this.setState({toDate: state.toDate});
        }
    }

    /** show the first datepicker */
    showFromDatePicker = () => $(this.fromDate.current).datepicker("show")

    /** shows the second datepicker */
    showToDatePicker = () => {
        if( this.toDate.current.classList.contains('hasDatepicker') ) {
            $(this.toDate.current).datepicker("show");
        }
    }

    /** fetches the destination */
    fetchDestinations = () => {
        let requestData = { CacheType: "getDestinations", Args: "" };
        Ajax.call(api.cacheService, requestData)
            .then(data => {
                let destinations = data.map(d => {
                    let _dest = d;
                    _dest.Key = replaceSpecialChars(d.Key);
                    return _dest;
                })
                this.setState({ destinations });
            })
            .catch(() => '')
    }

    /** sets the search from the URL */
    setDefaultSearch = () => {
        var { location } = window;
        if (location.href && location.href !== "") {
            var url = decodeURI(location.href);
            url = replaceSpecialChars(url);
            var match = /#search\/(\d{4}-\d{2}-\d{2})\/(\d{4}-\d{2}-\d{2})\/(\d)+\/?(\w+-\w+)?\/?(\w+)?\/?([a-zA-Z\d-]+)?/.exec(url)
            if (match != null && match.length > 2) {
                var fromDate = datus.showFormat(match[1]);
                var toDate = datus.showFormat(match[2]);
                var quantity = match[3];
                var destination = '';
                var providerKey = '';
                var propertyKey = '';
                if (match.length > 3 && match[4] !== 'NO - DEST') {
                    destination = match[4];
                }
                if (match.length > 4) {
                    providerKey = match[5];
                    propertyKey = match[6];
                }
                this.fromDateFormat = match[1];
                this.toDateFormat = match[2];
                this.setState({ fromDate, toDate, quantity, destination, providerKey, propertyKey }, () => this.doSearch());
                return true;
            }            
        }
        return false;
    }

    /** searches the properties */
    doSearch = () => {
        this.searchGetCount = 0;
        this.setState({searchLoading: Date.now(), searchData:[], searchComplete: false});
        var { quantity, destination, providerKey, propertyKey, limitType } = this.state;
        let { location } = window;
        $("#search-result-wrap").slideDown(function () {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#search-result-wrap").offset().top
            }, 2000);
        });
        var match = /group\/([^/^#]*)(#|\/|$)/.exec(location.href);
        var userGroupToken = '';
        if (match != null && match.length > 1) {
            userGroupToken = match[1];
            this.setState({userGroupToken: match[1], groupHeader: `${match[1]}'s boenden på Skinova.se`});
        } else {
            match = /ebook\/([^/^#]*)(#|\/|$)/.exec(location.href);
            if (match != null && match.length > 1) {
                userGroupToken = match[1];
                this.setState({userGroupToken: match[1]});
            }
        }
        var searchUrl = "#search/" + this.fromDateFormat + "/" + this.toDateFormat + "/" + quantity + "/";
        if(typeof destination !== 'undefined' && destination !== '') {
            searchUrl += destination;
        }
        if((typeof providerKey !== 'undefined' && providerKey !== '')) {
            searchUrl += `${providerKey}`;
            limitType = 'forProvider';
            if(typeof propertyKey !== 'undefined' && propertyKey !== '') {
                searchUrl += `/${propertyKey}`;
                limitType = 'forProperty';
            }
        }
        location.hash = replaceSpecialChars(searchUrl);
        if(this.props.hasOwnProperty('providerKey') && this.props.providerKey !== '') {
            providerKey = this.props.providerKey;
            limitType = 'forProvider';
        }
        this.setState({ limitType, Provider: providerKey });
        this.getSponsoredProperties();
        var requestData = {
            PropertyKey: propertyKey,
            DateFrom: this.fromDateFormat,
            DateTo: this.toDateFormat,
            Adults: quantity,
            Children: 0,
            ChildAges: '',
            UserGroupToken: userGroupToken,
            Destination: destination,
            ProviderKey: providerKey,
            noAuth: true,
			WorkerToken:'yes'
        };
        Ajax.call(api.searchInit, requestData).then(() => this.getSearchResults())
            .catch(() => this.setState({loading: false}))
    }

    getSponsoredProperties = () => {
        var params = {
            operation: 'getSponsoredProperties',
            DateFrom: this.fromDateFormat,
            DateTo: this.toDateFormat,
            noAuth: true
        };
        Ajax.call(api.ebspPropService, params)
            .then(data => {
                if(data.success) {
                    this.setState({ sponsered: data.properties });
                }
            }).catch(e => e);
    }

    /** gets the search results */
    getSearchResults = () => {
        let { quantity, destination, userGroupToken, providerKey, propertyKey } = this.state;
        if(this.props.hasOwnProperty('providerKey') && this.props.providerKey !== '') {
            providerKey = this.props.providerKey;
        }
        let requestData = {
            PropertyKey: propertyKey,
            DateFrom: this.fromDateFormat,
            DateTo: this.toDateFormat,
            Adults: quantity,
            Children: 0,
            ChildAges: '',
            UserGroupToken: userGroupToken,
            Destination: destination,
            ProviderKey: providerKey,
            UsePaging: true,
            PagingStart: 0,
            PagingCount: config.propertySearchPaging,
            noAuth: true
        };
        Ajax.call(api.searchGet, requestData).then(data => {
            if (!isEmpty(data)) {
                this.setState({searchComplete: true, searchData: data, searchLoading: 0 });
            } else if(this.searchGetCount > 14) {
                this.setState({searchComplete: true, searchData: [], searchLoading: 0 })
            } else {
                ++this.searchGetCount;
                setTimeout(this.getSearchResults, 3000);
            }
        }).catch(() => this.setState({searchComplete: true, searchData: [], searchLoading: 0 }))
    }

    resetProviders = () => {
        this.setState({ providerKey: '', Provider: '' }, () => {
            var searchUrl = "#search/" + this.fromDateFormat + "/" + this.toDateFormat + "/" + this.state.quantity + "/";
            let { location } = window;
            if(location.pathname.search("partner") < 0) {
                window.location.hash = searchUrl;
                window.location.reload();
            } else {
                searchUrl = `/s${searchUrl}`;
                window.location.href = searchUrl;
            }
        });
    }

    render() {
        const {fromDate, toDate, quantity, destinations, destination, searchData, 
            searchComplete, searchLoading} = this.state;
        const { viewFor, ebooking } = this.props;
        let emptySearch = isEmpty(searchData);
        return (<>
            {viewFor === 'partner'? <PartnerSearchForm {...this.state} quantity={Quantity}
                setDatePickerState={this.setDatePickerState.bind(this)} 
                fromDateFormat={this.fromDateFormat}
                toDateFormat={this.toDateFormat}
                searchGetCount={this.searchGetCount}
                doSearch={this.doSearch.bind(this)}
                />:
                <div className="container-fluid" id="search-bar">
                    <Row className="row search-filter-wrapper">
                        <Container>
                            <div className="">
                                {!ebooking && <h1 className="dm_title title-above-search"> Sök. Hitta. Jämför. Boka</h1>}
                                {ebooking && <h2 className="dm_title title-above-search" style={{marginTop:'0px'}}> Onlinebokning </h2>}
                                <div className="search-container">
                                    <div className="row-flex">
                                        {!ebooking &&
                                        <div className="col border-right">
                                            <div className="form-group form-select-wrap search-wrapper">
                                                <label>Resmål</label>
                                                <div className="input-group-select searchbox-select">
                                                    <select name="destination" className="color-change" value={destination} onChange={e => this.setState({destination: e.target.value})}>
                                                        <option value="">Alla</option>
                                                        {destinations.length > 0 && destinations.map((destination, i) => (
                                                            <option value={destination.Key} key={i}>{destination.Name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="col border-right">
                                            <div className="form-group form-select-wrap search-wrapper">
                                                <label>Antal bäddar</label>
                                                <div className="input-group-select searchbox-select">
                                                    <select name="destination" className="color-change" value={quantity} onChange={e => this.setState({quantity: e.target.value})}>
                                                        {Quantity.map((qty, i) => (
                                                            <option value={qty} key={i}>{qty}</option>
                                                        ))}
                                                    </select>
                                                </div>                                        
                                            </div>
                                        </div>
                                        <div className="col border-right">
                                            <div className="form-group fromDateErr">
                                                <label>Utresa
                                                <i className="ski-icon-calendar from-date-icon pull-right" onClick={this.showFromDatePicker}></i>
                                                </label>
                                                <div className="input-group">
                                                    <input className="color-change" type="text" name="from_date" value={fromDate} ref={this.fromDate} readOnly placeholder="YYYY-MM-DD" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col border-right">
                                            <div className="form-group toDateErr">
                                                <label>Hemresa
                                                <i className="ski-icon-calendar to-date-icon pull-right" onClick={this.showToDatePicker}></i>
                                                </label>
                                                <div className="input-group">
                                                    <input className="color-change" type="text" name="to_date" ref={this.toDate} value={toDate} readOnly placeholder="YYYY-MM-DD" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group form-submit-btn">
                                                <button type="button" onClick={this.doSearch} className="btn-submit-wrap">
                                                <span>SÖK</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!ebooking && <h4 className="text-below-search">Skandinaviens största sökmotor för boende i fjällen</h4>}
                            </div>
                        </Container>
                    </Row>
                </div>}
                <div id="search-result-wrap">
                    {searchComplete && !emptySearch && <SearchResults {...this.state} ebooking={ebooking} fromDate={this.fromDateFormat} 
                        toDate={this.toDateFormat} resetProviders={this.resetProviders.bind(this)} partnerContent={this.props.partnerContent} sponsered={this.state.sponsered} />}
                    {(searchComplete && emptySearch) && <NoResult Provider={this.state.Provider} limitType={this.state.limitType} 
                        resetProviders={this.resetProviders.bind(this)} />}
                    {searchLoading !== 0 && <SearchLoading hideLogos={(ebooking || !isEmpty(this.state.Provider))} 
                        searchLoading={searchLoading} destination={destination} />}
                </div>
        </>)
    }
}
const Quantity = Array(24).fill(0).map((e,i)=>i+1)
export default PropertySearch;