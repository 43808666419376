import React, { Component } from 'react';
import { isEmpty } from '../../helpers';
import FormContext from './FormContext';

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            unique: Date.now(),
            selectedValue: ''
        }
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    setSelectedValue = value => {
        this.setState({ isOpen: false });
        let { options, name } = this.props;
        this.props.onChange(name, options[value].value);
    }

    render() {
        let { isOpen, unique } = this.state;
        let { options, label, value } = this.props;
        let placeholder = '';
        if(value) {
            placeholder = value;
        } else if(placeholder) {
            placeholder = this.props.placeholder
        }
        return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            const errMsgShow = context? context.errMsgShow: false;
            return <>
            {label && <label>{label}</label>}
            <div className={`btn-group ${unique} select-dropdown`} ref={this.wrapperRef}>
                <button type="button" className={`btn dropdown-toggle btn-dropdown ${errors[this.props.name]? 'has-error': ''}`} title={`Select ${label}`} onClick={() => this.setState({isOpen: !isOpen})}>
                    <span className="filter-option">
                        {placeholder}
                    </span>&nbsp;
                    <span className="glyphicon glyphicon-menu-down search-caret"></span>
                </button>
                <div className={`dropdown-menu ${isOpen? 'open': ''}`}>
                    <ul className="dropdown-inner" role="menu">
                        {(!isEmpty(options)) && options.map((opt, i) => {
                            return <li className="item" onClick={() => this.setSelectedValue(i)} key={i}>{opt.text}</li>
                        })}
                    </ul>
                </div>
                {(errMsgShow && errors[this.props.name]) && <div className="help-block error">{errors[this.props.name]}</div>}
            </div>
            </>
        }}
    </FormContext.Consumer>
    }
}
export default Dropdown;