import React, { Component } from 'react';
import { isEmpty, priceFormat } from '../helpers';
import { Link } from 'react-router-dom';
import { FormRow, ScrollElement } from '../Theme';
import { getWeekNumber, dateSplit } from '../lib/DateZone.js';

class BookingsOverviewTable extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            monthlyWeeks: [],
            currentDate: new Date(),
            months: []
        }
    }

    /** mounts the component and checks whether the user has properties added or not */
    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /**fetches the data for property and bookings */
    fetchData = () => {
        let { data } = this.props;
        this.generateMonthDropdown(data.monthEnds);
        this.setState({ properties: data.properties });
        let { currentDate } = this.state;
        let month = currentDate.getMonth();
        let year = currentDate.getFullYear();
        this.createWeeks(month, year);
    }

    /**generates the months dropdown */
    generateMonthDropdown = monthEnds => {
        if(monthEnds && monthEnds[0]) {
            if(monthEnds[0].MaxDate) {
                let md = dateSplit(monthEnds[0].MaxDate)
                var months = this.getMonths(new Date(), md);
                this.setState({ months })
            }
        }
    }

    /**gets the months between the start and end date for months dropdown */
    getMonths = (startDate, endDate) => {
        var resultList = [];
		
        startDate.setDate(1);
        let lastDay =  new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
        endDate.setDate(lastDay.getDate());
        while (startDate <= endDate) {
            let month = startDate.getMonth();
            let year = startDate.getFullYear();
            var firstDay = new Date(year, month, 1);
            resultList.push({
                showMonth: `${firstDay.toString('MMM')} - ${year}`,
                dateVal: `${month}-${year}`,
            });
            startDate.setMonth(month + 1);
        }
        return resultList;
    }

    /**creates the week */
    createWeeks = (month, year) => {
        let weeks = this.getWeeksInMonth(month, year);
        this.loopThroughWeeks(weeks, month, year);
    }

    /**loop through the weeks */
    loopThroughWeeks = (weeks, month, year) => {
        this.monthDates = [];
        let monthlyWeeks = weeks.map((week, index) => {
            let addFn = Date.prototype.addDays;
            var headers = [], dateObj = {};
            let sunday = new Date(year, month, week.end);
            var current = new Date(year, month, week.start);
            var i = 0;
            while (current <= sunday) {
                let date = new Date(current);
                dateObj.timestamp = date.getTime();
                dateObj.day = date.toString("ddd");
                dateObj.showDate = date.toString("dd");
                headers[i] = { ...dateObj };
                this.monthDates.push(dateObj.timestamp);
                current = addFn.call(current, 1);                
                ++i;
            }            
            return headers;
        });
        this.setState({ monthlyWeeks });
    }

    /** gets the weeks in a month and year */
    getWeeksInMonth(month, year) {
        var weeks = [],
            firstDate = new Date(year, month, 1),
            lastDate = new Date(year, month + 1, 0), 
            numDays = lastDate.getDate();
        var start = 1;
        let firstDay = firstDate.getDay();
        var end = firstDay === 0? 1: (7 - (firstDay - 1));
        while(start <= numDays) {
            weeks.push({ start, end });
            start = end + 1;
            end = end + 7;
            if(end > numDays)
                end = numDays;
        }
        return weeks;
    }

    /** handles the month change event */
    changeMonth = e => {
        var dateYear = e.target.value.split('-');
        var month = parseInt(dateYear[0]);
        var year = parseInt(dateYear[1]);
        let date = new Date(year, month, 1);
        this.setState({ currentDate: date });
        this.createWeeks(month, year);
    }

    /** prints the week numbers */
    printWeekNumbers = ( week, index ) => {
        let w = getWeekNumber(new Date(week[0].timestamp));
        return <td colSpan={week.length} key={index} style={{fontWeight:'bold'}}><nobr>Vecka {w}</nobr></td>
    }

    /**print the days */
    printDays = weeks => {
        let html = weeks.map((week, i) => {
            return <td key={i}>{week.day}</td>
        });
        return html;
    }

    /** prints the headers */
    printHeaders = weeks => {
        let html = weeks.map((week, i) => {
            return <td key={i}>{week.showDate}</td>
        });
        return html;
    }

    /** print the rows */
    printRows = property => {
        var html = [];
        let { bookings } = property;
        html = this.monthDates.map((date, index) => {
            var matchCode = 'NO_MATCH';
            var bookingNumber = '';
            var priceInfo = [];
            var intersectClass = '';
            let dayBefore = new Date(date);
            let dayAhead = new Date(date);
            dayBefore.setDate(dayBefore.getDate() - 1);
            dayAhead.setDate(dayAhead.getDate() + 1);
            bookings.map((prop, i) => {
                let ad = prop.ArrivalDate.split('-')
                let dd = prop.DepartureDate.split('-')
                let arrivalTime = new Date(parseInt(ad[0]), parseInt(ad[1]) - 1, parseInt(ad[2]), 0, 0, 0).getTime();
                let depTime = new Date(parseInt(dd[0]), parseInt(dd[1]) - 1, parseInt(dd[2]), 23, 59, 59).getTime();
                let d = new Date(date);
                let depTimeMatch = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                let bd = new Date(parseInt(dd[0]), parseInt(dd[1]) - 1, parseInt(dd[2]), 0, 0, 0);
                if(date === arrivalTime && prop.BookingID != null) {
                    matchCode = 'BOOKED_ARRIVAL_MATCH';
                    bookingNumber = prop.BookingNumber;
                    if(bookings[i - 1]) {
                        let lastBooking = bookings[i - 1];
                        let alb = dateSplit(lastBooking.ArrivalDate);
                        let dlb = dateSplit(lastBooking.DepartureDate);
                        if(dayBefore.getTime() >= alb.getTime() && dayBefore.getTime() <= dlb.getTime()) {
                            intersectClass = lastBooking.BookingID == null? 'price-table-intersect': 'pink-bg';
                        } else {
                            intersectClass = 'pink-bg';
                        }
                    } else {
                        intersectClass = 'pink-bg';
                    }
                } else if(date === arrivalTime && prop.BookingID == null) {
                    matchCode = 'FREE_ARRIVAL_MATCH';
                    bookingNumber = prop.BookingNumber;
                    if(bookings[i - 1]) {
                        let lastBooking = bookings[i - 1];
                        let alb = dateSplit(lastBooking.ArrivalDate);
                        let dlb = dateSplit(lastBooking.DepartureDate);
                        if(dayBefore.getTime() >= alb.getTime() && dayBefore.getTime() <= dlb.getTime()) {
                            intersectClass = lastBooking.BookingID == null? 'green-bg': 'price-table-intersect1';
                        } else {
                            intersectClass = 'green-bg';
                        }
                    } else {
                        intersectClass = 'green-bg';
                    }
                    var diffInTime = bd.getTime() - arrivalTime; 
                    var diff = diffInTime / (1000 * 3600 * 24);
                    priceInfo.push(<div key={i}>
                        <div style={{color: 'black',fontWeight:'bold'}}>{Math.ceil(diff)} nätter:</div>
                            <Link key={i} to={`/book-property?propertyId=${property.Id}&dateFrom=${prop.ArrivalDate}&dateTo=${prop.DepartureDate}`} style={{color: 'black'}}>{priceFormat(prop.Price)}kr</Link>
                        </div>);
                } else if(depTimeMatch.getTime() === bd.getTime()) {
                    matchCode = 'DEPARTURE_MATCH';
                    bookingNumber = prop.BookingNumber;
                    if(bookings[i + 1]) {
                        let nextBooking = bookings[i + 1];
                        let alb = dateSplit(nextBooking.ArrivalDate);
                        let dlb = dateSplit(nextBooking.DepartureDate);
                        if(dayAhead.getTime() >= alb.getTime() && dayAhead.getTime() <= dlb.getTime()) {
                            intersectClass = nextBooking.BookingID == null? 'green-bg': 'price-table-intersect1';
                        } else {
                            intersectClass = prop.BookingID != null? 'pink-bg':'green-bg';
                        }
                    } else {
                        intersectClass = prop.BookingID != null? 'pink-bg':'green-bg';
                    }
                } else if(date > arrivalTime && date <= depTime && prop.BookingID != null) {
                    matchCode = 'BOOKED';
                    bookingNumber = prop.BookingNumber;
                } else if(date > arrivalTime && date <= depTime && prop.BookingID == null) {
                    matchCode = 'AVAILABLE_MATCH';
                }
                return true;
            });
            if(matchCode === 'BOOKED') {
                return <td key={index} title={bookingNumber} style={{textAlign:'center', backgroundColor: '#f29f48'}}><i style={{color:'#ac2c2b'}} className="fas fa-times"></i></td>
            } else if(matchCode === 'BOOKED_ARRIVAL_MATCH') {
                return (<td className={intersectClass} key={index} title={bookingNumber} style={{textAlign:'center', color: "#fff"}}><i style={{color:'#ac2c2b'}} className="fas fa-times"></i></td>)
            } else if(matchCode === 'FREE_ARRIVAL_MATCH') {
                return (<td className={intersectClass} key={index} title={bookingNumber} style={{textAlign:'center', color: "#fff"}}>
                    {priceInfo}
                </td>)
            } else if(matchCode === 'DEPARTURE_MATCH') {
                return (<td className={intersectClass} key={index} title={bookingNumber} style={{textAlign:'center'}}>
                    <i style={{color:'#ac2c2b'}} className="fas fa-times"></i>
                </td>)
            } else if(matchCode === 'AVAILABLE_MATCH') {
                return <td style={{backgroundColor: '#7bb17a'}} key={index}></td>
            } else if(matchCode === 'NO_MATCH') {
                return <td style={{backgroundColor: '#e3e0ce'}} key={index}></td>
            }
            return null;
        });
        return html;
    }

    render() {
        let { monthlyWeeks, properties, currentDate, months } = this.state;
        let selectMonth = `${currentDate.getMonth()}-${currentDate.getFullYear()}`;
        let showMonth = currentDate.toString("MMMM");        
        return (<>
            {!isEmpty(properties) && <div className="overview-table-container">
                <FormRow>
                    <div className="col-xs-8">
                        <h3>{showMonth}</h3>
                    </div>
                    {!this.props.noBack && <div className="col-xs-4 text-right">
                        <Link to="/user" className="skibtn btn-secondary mr-10">
                            <i className="fa fa-arrow-circle-left"></i>
                        </Link>
                    </div>}
                </FormRow>
                {!isEmpty(months) && <FormRow>
                    <div className="col-sm-4 col-xs-12 mbr15-767">
                        <select className="form-control" onChange={this.changeMonth} value={selectMonth}>
                            {months.map((month, i) => (
                                <option value={month.dateVal} key={i}>{month.showMonth}</option>
                            ))}
                        </select>
                    </div>                    
                </FormRow>}
                <div className="row">                    
                    <div className="col-xs-12">
                        {!isEmpty(properties) && <>
                            <ScrollElement className="table-responsive">
                                <table className={`table price-table-bordered table-bordered table-striped`}>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            {monthlyWeeks.map((weeks, index) => {
                                                return this.printWeekNumbers(weeks, index)
                                            })}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            {monthlyWeeks.map((weeks, index) => {
                                                return this.printDays(weeks)
                                            })}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            {monthlyWeeks.map((weeks, index) => {
                                                return this.printHeaders(weeks)
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {properties.map((property, index) => {
                                            return <tr key={index}>
                                                <td style={{fontWeight:'bold',border:'1px solid #ddd'}}><nobr><Link to={property.Slug}>{property.Name} {property.Size > 0? `, ${property.Size}kvm`: ''}</Link></nobr></td>                                                
                                                {this.printRows(property)}
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </ScrollElement>
                        </>}
                    </div>
                </div>
            </div>}
        </>);
    }
}

export default BookingsOverviewTable;