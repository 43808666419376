import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { AppLayout } from '../Layout';

const ProviderRoute = ({ component: Component, search, loggedIn, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                loggedIn === "provider" ? (
                    <AppLayout search={search} location={props.location.search}>
                        <Component {...props} />
                    </AppLayout>
                ) : (
                    (loggedIn === "user" ? 
                        <Redirect to={{ pathname: '/user' }} />: 
                        (loggedIn === "admin" ? 
                            <Redirect to={{ pathname: '/admin/dashboard' }} />: 
                            <Redirect to={{ pathname: '/provider/login' }} />
                        )
                    )
                )
            }
        />
    );
}

export default ProviderRoute;