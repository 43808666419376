import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css';
import {connect} from 'react-redux';

function Footer(props) {
    return (
        <footer>
            <div className="container">
                <div className="sk-footer-info">
                    <div className="sk-footer-logo">
                        <img src="/images/footer/logo.png" alt="logo" />
                    </div>
                    <p>Skandinaviens största sökmotor för boende i fjällen.<br/>
                        Här hittar du ledigt boende - inklusive priser från flera
                        skidanläggningar och bokningssajter
                    </p>
                    <h5>LÅT OSS BLI VÄNNER</h5>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/skinova.se" target="_BLANK" rel="noopener noreferrer">
                                <img src="/images/footer/fb-ion.png" alt="social icon" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/skinova.se/" target="_BLANK" rel="noopener noreferrer">
                                <img src="/images/footer/insta-ion.png" alt="social icon" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="sk-footer-menu">
                    <ul>
						<li>
                            <Link to='/partner/home'>
                                <img src="/images/footer/Partner.png" alt="kontakticon" />Skidanläggning/Stuguthyrare
                            </Link>
                        </li>
                        <li>
                            <Link to='/about'>
                                <img src="/images/footer/Skinova.png" alt="kontakticon" /> Om Skinova
                            </Link>
                        </li>
                        <li>
                            <Link to='/kontakt'>
                                <img src="/images/footer/kontakticon.png" alt="kontakticon" />Kontakt
                            </Link>
                        </li>
                        <li>
                            <Link to='/property-text-search'>
                                <img src="/images/footer/stugagare.png" alt="kontakticon" />Fritextsök
                            </Link>
                        </li>
                        <li>
                            <Link to='/owner/login'>
                                <img src="/images/footer/Partner.png" alt="kontakticon" />Stugägare login
                            </Link>
                        </li>
                    </ul>
                    
                    
                </div>
                <div className="sk-footer-info">
                {props.userRole === 'visitor' && <Link to='/login' className="">Skinova login</Link>}
                {props.userRole === 'user' && <Link to='/user' className="">Skinova login</Link>}
                </div>
            </div>
            <img src="/images/footer/logo-icon.png" alt="Logo icon" className="sk-right-logo-icon" />
        </footer>    
    );
}
const mapStateToProps = state => {
    return { 
        userRole: state.authUser.role
    };
}
export default connect(mapStateToProps)(Footer);