import api from "../config/api.js";
import Ajax from './Ajax';

class EbookProperty {

    /** gets the property list. */
    getPropertyList(offset, limit, filter) {
        let requestData = {
            operation: "propertyList",
            offset: offset,
            limit: limit,
            filter: filter
        };
        return Ajax.call(api.propertyService, requestData);
    }

    /** deletes the property */
    deleteProperty(Id) {
        var requestData = {
            operation: "deleteProperty",
            propertyId: Id
        };
        return Ajax.call(api.propertyService, requestData);
    }

    /** clones the existing property to the Ebook property */
    clonePropertyToEBook(propertyKey, providerKey) {
        let requestData = {
            operation: "cloneProperty",
            propertyKey: propertyKey,
            providerKey: providerKey,
        };
        return Ajax.call(api.cloneService, requestData);
    }

    /** gets the property */
    getProperty(propertyID) {
        var requestData = {
            operation: "getProperty",
            propertyID: propertyID
        };
        return Ajax.call(api.propertyService, requestData);
    }

    /** aborts the xhr */
    abortXHR() {
        Ajax.abort()
    }
}
export default EbookProperty;
