import React from 'react';
import styled, { css } from 'styled-components';
import { Card, Row } from '../Theme';

/** returns the skeleton line */
function AnalyticsSkeleton() {
    let lines = [];
    for(let i = 0; i < 2; i++) {
        lines.push(<div className="col-xs-12" key={i}>
        <Card>
            <Skeleton>
                <Line width="30%"></Line>
                <Line width="90%"></Line>
            </Skeleton>
        </Card>
    </div>)
    }
    return <Row>
        {lines}
    </Row>
}
export default AnalyticsSkeleton;
const Skeleton = styled.div`width: 100%;
    height: 115px;
    position: relative;
    @keyframes shine-lines {
        0% {
          background-position: -100px;
        }
        40%, 100% {
          background-position: 140px;
        }
    }
    @keyframes shine-avatar {
        0% {
          background-position: -32px;
        }
        40%, 100% {
          background-position: 208px;
        }
    }`;

const Line = styled.div`height: 16px;
    margin-top: 12px;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines 1.6s infinite linear;
    ${props => props.width && css`width: ${props.width}`}`