import React, {Component} from 'react';
import { priceFormat, isEmpty } from '../../helpers';
import $ from 'jquery';

class PropertyAccordion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            modalOpen: false,
            timer: 0,
            minPrice: 0
        }
        this.timeOut = '';
    }

    componentDidMount() {
        let { properties } = this.props;
        if(!isEmpty(properties)) {
            var lastValue = properties[0].Price;
            for(let i in properties) {
                if(properties[i].Price < lastValue) {
                    lastValue = properties[i].Price;
                }
            }
            this.setState({ minPrice: priceFormat(lastValue) });
        }
    }

    /** handles the clickout event for the modal */
    clickOut = (e, property, item) => {
        let params = {
            Url: property.Url,
            Name: property.Name,
            Image: property.Image,
            ProviderKey: item.ResortKey,
            PropertyKey: property.Id,
            IsPayingPartner: item.IsPayingPartner
        };
        return this.props.clickOut(params);
    }

    /** renders the properties template */
    propTemplate = (properties, item) => {
        var prop = [];
        properties.map((property, propKey) => {
            let newUrl = `${item.ProviderKey}/${prop.Id}#request/${this.props.fromDate}/${this.props.toDate}`;
			
            return prop.push(<div className="property full-width" key={propKey}>
                <div className="property-name">
                    <span style={{cursor: 'pointer'}} onClick={() => this.props.addDrawer(property.Slug)}>{property.Name} &gt;</span>
					<span style={{float:'right',opacity:'0%'}}><a target="_blank" rel="noopener noreferrer" href={property.Slug}>.</a></span> 
                </div>
                <div className="property-beds">{property.Beds}</div>
                <div className="property-price">{priceFormat(property.Price) + " " + item.Currency}</div>
                <div className="property-link">
                    {property.AllowBook? <a href={`/request/booking/${newUrl}`} rel="noopener noreferrer" target="_blank">Boka</a>:
                        <span style={{cursor: 'pointer', fontWeight:'bold'}} 
                            onClick={() => this.props.addDrawer(property.Slug)} id={`${item.ProviderKey}-${propKey}`} 
                            provider={item.ProviderKey} 
                            className="clickout">Läs mer &amp; boka &gt;</span>}
                </div>
                <div style={{clear:'both'}}></div>
            </div>)
        })
        return prop;
    }

    /** shows the properties data */
    showAreaProperties = (e, key) => {
        if ($(".area-properties-" + key).is(":visible")) {
            $(".area-properties-" + key).slideUp();
            $(".properties-arrow-" + key).css("transform", "rotate(0deg)");
        } else {
            $(".area-properties-" + key).slideDown();
            $(".properties-arrow-" + key).css("transform", "rotate(180deg)");
        }
    }

    render() {
        let { minPrice } = this.state;
        let {properties, item} = this.props;
        return (<div className="row prop-acc-wrap">
            <div className="col-xs-12 col-sm-7" style={{marginTop: '6px',paddingLeft:'3px'}}>Pris från {minPrice} {item.Currency}</div>
            <div className="col-xs-12 col-sm-5 text-right" style={{paddingRight:'0px'}}>
                <div onClick={e => this.showAreaProperties(e, this.props.index)}>
                    <div className="prop-acc-title">
                        <div className='prop-acc-img' ><img src="/images/down-white.svg" alt="" /></div>
                    </div>
                    <div className='prop-acc-count'>
                        <span onClick={() => this.setState({ isOpen: !this.state.isOpen })}>Visa 
                        <span className="properties-count"> {this.props.count} </span> 
                            boendealternativ
                        </span>
                    </div>
					<div style={{clear:'both'}}></div>
                </div>
            </div>
            <div className="col-xs-12" style={{padding:'0px'}}>
                <div className={`area-properties-${this.props.index}`} style={{display: 'none' }}>
                    <div style={{width:'100%'}} className="properties-header">
                        <div className="property-name">Objekt</div>
                        <div className="property-beds">Bäddar</div>
                        <div className="property-price">Pris</div>
                        <div className="property-link">&nbsp;</div>
                        <div style={{clear:'both'}}></div>
                    </div>
                    <div style={{width:'100%'}}>
                        {this.propTemplate(properties, item)}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default PropertyAccordion