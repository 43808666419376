import api from '../config/api.js';
import Ajax from './Ajax';
import { toast } from 'react-toastify';
import validate from '../lib/validate';

class UpdateEbookUser {

    /** saves the user in database. */
    saveUser = fields => {
        var requestData = {
            operation: "updateUser",
            fields
        };
        return new Promise((resolve, reject) => {
            Ajax.call(api.eBookUserService, requestData).then(data => {
                if(data.success) {
                    toast.success(resp[data.message]);
                    resolve({ user: data.user, message: resp[data.message]});
                } else {
                    toast.error(resp[data.message]);
                    resolve({ message: resp[data.message]});
                }
            }).catch(() => reject(new Error('error_in_usersave')));
        })        
    }

    /** form validation */
    valid = (fields, tncCheck) => {
        let rules = {
            Name: {required: true},
            tncCheck: {required: true}
        };
        let messages = {
            Name: {required: "Please enter the Name."},
            tncCheck: {required: "Please accept the terms and conditions."}
        };
        fields['tncCheck'] = tncCheck;
        return validate(fields, rules, true, messages);
    }
}
const resp = {
    "USER_UPDATED": "Användaren är nu uppdaterad.",
    "SOMETHING_WENT_WRONG": "Ojdå, något gick fel. Vänligen ladda om sidan och försök igen",
    "WEBHOOK_FAILED": "Cannot create Web Hook on your Stripe account.",
    "NO_STRIPE_ACCOUNT": "This Stripe account does not exists.",
    "NO_NAME_SET": "You have to se the Name for your Stripe Account.",
    "ERR_STATEMENT_DESCRIPTOR": "The statement descriptor matches a common term or website URL, and can't be used."
}
export default UpdateEbookUser;