import React from "react";
import { SignIn } from "aws-amplify-react";
import { Card, Container, Row, Fluid, FormRow } from '../Theme';
import { Link } from "react-router-dom";
import Auth from '@aws-amplify/auth';
import { isEmpty } from "../helpers";
import { toast } from "react-toastify";
import validate from '../lib/validate';

class Login extends SignIn {
    _isMounted = false;

    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        this.state = { loading: false, email: '', password: '', errors: {} }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps) {
        let { location } = window;
        let { authState } = prevProps;
        if(location.pathname === '/register' && authState === 'loading') {
            super.changeState("signUp");
        } else if(location.pathname === '/forgot-password' && authState === 'loading') {
            super.changeState("forgotPassword");
        }
    }

    async signIn(event) {
        event.preventDefault();

        const { email, password } = this.state;
        
        let errors = this.validate(event);
        if(!isEmpty(errors)) {
            return false;
        }

		if (!Auth || typeof Auth.signIn !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
		}
		this.setState({ loading: true });
		try {
			const user = await Auth.signIn(email, password);
			if (
				user.challengeName === 'SMS_MFA' ||
				user.challengeName === 'SOFTWARE_TOKEN_MFA'
			) {
				this.changeState('confirmSignIn', user);
			} else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				this.changeState('requireNewPassword', user);
			} else if (user.challengeName === 'MFA_SETUP') {
				this.changeState('TOTPSetup', user);
			} else if (
				user.challengeName === 'CUSTOM_CHALLENGE' &&
				user.challengeParam &&
				user.challengeParam.trigger === 'true'
			) {
				this.changeState('customConfirmSignIn', user);
			} else {
				this.checkContact(user);
			}
		} catch (err) {
			if (err.code === 'UserNotConfirmedException') {
                toast.error('Bekräftelse krävs för ditt konto.');
				this.changeState('confirmSignUp', { email });
			} else if (err.code === 'PasswordResetRequiredException') {
                toast.error('Du måste återställa ditt lösenord.');
				this.changeState('forgotPassword', { email });
			} else if(err.code === 'NotAuthorizedException') {
                toast.error('Felaktigt användarnamn eller lösenord.');
            } else {
                toast.error('Ogiltigt användarnamn eller lösenord');
			}
		} finally {
			this.setState({ loading: false });
		}
    }

    validate = () => {
        let rules = {email: {required: true, email: true}};
        let errors = validate(this.state, rules);
        this.setState({ errors });
        return errors;
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    render() {
        let { authState } = this.props;
        if(authState !== 'signIn') { return null; }
        let { loading, errors } = this.state;
        return (
            <Fluid className="page-wrapper">
                <Container>
                    <Row>
                        <div className="col-sm-8">
                            <Card>
                                <form method="POST" autoComplete="off">
                                    <FormRow>
                                        <div className="col-xs-12">
                                            <div className="tab-title">Logga in på ditt konto</div>
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className={`col-sm-8 ${errors.email? 'has-error': ''}`}>
                                            <label>E-post</label>
                                            <input autoComplete="false" className="form-control" name="email"
                                                onChange={e => this.setState({ email: e.target.value})} type="text" placeholder="" />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className={`col-sm-8 ${errors.password? 'has-error': ''}`}>
                                            <label>Lösenord</label>
                                            <input autoComplete="false" className="form-control" id="password" key="password" name="password"
                                                onChange={e => this.setState({ password: e.target.value})} type="password" placeholder="" />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-xs-12">
                                            Glömt löseno
                                            rd?{" "}
                                            <Link to="/forgot-password">
                                                Återställ lösenord
                                            </Link>
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-8">
                                            {loading === false && <button className="skibtn btn-secondary" type="submit" onClick={(e) => this.signIn(e)}>
                                                Logga in
                                            </button>}
                                            {loading === true && <button className="skibtn btn-secondary loading-btn" type="button">
                                                <i className="fa fa-spinner fa-spin"></i>Logga in
                                            </button>}
                                        </div>
                                    </FormRow>
                                </form>
                                <FormRow>
                                    <div className="col-sm-8">
                                        Ny användare? <Link to="/register">
                                            Skapa nytt konto
                                        </Link>
                                    </div>
                                </FormRow>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </Fluid>
        );
    }
}
export default Login;