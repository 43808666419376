import React from 'react';
/**
 * returns the skeleton line
 */
export const Line = (props) => {
    var count = props.count? props.count: 1;
    var line = []
    for(let i = 0; i < count; i++) {
        line.push(
            <React.Fragment key={i}>
            <div className="search-skeleton">
                <div className="avatar"></div>
                <div className="lines">
                    <div className="line-title"></div>
                    <div className="line-desc"></div>
                </div>
            </div>
            <div className="clearfix"></div>
            </React.Fragment>
        )
    }
    return line;
}