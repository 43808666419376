import { ALERT } from "../actions/alertAction";

const initialState = {
    type: '', message: ''
};

export default function alertReducer(state = initialState, action) {
    switch (action.type) {
        case ALERT:
            return {
                ...state,
                type: action.payload.data.type,
                message: action.payload.data.message
            };
            
        default:
            return state;
    }
}