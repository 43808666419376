import React from 'react';
import { Route } from 'react-router-dom';
import { AppLayout } from '../Layout';

const AppRoute = ({ component: Component, search, header, footer, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => {
                return <AppLayout search={search} header={header} footer={footer} 
                    location={matchProps.location.search}>
                    <Component {...matchProps} />
                </AppLayout>
            }}
        />
    );
};

export default AppRoute;