import React from 'react';

function LoadingButton(props) {
    let title = props.title? props.title: 'Laddar...';
    if(props.title === "NA") {
        title = '';
    }
    return <button type="button" className={`${props.className? props.className: ''} skibtn btn-secondary loading-btn`}>
        <i className="fa fa-spinner fa-spin"></i>{title}
    </button>
}
export default LoadingButton;