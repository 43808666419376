import React, { Component } from 'react';
import {isScriptIncluded, isEmpty} from '../../helpers';
import Ajax from '../../api/Ajax';
import api from '../../config/api';

class DestinationMap extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showModal: true
        }
        this.googleMapRef = React.createRef();
        this.destinations = [];
    }

    /** mounts the component and checks whether the user has properties added or not */
    componentDidMount() {
        this._isMounted = true;
        this.getDestinations();
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    getDestinations = () => {
        let { destinations } = this.props;
        let dest = destinations.map(dest => dest.Value);
        let params = {
            operation: 'getSearchDestinationForMap',
            destinations: dest
        };
        Ajax.call(api.masterService, params)
            .then(data => {
                if(data.success) {
                    this.setDestinations(data.result);
                    this.initMap();
                }
            }).catch(e => e)
    }

    setDestinations = result => {
        let { destinations } = this.props;
        if(!isEmpty(result)) {
            destinations.forEach(dest => {
                result.forEach((res, i) => {
                    if(res.Name === dest.Value) {
                        result[i].Key = dest.Key;
                    }
                });
            });
        }
        this.destinations = result;
    }

    /** inits the google map and includes the script */
    initMap = () => {
        let googleScript = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC986990VxpqFnuYolTpKOB_FogBznpVrA&libraries=places`;
        if(!isScriptIncluded(googleScript)) {
            const script = document.createElement("script");
            script.src = googleScript;
            document.body.appendChild(script); 

            script.addEventListener('load', () => {
                this.googleMap = this.createGoogleMap()
                this.createMarker()
            })
        } else {
            this.googleMap = this.createGoogleMap()
            this.createMarker()
        }
    }

    /** creates the google map */
    createGoogleMap = () => {
        var latlng = new window.google.maps.LatLng(59.3261917, 17.7018698);
        return new window.google.maps.Map(this.googleMapRef.current, {
            zoom: 10,
            center: latlng,
            maxZoom: 20
        });
    }

    /** creates the google map marker */
    createMarker = () => {
        let { google } = window;
        var markersSet = false;
        var bounds = new google.maps.LatLngBounds();
        if(isEmpty(this.destinations)) {
            return false;
        }
        var markerIcon = {
            url: '/images/ski-map-marker.png',
            scaledSize: new google.maps.Size(35, 35),
            origin: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(5, 45)
        };
        var markers = [];
        this.destinations.forEach((dest) => {
            let { Lat, Lng } = dest;

            if(!isEmpty(Lat) && !isEmpty(Lng)) {
                markersSet = true;
                let latlng = new google.maps.LatLng(Lat, Lng);
                var marker = new google.maps.Marker({
                    position: latlng,
                    map: this.googleMap,
                    icon: markerIcon,
                    label: {
                        text: dest.Name,
                        color: "#0352e0",
                        fontSize: "16px",
                        fontWeight: "bold"
                    }
                });
                markers.push(marker);
                ((marker, property) => {
                    google.maps.event.addListener(marker, "click", e => {
                        this.onMarkerClick(property);
                    });
                })(marker, dest);

                bounds.extend(marker.position);
            }
        });
        if(markersSet) {
            new window.MarkerClusterer(this.googleMap, markers,
                {imagePath: '/images/map/m', maxZoom: 20});
            this.googleMap.setCenter(bounds.getCenter());
            this.googleMap.fitBounds(bounds);
        }
    }

    onMarkerClick = property => this.props.destinationMapFilter(property)

    printMapCard = dest => {
        return `<div style="width: 200px: min-height: 40px;">
            <span style="font-size: 15px; font-weight: bold;">${dest.Name}</span>
        </div>`;
    }

    closeModal = () => {
        this.setState({ showModal: false });
        this.props.closeMapModal();
    }

    render() {
        return (
            <div className="row map-filter">
                <div className="col-xs-12">
                    <div style={{fontSize: '15px'}}>Klicka på en destination för att filtrera sökresultatet till denna plats</div>
                </div>
                <div className="col-xs-12" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                    <div id="google-map-filter" ref={this.googleMapRef}></div>
                </div>
            </div>
        )
    }
}
export default DestinationMap;