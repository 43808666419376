import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import { Card, Container, Row, Fluid, FormRow } from '../Theme';
import Auth from '@aws-amplify/auth';
import { isEmpty } from "../helpers";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { LoadingButton } from "../Components";

class CustomForgotPassword extends ForgotPassword {

    constructor(props) {
        super(props);
        this.state = { 
            delivery: null, 
            loading: false, 
            username: '', 
            errors: {},
            code: '', 
            password: ''
        };
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    send() {
        const { username } = this.state;
        if(username === '') {
            let errors = {username: true};
            this.setState({ errors });
            return false;
        }
		
		if (!Auth || typeof Auth.forgotPassword !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
        }
        this.setState({ loading: true });
		Auth.forgotPassword(username)
			.then(data => {
                this.setState({ delivery: data.CodeDeliveryDetails });
                toast.success(`Vänligen kontrollera din e-post!`);
                this.setState({ loading: false });
			})
			.catch(err => {
                switch(err.code) {
                    case 'UserNotFoundException':
                        toast.error('Ogiltigt användarnamn, vänligen försök igen!');
                        break;
                    default:
                        toast.error('Användarnamn / klient-id-kombination hittades inte');
                        break;
                }
                this.setState({ loading: false });
            });
	}

	submit() {
        const { code, password, username } = this.state;
        let errors = this.validate();
        if(!isEmpty(errors)) {
            return false;
        }
		if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
        }
        this.setState({ loading: true });
		Auth.forgotPasswordSubmit(username, code, password)
			.then(data => {
                toast.success(`Ditt lösenord är nu ändrat!`);
				this.changeState('signIn');
				this.setState({ delivery: null, loading: false });
			})
			.catch(err => {
                this.setState({ loading: false });
                toast.error('Ogiltig verifieringskod, kontrollera din e-post och försök igen.');
            });
    }
    
    /**validates the fields */
    validate = () => {
        const { code, password } = this.state;
        var errors = {};
        if(code === '') {
            errors.code = true;
        }
        if(password === '') {
            errors.password = true;            
        }
        this.setState({ errors });
        return errors;
    }

    /**code send view which asks for username */
    sendView() {
        let { errors } = this.state;
        return (
			<div>
	        <FormRow>    
				<div className={`col-xs-12 ${errors.username? 'has-error': ''}`}>
	                <input autoFocus placeholder='Ange din e-post adress' key="username" name="username"
	                    className="form-control" onChange={e => this.setState({username: e.target.value})} />
	            </div>
			</FormRow>
			<FormRow>
				<div className="col-xs-12">
					Vi behöver bekräfta din e-postadress. Om du är en befintlig användare och inte själv valt att skapa nytt lösenord så kan det bero på att systemet automatiskt nollställt lösenordet av säkerhetsskäl. <br/><br/>
					En bekräftelsekod kommer att skickas till din e-postadress. Ange koden i nästa steg där du också väljer nytt lösenord.
				</div>
			</FormRow>
			</div>
        );
    }

    /** code submit view */
    submitView() {
        let { errors } = this.state;
        return (<>
			<FormRow>
				 <div className="col-xs-12">
                    En bekräftelsekod har skickats till din e-postadress!
                </div>
			</FormRow>
                <FormRow>
                    <div className={`col-sm-8 ${errors.code? 'has-error': ''}`}>
                        <label>Bekräftelsekod</label>
                        <input placeholder='Code' key="code" name="code" autoComplete="off"
                            onChange={e => this.setState({code: e.target.value})} className="form-control" />
                    </div>
                </FormRow>
                <FormRow>
                    <div className={`col-sm-8 ${errors.password? 'has-error': ''}`}>
                        <label>Ange nytt lösenord</label>
                        <input placeholder='New Password' type="password" key="password" name="password"
                            onChange={e => this.setState({password: e.target.value})} className="form-control" />
                    </div>
                </FormRow>
        </>);
    }

    render() {
        let { authState } = this.props;
        let { delivery, loading } = this.state;
        if(authState !== 'forgotPassword') { return null; }
        return (
            <Fluid className="page-wrapper">
                <Container>
                    <Row>
                        <div className="col-sm-8">
                            <Card>
                                <FormRow>
                                    <div className="col-xs-12">
                                        <div className="tab-title">Återställ lösenord</div>												
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-sm-10">
                                        { delivery ? this.submitView() : this.sendView() }
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-sm-8">
                                    {!loading && (delivery ? 
                                        <>
                                            <button type='button' onClick={this.submit} className="mr-10 skibtn btn-secondary">Spara nytt lösenord</button>
                                            <Link to="/login" className="skibtn btn-general">Tillbaka till inloggning</Link>
                                        </>:
                                        <button type='button' onClick={this.send} className="skibtn btn-general">Skicka bekräftelsekod</button>)
                                    }
                                    {loading && <LoadingButton />}
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        { delivery ?
                                            <div onClick={this.send} className="duplicate-anchor1">Skicka koden igen</div> :
                                            <>
                                                <Link to="/login">Tillbaka till inloggning</Link>
                                            </>
                                        }
                                    </div>
                                </FormRow>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </Fluid>
        );
    }
}
export default CustomForgotPassword;