import React from 'react';
import Description from './Description';
import { Link } from 'react-router-dom';
import { providerSiteFromKey, isEmpty } from '../../helpers';
import '../../styles/new-property-card.css';

function NewPropertyDisplay(props) {
    let item = props.property;
 
	let prependLink = isEmpty(props.prependLink) ? "" : props.prependLink;
	let wrapperCSS = props.wrapperCSS;
	
	if(isEmpty(wrapperCSS)){
		wrapperCSS = "col-xs-12 col-md-6";
	}

    function printImage(property) {
		
        var url = property.ThumbUrl;
        if(isEmpty(url)) {
            url = property.Url;
        }
		if(isEmpty(url)) {
			url = property.Image;
		}
        if(isEmpty(url)) {
            url = '/images/placeholder-600x400.png';
        }
		
        return <img src={url} alt="" className="img-responsive image" />
    }

    function printName(name) {
        let n = name.length > 90? `${name.substring(0, 90)}...`: name;
        if(item.Size && item.Size > 0) {
            return `${n}, ${item.Size}kvm`;
        }
        return n;
    }

    return (
        <div className={wrapperCSS}>
            <div className="search-accommodation-list-box">
                <div className="row">
                    <div className="col-xs-12 col-md-6 cols">
                        <div className="search-accommodation-list-img">
                            {printImage(item)}
							<div className="result-img-copyright">Bildkälla: {providerSiteFromKey(item.ProviderKey)}</div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 cols">
                        <div>
                            <img src={`/images/icons/${item.Country}-icon.png`} alt={item.Country} className="resort-country" />
                            <h4 className="blue-icon-text">{item.Resort} {item.Resort.indexOf(item.Destination)===-1 && !isEmpty(item.Destination) && <> - {item.Destination}</>}</h4>
                        </div>
                        <h3>{printName(item.Name)}</h3>
						{!isEmpty(item.Description) &&
                        <p>
                            <Description description={item.Description} numChars={40} />
                        </p>}
                        <div className="blueselect">
                            <Link to={`${prependLink}${item.Slug}`} className="selectpicker">Läs Mer</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewPropertyDisplay;