import React, { useState } from 'react';
import { RichText } from 'prismic-reactjs';

function Accordion(props) {
    let { sliceItems } = props;
    return (
        <div className='container'>
            <ul className='accordion-list'>
                {sliceItems.map((data, key) => (
                    <li className='accordion-list__item' key={key}>
                        <AccordionItem {...data} />
                    </li>
                ))}
            </ul>
        </div>
    )
}

function AccordionItem(props) {
    var [ isOpen, setOpen ] = useState(false);
    const { description, header } = props;
        
    return (
        <div className={`accordion-item ${isOpen && 'accordion-item--opened'}`} onClick={() => { setOpen( !isOpen ) }}>
            <div className='accordion-item__line'>
                <h3 style={{fontWeight:'bold',margin:'10px 0px'}}>
                    {header}
                </h3>
                <span className='accordion-item__icon'/>
            </div>
            <div className='accordion-item__inner'>
                <div className='accordion-item__content'>
                    <div className=''>
                        {RichText.render(description)}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion;