export default {
    'userService': "https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/user",
    'propertyService': "https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/property",
    'priceService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/property-pricing',
    'masterService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/master',
    'bookingService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/property-booking',
    'paymentService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/property-booking-payment',
    'eBookUserService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/user',
    'spPaymentService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/payment',
    'providerService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/provider',
    'cloneService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/ebookpropertyclone',
    'cacheService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/cache',
    'ebspPropService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/ebookskiportalpropertyinfo',
    'adminService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/admin',
    'partnerService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/mediapartner',
    'searchInit': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/search-init',
    'searchGet': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/search-get',
    'propAnalyticsService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/property-analytics',
    'deployService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/deploy',
    'adService': 'https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/ads-service',
    'ownerService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/property-booking-settlement',
    'productService': 'https://akqujf95oi.execute-api.eu-central-1.amazonaws.com/prod/product'
}