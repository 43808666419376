import {
    HEADER_FETCH,
    MENU_FETCH,
    FOOTER_FETCH,
    SET_HISTORY
} from "../actions/layoutActions";

const initialState = {
    header: [],
    menu: false,
    footer: null,
    history: null
};

export default function layoutReducer(state = initialState, action) {
    switch (action.type) {
        case HEADER_FETCH:
            return {
                ...state,
                header: action.payload.data,
            };

        case MENU_FETCH:
            return {
                ...state,
                menu: action.payload.data,
            };

        case FOOTER_FETCH:
            return {
                ...state,
                footer: action.payload.data,
            };

        case SET_HISTORY:
            return {
                ...state,
                history: action.payload.data,
            };
            
        default:
            return state;
    }
}