import React, { Component } from 'react';
import { isEmpty, PostLoading } from '../../helpers';
import PropertyCard from './PropertyCard';
import $ from 'jquery';
import api from '../../config/api'
import Ajax from '../../api/Ajax';
import config from '../../config/config';
import NoResult from './NoResult';
import { Line } from '../../Components/SearchSkeleton';
import LimitType from './LimitType';
import DestinationMap from './DestinationMap';
import Sponsored from './Sponsored';
import AdDisplay from './AdDisplay';
import EmailServiceModal from './EmailServiceModal';

class SearchResults extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            resortFilter: '',
            bedsFilter: '',
            destinationFilter: '',
            countryFilter: '',
            mapFilter: {},
            countries: [],
            locations: [],
            destinations: [],
            beds: [],
            propertyCount: 0,
            areaCount: 0,
            matchingProviders: {},
            property: {},
            searchLoading: 0,
            searchComplete: false,
            Detail: {},
            destFirstOption: '',
            resortFirstOption: '',
            sortOrder: '',
            showDestModal: false,
            showMapButton: true
        }
        this.scrolled = false;  
        this.pages = 0;
        this.scrolledPages = 0;
        this.PagingStart = config.propertySearchPaging;
		this.partnerWhitelabel = (this.props.partnerContent!=null && this.props.partnerContent.whitelabel);		
    }

    /** setup the scroll event and count of the results */
    componentDidMount() {
        this._isMounted = true;
        this.searchGetCount = this.props.searchData.ResultCount;
        this.getScrollables()
        $(window).on('scroll', () => {
            if($('#result').length > 0) {
                if ($(window).scrollTop() >= ($('#result').offset().top + $('#result').outerHeight() - window.innerHeight)) { 
                    if(!this.scrolled) {
                        this.scrolled = true;
                        if(this.searchGetCount > config.propertySearchPaging && this.scrolledPages < Math.floor(this.pages)) {
                            this.getMoreResults('scroll');
                        }
                    }
                }
            }
        });
        const { PropertiesCount, ProviderCount, ResultCount, SearchResult, FilteredFilters } = this.props.searchData;
        let Detail = { PropertiesCount, ProviderCount, ResultCount }
        this.setState({searchInfo: SearchResult, Detail, Filters: FilteredFilters, Countries: FilteredFilters.Countries });
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => {
        this.setState({searchInfo: []});
        this._isMounted = false;
    }

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** sets the number of scrollables for the search results */
    getScrollables = () => {
        this.pages = ((this.searchGetCount - 1) / config.propertySearchPaging) + 1;
    }

    /** gets the more search results */
    getMoreResults = recordType => {
        var loader = recordType !== 'scroll'? 'lines': 'loader';
        this.resetMapFilter(recordType);
        this.setState({ searchLoading: loader, searchComplete: false });
        let { fromDate, toDate, quantity, destination, userGroupToken, providerKey, propertyKey } = this.props;
        let { resortFilter, bedsFilter, destinationFilter, countryFilter } = this.state;
        let requestData = {
            PropertyKey: propertyKey,
            DateFrom: fromDate,
            DateTo: toDate,
            Adults: quantity,
            Children: 0,
            ChildAges: '',
            UserGroupToken: userGroupToken,
            Destination: destination,
            ProviderKey: providerKey,
            UsePaging: true,
            PagingStart: this.PagingStart,
            PagingCount: config.propertySearchPaging,
            FilterCountry: countryFilter,
            FilterDestination: destinationFilter,
            FilterResort: resortFilter,
            FilterBeds: bedsFilter,
            noAuth: true
        };
        if(this.state.sortOrder !== '') {
            requestData.SortOrder = this.state.sortOrder;
        }
        if(recordType === 'mapFilter') {
            requestData.FilterDestination = this.state.mapFilter.Key;
            requestData.FilterResort = '';
            requestData.FilterBeds = '';
            $("#dest-map-container").slideDown(function () {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#dest-map-container").offset().top + ($("#dest-map-container .map-filter").innerHeight() - 20)
                }, 2000);
            });
        }
        Ajax.call(api.searchGet, requestData).then(data => {
            if (!isEmpty(data) && data.ResultCount > 0) {
                var searchData = this.state.searchInfo.concat(data.SearchResult);
                const { PropertiesCount, ProviderCount, ResultCount, FilteredFilters } = data;
                let Detail = { PropertiesCount, ProviderCount, ResultCount };
                this.setState({searchComplete: true, searchInfo: searchData, searchLoading: 0, Detail }, () => this.scrolled = false);
                this.PagingStart = this.PagingStart + config.propertySearchPaging;
                this.searchGetCount = ResultCount;
                ++this.scrolledPages;
                this.getScrollables();
                if(recordType === 'dropdownFilter') {
                    this.repopulateFilters(FilteredFilters);
                }
            } else {
                this.scrolled = true;
                this.setState({searchComplete: true, searchLoading: 0 });
            }
        }).catch(() => {
            this.scrolled = true;
            this.setState({searchComplete: true, searchLoading: 0 })
        })
    }

    resetMapFilter = recordType => {
        if(recordType === 'dropdownFilter' && !isEmpty(this.state.mapFilter)) {
            this.setState({mapFilter: {}});
        }
    }

    resetMapFilterParams = () => {
        this.resetPagination();
        this.setState({mapFilter: {}, showDestModal: false, searchInfo: [], showMapButton: true}, 
            () => this.getMoreResults('dropdownFilter'));
    }

    /** handles the filter inputs change event */
    handleFilterChange = e => {
        this.resetPagination();
        var { resortFilter, bedsFilter, destinationFilter, countryFilter, destFirstOption, resortFirstOption, showMapButton } = this.state;
        var source = e.target.name;
        var value = e.target.value;
        var optionText = e.target.options[e.target.selectedIndex].text;
        showMapButton = (value === 'SHOW_ALL')? true: false;
        if (source === "countryFilter") {
            resortFilter = ''; 
            destinationFilter = ''; 
            bedsFilter = ''; 
            countryFilter = value;
            destFirstOption = (value === '' || value === 'SHOW_ALL' || value === 'FIRST_OPTION')? '':`Destinationer i ${optionText}`;
            resortFirstOption = (value === '' || value === 'SHOW_ALL' || value === 'FIRST_OPTION')? '':`Områden i ${optionText}`;
        } else if (source === "destinationFilter") {
            resortFilter = ''; 
            destinationFilter = (value === 'SHOW_ALL' || value === 'FIRST_OPTION')? '': value; 
            bedsFilter = '';
            destFirstOption = ``;
            resortFirstOption = (value === '' || value === 'SHOW_ALL' || value === 'FIRST_OPTION')? '':`Områden i ${optionText}`;
        } else if (source === "resortFilter") {
            resortFilter = (value === 'SHOW_ALL' || value === 'FIRST_OPTION')? '': value; 
            bedsFilter = '';
            resortFirstOption = ``;
        } else if (source === "bedsFilter") {
            bedsFilter = (value === 'SHOW_ALL' || value === 'FIRST_OPTION')? '': value;
        }
        this.setState({
            showDestModal: false, showMapButton, 
            resortFilter, bedsFilter, destinationFilter, countryFilter, destFirstOption, 
            resortFirstOption, searchInfo: []}, () => this.getMoreResults('dropdownFilter'));
    }

    destinationMapFilter = option => {
        this.resetPagination();
        this.setState({mapFilter: option, searchInfo: []}, () => this.getMoreResults('mapFilter'));
    }

    resetPagination() {
        this.PagingStart = 0;
        this.scrolled = true;
        this.scrolledPages = 0;
        this.searchGetCount = this.props.searchData.ResultCount;
    }

    /** repopulates the filter options */
    repopulateFilters = FilteredFilters => this.setState({ Filters: FilteredFilters })

    /** sorts the results */
    sortOrder = e => {
        this.PagingStart = 0;
        this.scrolled = true;
        this.scrolledPages = 0;
        this.searchGetCount = this.props.searchData.ResultCount;
        this.setState({ sortOrder: e.target.value, searchInfo: []}, () => this.getMoreResults('dropdownFilter'));
    }

    render() {
        const emptySearch = isEmpty(this.state.searchInfo);
        const { Detail, Filters, Countries, resortFilter, bedsFilter, destinationFilter, showMapButton, 
            countryFilter, destFirstOption, resortFirstOption, sortOrder, searchLoading, showDestModal, mapFilter } = this.state;
        const { limitType, Provider, sponsered, fromDate, toDate } = this.props;
        return (<>
            <div className="container-fluid page-wrapper">
                <div className="container">
                    <div className="row">
			
                        <div style={{backgroundColor: '#fff'}} className={`${!this.partnerWhitelabel && !this.props.ebooking ? 'col-sm-10' : ''} col-xs-12`}>
                            {this.props.groupHeader !== '' && <div className="container-center" style={{borderBottom: '20px solid #f1f1f1'}}>
                                <div className="container-inner-center" style={{marginTop: '20px'}}>
                                    <h3><strong>{this.props.groupHeader}</strong></h3>
                                    <p>Du söker nu efter boenden kopplade till en enda användare här på Skinova. <a href="/">Klicka här för att söka på hela utbudet.</a></p>
                                </div>
                            </div>}
                            {!isEmpty(Filters) && <div id="result">
                                <div className="container-center property-filter-wrapper">
                                    <div className="container-inner-center" style={{marginBottom:'40px'}}>
                                        <h3 id="search-result-header">
                                            {`Ditt sökresultat - ${Detail.PropertiesCount} boenden i ${Detail.ResultCount} områden från ${Detail.ProviderCount} leverantörer`}
                                        </h3>
                                        <h4>Filtrera din sökning</h4>
                                    </div>
                                    <div id="filter-container" className="row">
                                        <div className="col-sm-3 col-xs-12">
                                            <select onChange={this.handleFilterChange} value={countryFilter} name="countryFilter" className="form-control">
                                                <option value="" data-iconurl="/images/icons/country-icon.png">Länder - Visa alla</option>
                                                {Countries.map((country, i) => (
                                                    <option value={country.Key} key={i}>{country.Value}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-3 col-xs-12">
                                            <select onChange={this.handleFilterChange} value={destinationFilter} name="destinationFilter" className="form-control">
                                                {(destFirstOption === '' && destinationFilter === '') && <option value="">Destination - visa alla</option>}
                                                {destFirstOption !== '' && <option value="FIRST_OPTION">{destFirstOption}</option>}
                                                {(destinationFilter !== '' || resortFilter !== '') && <option value="SHOW_ALL">Återställ och visa alla</option>}
                                                {!isEmpty(Filters.Destinations) && Filters.Destinations.map((destination, i) => (
                                                    <option value={destination.Key} key={i}>{destination.Value}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-3 col-xs-12">
                                            <select onChange={this.handleFilterChange} value={resortFilter} name="resortFilter" className="form-control">
                                                {(resortFirstOption === '' && resortFilter === '') && <option value="">Område - visa alla</option>}
                                                {resortFirstOption !== '' && <option value="FIRST_OPTION">{resortFirstOption}</option>}
                                                {resortFilter !== '' && <option value="SHOW_ALL">Återställ och visa alla</option>}
                                                {!isEmpty(Filters.Resorts) && Filters.Resorts.map((resort, i) => (
                                                    <option value={resort.Key} key={i}>{resort.Value}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-3 col-xs-12">
                                            <select onChange={this.handleFilterChange} value={bedsFilter} name="bedsFilter" className="form-control">
                                                <option value="">Antal bäddar - visa alla</option>
                                                {!isEmpty(Filters.Beds) && Filters.Beds.map((bed, i) => (
                                                    <option value={bed} key={i}>{bed}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
									<div style={{clear:'both'}}></div>
                                </div>
								{!this.partnerWhitelabel && !this.props.ebooking &&
                                <div className="row">
                                    <div className="col-xs-12">
                                        <EmailServiceModal destination={destinationFilter} area={resortFilter} 
                                            fromDate={fromDate} toDate={toDate} />
                                    </div>
                                </div>
								}
                                <div className="row property-sort-row">
                                    {limitType && !this.partnerWhitelabel && !this.props.ebooking && <div className="col-xs-12">
                                        <LimitType Provider={Provider} limitType={limitType} resetProviders={this.props.resetProviders} />
                                    </div>}
                                    <div className="col-xs-12">
                                        <div className="pull-right mbr15-767 hidden-xs">
                                            {showMapButton && <button className="skibtn btn-secondary" 
                                                onClick={() => this.setState({ showDestModal: !this.state.showDestModal })}>
                                                Visa på karta
                                            </button>}
                                        </div>
                                        <div className="pull-right mr-10">
                                            <select className="form-control" value={sortOrder} onChange={this.sortOrder}>
                                            	<option value="popular">Utvalda boenden</option>    
												<option value="priceasc">Pris - lägst först</option>
                                                <option value="pricedesc">Pris - högst först</option>
                                                <option value="nameasc">Områdesnamn a-&gt;ö</option>
                                                <option value="namedesc">Områdesnamn ö-&gt;a</option>
                                                
                                            </select>
                                        </div>
                                        <div className="pull-right" style={{paddingTop:'6px',paddingRight:'5px'}}>Sortering: </div>
                                    </div>
                                </div>
                                {!isEmpty(sponsered) && !this.partnerWhitelabel &&!this.props.ebooking && <Sponsored sponsered={sponsered} />}
                                <div id="dest-map-container">
                                    {showDestModal && <DestinationMap destinations={Filters.Destinations} 
                                        destinationMapFilter={this.destinationMapFilter.bind(this)} />}
                                </div>
                                <div id="property-card">
                                    {(showDestModal && !isEmpty(mapFilter)) && <div className="row">
                                        <div className="col-sm-9 col-xs-12">
                                            <h3>Filtrerar resultat för: <i>{mapFilter.Name}</i></h3>
                                        </div>
                                        <div className="col-sm-3 col-xs-12 text-right">
                                            <button onClick={this.resetMapFilterParams} className="skibtn btn-secondary">Stäng av filter och visa alla</button>
                                        </div>
                                    </div>}
                                    {!emptySearch && <PropertyCard ebooking={this.props.ebooking} provider={Provider} searchInfo={this.state.searchInfo} fromDate={this.props.fromDate} 
                                        toDate={this.props.toDate} />}
                                </div>
                            </div>}
                            <div style={{clear:'both'}}></div>
                            {searchLoading === 'lines' && <Line count={5} />}                            
                        </div>
						{!this.partnerWhitelabel &&!this.props.ebooking &&
                        <div className="col-sm-2 hidden-xs" style={{paddingTop: '225px'}}>                        
						    <AdDisplay destination={destinationFilter} />
                        </div>
						}
                        {searchLoading === 'loader' && <PostLoading />}
                    </div>
                </div>
            </div>
            <div id="search-result-wrap">
                {this.state.searchComplete && emptySearch && <>
                    <NoResult Provider={Provider} limitType={limitType} resetProviders={this.props.resetProviders} />
                </>}
            </div>
        </>)
    }
}
export default SearchResults;