import React from 'react';
import { RichText } from 'prismic-reactjs';
import { isEmpty } from '../../helpers';

/** renders the multi campaign on the home page */
function FullWidthOverlay(props) {
    return (
		<>
	
		{!props.primary['hide'] &&
        <div className="container-fluid fullWidthOverlay">
            <div className="row" style={{backgroundImage:`url(${props.primary['background-image'].url})`,backgroundSize:'cover',backgroundPosition:'center',position:'relative'}}>
                <div style={{position:'absolute',left:'0',top:'0',bottom:'0',right:'0'}}></div>
				{props.primary['display-type']==='Box' &&
                <div className="container row">
                    <div className="col-xs-1 col-sm-7 col-md-8">&nbsp;</div>        
                    <div className="col-xs-10 col-sm-4 col-md-4" style={{backgroundColor: 'rgb(255, 255, 255,0.8)',marginBottom:'60px',marginTop:'60px',padding:'40px 40px 40px 40px'}}>                        
                        <span className="title1" style={{color:props.primary['title1-color']}}>{props.primary['title1']}</span>&nbsp;<span className="title2"  style={{color:props.primary['title2-color']}}>{props.primary['title2']}</span>
                        <div className="content" style={{color:props.primary['content-color']}}>{RichText.render(props.primary['content'])}</div>
                        {!isEmpty(props.primary.link.url) && <div className="skibtn btn-secondary"><a href={props.primary.link.url} target="_blank" rel="noopener noreferrer">Läs mer ></a></div>}
                    </div>
                </div>}				
						
				{props.primary['display-type']==='Box-left' &&
                <div className="container row">                      
                    <div className="col-xs-10 col-sm-4 col-md-4" style={{backgroundColor: 'rgb(255, 255, 255,0.8)',marginBottom:'60px',marginTop:'60px',padding:'40px 40px 40px 40px'}}>                        
                        <span className="title1" style={{color:props.primary['title1-color']}}>{props.primary['title1']}</span>&nbsp;<span className="title2"  style={{color:props.primary['title2-color']}}>{props.primary['title2']}</span>
                        <div className="content" style={{color:props.primary['content-color']}}>{RichText.render(props.primary['content'])}</div>
                        {!isEmpty(props.primary.link.url) && <div className="skibtn btn-secondary"><a href={props.primary.link.url} target="_blank" rel="noopener noreferrer">Läs mer ></a></div>}
                    </div>
                </div>}	
						
				{props.primary['display-type']==='Text' &&
                <div className="container row">                         
                    <div className="col-xs-12" style={{marginBottom:props.primary['margin-bottom'],marginTop:props.primary['margin-top'],padding:props.primary['padding']}}>                        
                        <span className="title1" style={{color:props.primary['title1-color']}}>{props.primary['title1']}</span>&nbsp;<span className="title2"  style={{color:props.primary['title2-color']}}>{props.primary['title2']}</span>
                        <div className="content" style={{color:props.primary['content-color']}}>{RichText.render(props.primary['content'])}</div>
                        {!isEmpty(props.primary.link.url) && <div className="skibtn btn-secondary"><a href={props.primary.link.url} target="_blank" rel="noopener noreferrer">Läs mer ></a></div>}
                    </div>
                </div>}
            </div>
        </div>}
		</>
    );
}

export default FullWidthOverlay;