import React, { Component } from 'react';
import { isEmpty, isSearchHash } from '../helpers';
import Reaptcha from 'reaptcha';
import Ajax from '../api/Ajax';
import validate from '../lib/validate';
import $ from 'jquery';
import { Meta, Checkbox } from '../Theme'

class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            message: "",
            loading: false,
            formSent: false,            
            captchaError: false,
            captchaValue: "",
            errors: {},
            sentEmailCheck: false
        };
    }

    componentDidMount() {
        this.hideForSearch();
        if(window.screen.width < 900) {
            $("#dynamic-page-content").slideDown(function () {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#dynamic-page-content").offset().top
                }, 2000);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.hash !== this.props.location.hash) {
            this.hideForSearch();
        }
    }

    hideForSearch = () => {
        if(this.props.location.hash !== "" && isSearchHash(this.props.location.hash)) {
            this.setState({hideForSearch: true})
        }
    }

    onVerify = val => this.setState({ captchaValue: val, captchaError: false })

    handleFormSubmit = e => {
        e.preventDefault();
        let rules = {
            name: {required: true},
            email: {required: true, email: true},
            message: {required: true},
            captchaValue: {required: true},
        };
        let errors = validate(this.state, rules);
        this.setState({ errors });
        if(!isEmpty(errors)) {
            return false;
        }
        this.setState({ loading: true });

        var requestData = {
            Operation: "message",
            Message: {
                Name: this.state.name,
                Email: this.state.email,
                Message: this.state.message,
                CaptchaValue: this.state.captchaValue
            }
        };
        Ajax.call('https://mmd1jpg2b3.execute-api.eu-central-1.amazonaws.com/prod/communication', requestData)
            .then(() => this.setState({ loading: false, formSent: true }))
            .catch(() => this.setState({ loading: false }))
    }


    render() {
        const { loading, formSent, errors, hideForSearch, sentEmailCheck } = this.state;
        if(hideForSearch) { return null; }
        return (<>
            <Meta title="Kontakta Skinova!" />
            <div className="container hide-for-search" id="dynamic-page-content">
                <div className="row" style={{ marginTop: '20px' }}>
                    <div className="col-xs-12">
                        <h1 className="dm_title content-heading">Kontakta Skinova!</h1>
                    </div>
                </div>

                {formSent === false &&
                    <form action="#">
                        <div className="row">
                            <div className={`col-md-6 col-xs-12 ${errors.name? 'has-error': ''}`}>
                                <label>Namn</label>
                                <input className="form-control" type="text" placeholder="Ditt namn..." value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                            </div>

                            <div className={`col-md-6 col-xs-12 ${errors.email? 'has-error': ''}`}>
                                <label>E-post</label>
                                <input className="form-control" type="email" placeholder="Din e-post..." value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className={`col-xs-12 ${errors.message? 'has-error': ''}`}>
                                <label>Meddelande</label>
                                <textarea style={{ height: '100px' }} className="form-control" placeholder="Ditt meddelande.." onChange={e => this.setState({ message: e.target.value })} value={this.state.message}></textarea>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-xs-12">
                                <Reaptcha sitekey="6LcbUo8UAAAAALbZKlnjZakjkAoQ0LG2JfDJHiM4" onVerify={this.onVerify} />
                                {errors.captchaValue && <div className="text-danger"><p>Vänligen kontrollera Captcha-kontrollen ovan!</p></div>}
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-xs-12">
                                <Checkbox name="selfCleaning" value='optional' checked={sentEmailCheck} 
                                    onChange={() => this.setState({ sentEmailCheck: !sentEmailCheck  })} 
                                    title="Ja tack, jag vill ha löpande information av Skinova via e-post" />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-xs-12">
                                <input className="skibtn btn-secondary" style={{marginTop: '10px', marginBottom: '10px'}} disabled={loading} onClick={this.handleFormSubmit} type="submit" value="Skicka!" />
                                {loading === true && <Loading />}
                            </div>
                        </div>
                    </form>
                }

                {formSent === true &&
                    <div className="row">
                        <div className="col-xs-12">
                            <p>Ditt meddelande har blivit skickat!</p>
                        </div>
                    </div>
                }
            </div>
        </>);
    }
}
const Loading = () => {
    return <img src="/images/post_loading.gif" alt="" />
}
export default ContactForm;
