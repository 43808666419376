import React, { useState, useRef } from 'react';
import { FormRow, Checkbox, LoadingButton } from '../../Theme';
import Ajax from '../../api/Ajax';
import api from '../../config/api';
import RespTrans from '../../config/server-response.js';
import { isEmpty } from '../../helpers';
import Reaptcha from 'reaptcha';
import { toast } from 'react-toastify';
import validate from '../../lib/validate';

function ContactOwner(props) {
    const [modalVisible, setVisible] = useState(false);
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [captcha, setCaptcha] = useState('');
    var [ sentEmailCheck, setSentEmailCheck ] = useState(false);
    var captchaRef = useRef();

    /**Submits the form */
    const formSubmit = () => {
        let errors = validateForm();
        if(!isEmpty(errors)) {
            return false;
        }
        setLoader(true);
        let requestData = {
            operation: 'contactPropertyOwner',
            subject, message, email, captcha,
            ID: props.ID
        }
        var _capt = captchaRef;
        Ajax.call(api.ebspPropService, requestData)
            .then(response => {
                _capt.reset();
                if(response.success) {
                    setLoader(false);
                    setSubject('');
                    setMessage('');
                    setEmail('');
                    toast.success(RespTrans[response.message]);
                } else {
                    setLoader(false);
                    toast.error(RespTrans[response.message]);
                }
            }).catch(() => {
                setLoader(false);
                toast.success(RespTrans['SOMETHING_WENT_WRONG']);
            });
    }

    /**validates the form */
    function validateForm() {
        let fields = {subject, email, message, captcha};
        let rules = {
            subject: {required: true}, 
            email: {required: true, email: true}, 
            message: {required: true}, 
            captcha: {required: true}
        };
        var errors = validate(fields, rules);
        setErrors(errors);
        return errors;
    }

    return (<>
            <FormRow>
                <div className="col-xs-12">
                    <h3>Ställ en fråga om detta boende</h3>
                </div>
            </FormRow>
        
            <FormRow>
                <div className={`col-xs-12 ${errors['subject']? 'has-error': ''}`}>
                    <input type="text" className="form-control" value={subject || ''} 
                        onChange={(e) => setSubject(e.target.value)} placeholder="Rubrik" />
                </div>
            </FormRow>
            <FormRow>
                <div className={`col-xs-12 ${errors['email']? 'has-error': ''}`}>
                    <input type="text" className="form-control" value={email || ''} 
                        onChange={(e) => setEmail(e.target.value)} placeholder="E-post" />
                </div>
            </FormRow>
            <FormRow>
                <div className={`col-xs-12 ${errors['message']? 'has-error': ''}`}>
                    <textarea className="form-control" value={message || ''} 
                        onChange={(e) => setMessage(e.target.value)} placeholder="Meddelande"></textarea>
                </div>
            </FormRow>
            <FormRow>
                <div className="col-xs-12">
                    <Reaptcha sitekey="6LcbUo8UAAAAALbZKlnjZakjkAoQ0LG2JfDJHiM4" ref={e => (captchaRef = e)} onVerify={(val) => setCaptcha(val)} />
                    {errors.captcha && <div className="help-block error">Kontrollera captcha.</div>}
                </div>
            </FormRow>
            <FormRow>
                <div className="col-xs-12">
                    <Checkbox name="selfCleaning" value='optional' checked={sentEmailCheck} 
                        onChange={() => setSentEmailCheck(!sentEmailCheck)} 
                        title="Ja tack, jag vill ha löpande information av Skinova via e-post" />
                </div>
            </FormRow>
            <FormRow>
                <div className="col-xs-12">
                    {!loading && <button type="button" className="skibtn btn-secondary mr-10" onClick={formSubmit}>
                        Skicka
                    </button>}
                    {loading && <LoadingButton className="mr-10" />}
                    <button className="skibtn btn-general" onClick={() => setVisible(false)}>Stäng</button>
                </div>
            </FormRow>
            {modalVisible && ''}
        </>
    );
}
export default ContactOwner;