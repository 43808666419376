import React from 'react';

function Panel(props) {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h4 className="panel-title"><span className="heading">{props.title}</span></h4>
            </div>
            <div className={`panel-collapse`}>
                <div className="panel-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
}
export default Panel;