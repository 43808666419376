import React from 'react';
import '../../styles/radio-button.css';

const Radio = ({ title, name, value, checked, onChange}) => {
    
    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return(
        <label className="radio-inline">
            <span>{title}</span>
            <input type="radio" name={name} value={value} onChange={changeHandler} checked={checked} />
            <span className="checkround"></span>
        </label>)
}
export default Radio;