import React, { Component } from 'react';
import { Row, FormRow } from '../../Theme';
import  { Redirect } from 'react-router-dom';
import { datus, setSearchDatePicker } from '../../helpers';

class PropertyInfoSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fromDate: '', 
            toDate: '', 
            quantity: 1,
            redirectTo: ''
        }
        this.fromDate = React.createRef();
        this.toDate = React.createRef();
        this.fromDateFormat = '';
        this.toDateFormat = '';
    }

    /** mounts the component and sets the dates */
    componentDidMount() {
        this.initDates();
        setSearchDatePicker(this.fromDate, this.toDate, this.setDatePickerState, this.fromDateFormat)
    }

    /** setup the initial dates on the date fields */
    initDates = () => {
        const { dateFrom, dateTo } = datus.defaultDate();
        this.fromDateFormat = datus.actualFormat(dateFrom);
        this.toDateFormat = datus.actualFormat(dateTo);
        this.setState({fromDate: datus.showFormat(dateFrom), toDate: datus.showFormat(dateTo)});
    }

    /** callback for setting the datepicker after date selection */
    setDatePickerState = state => {
        if(state.fromDate) {
            this.fromDateFormat = state.fromDate;
            this.setState({fromDate: datus.showFormat(state.fromDate)});
        }
        if(state.toDate) {
            this.toDateFormat = state.toDate;
            this.setState({toDate: datus.showFormat(state.toDate)});
        }
    }

    /** searches the property */
    doSearch = () => {
        const { quantity } = this.state;
        const { ProviderKey, PropertyKey } = this.props;
        var url = `/s#search/${this.fromDateFormat}/${this.toDateFormat}/${quantity}/NO - DEST/${ProviderKey}/${PropertyKey}`;
        this.setState({redirectTo: url});
    }

    render() {
        const { fromDate, toDate, quantity } = this.state;
        return (<Row>
            {this.state.redirectTo !== '' && <Redirect to={this.state.redirectTo} />}
            <div className="col-xs-12" style={{fontSize: '16px'}}>
                Sök ledigt boende i {this.props.PropertyName}
                <hr style={{marginTop: '5px', marginBottom: '10px'}} />
            </div>
            <div className="col-xs-12">
                <FormRow>
                    <div className="col-xs-12">
                        Ankomst<br />
                        <input type="text" ref={this.fromDate} className="datepicker form-control" readOnly value={fromDate} />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-xs-12">
                        Avresa<br />
                        <input type="text" ref={this.toDate} className="datepicker form-control" readOnly value={toDate} />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-xs-12">
                        Antal bäddar
                        <input type="number" className="form-control" value={quantity} min="1"
                        onChange={(e) => this.setState({'quantity': e.target.value})} />
                    </div>
                </FormRow>
                <FormRow>
                    <div className="col-xs-12">
                        <button className="skibtn btn-secondary" onClick={this.doSearch}>Sök</button>
                    </div>
                </FormRow>
            </div>
        </Row>)
    }
}
export default PropertyInfoSearch;