import React from 'react';
import styled from 'styled-components';
import { PostLoading } from '../helpers';

function ScreenLoader() {
    return (
        <Loader><PostLoading /></Loader>
    )
}
const Loader = styled.div`width: 100%
    height: 200px;`
export default ScreenLoader;