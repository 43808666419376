import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserSuccess } from "../actions/userActions.js";
import api from '../config/api.js';
import Ajax from '../api/Ajax';
import { userProperties, userBookings, userPurchaseCount,
    userPackageExpired, setScreenService } from '../actions/userActions';
import userAPI from '../api/AuthDispatch';
import styled from 'styled-components';

class AmplifyEvent extends Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: '',
            eventComplete: true,
            width: 0
        }
        Hub.listen('auth', data => {
            const { payload } = data;
            this.onAuthEvent(payload);
        });
        this.timeOut = '';
    }

    componentDidMount() {
        this._isMounted = true;
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** initializes the timer */
    initTimer = () => {
        let { width } = this.state;
        if(width <= 100) {
            this.setState({ width: width + 1 });
            this.timeOut = setTimeout(this.initTimer, 50);
        } else if(width === 100) {
            this.setState({ width: 0 });
        }
    }

    onAuthEvent(user) {
        switch(user.event) {
            case 'signIn':
                this.setState({eventComplete: false});
                    var requestData = {
                        operation: "getUserBySkiPortal",
                        saveUser: true,
                        ver: 1
                    };
                    Ajax.call(api.eBookUserService, requestData)
                        .then(data => {
                            window.location.href = '/user';
                            // clearTimeout(this.timeOut);
                            // this.props.dispatch(fetchUserSuccess(data.user));
                            // this.props.dispatch(userAPI.setRole('user'));                            
                            // this.setState({eventComplete: true, width: 0}, () => {
                            //     let { location } = window;
                            //     if(location.pathname !== '/user') {
                            //         this.setState({redirectTo: '/user'});
                            //     }
                            // });
                            // this.props.dispatch(setScreenService(false));
                        }).catch(e => {                            
                            clearTimeout(this.timeOut);
                            this.setState({ width: 0 });
                        });
                break;
            case 'signOut':
                this.props.dispatch(userProperties([]));
                this.props.dispatch(fetchUserSuccess({}));
                this.props.dispatch(userAPI.setRole('visitor'));
                this.props.dispatch(userProperties([]));
                this.props.dispatch(userBookings([]));
                this.props.dispatch(userPurchaseCount(-1));
                this.props.dispatch(userPackageExpired(false));
                this.props.dispatch(setScreenService(false));
                this.setState({ redirectTo: '/login', eventComplete: true });
                break;
            default: break;
        }
    }

    render() {
        let  { redirectTo, width, eventComplete } = this.state;
        return (<>
            {redirectTo !== '' && <Redirect to={redirectTo} />}            
            {!eventComplete && <ProgressWrap><Progress><Line width={width} /></Progress></ProgressWrap>}
        </>)
    }
}
const ProgressWrap = styled.div`
position: relative;`
const Progress = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: -4px;
    transform: translateY(100%);`
const Line = styled.div`
    height: 4px;
    background: #00d1ff;
    transition: .3s linear
    width: ${props => props.width ? props.width : 0}%`
export default connect()(AmplifyEvent);