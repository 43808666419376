import React, {Component} from 'react';
import {Loading, PrismicAPI, isEmpty} from '../helpers';
import { menuFetch, footerFetch, headerFetch } from '../actions/layoutActions';
import {connect} from 'react-redux';
import Routes from '../Routes/RoutesContainer';
import { HelmetProvider } from 'react-helmet-async';

class LayoutLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutLoaded: false,
            errorLoading: false,
            loading: true
        };
    }

    /** inits the layout fetch */
    componentDidMount() {
        this._isMounted = true;
        PrismicAPI(["footer", "top-header", "top-menu-link"]).then(response => {
            this.setHeader(response);
            this.setMenubar(response);
            this.setFooter(response);            
            this.setState({layoutLoaded: true, loading: false});
        }).catch(() => this.setState({layoutLoaded: true, loading: false}))
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** sets the header in redux */
    setHeader = response => {
        var header = [];
        response.results.map((item, i) => {
            if (item.type === 'top-header') {
                header = item.data;
            }
            return true;
        });
        this.props.dispatch(headerFetch(header.content));
    }

    /** sets the menus in redux */
    setMenubar = (response) => {
        var items = [];
        response.results.map((item, i) => {
            if (item.type === 'top-menu-link') {
                items[i] = {
                    uid: item.data.link.uid,
                    title: item.data.title[0].text
                };                
            }
            return true;
        });
        this.props.dispatch(menuFetch(items));
    }

    /** sets the footer in redux */
    setFooter = data => {
        if (data == null) {
            return;
        }
        var footerItems = [];
        data.results.map((item, i) => {
            if(item.type === 'footer') {
                item.data.body.map((slice, i) => {
                    if (slice.items && slice.items.length > 0 && !isEmpty(slice.items[0])) {
                        footerItems[i] = slice.items;                        
                    }
                    return true;
                });
            }
            return true;
        });
        this.props.dispatch(footerFetch(footerItems));
    }

    render() {
        let { layoutLoaded, errorLoading, loading } = this.state;
        let { userType } = this.props;
        return (<>
            {layoutLoaded && <>
                <HelmetProvider>
                    <Routes userType={userType} />
                </HelmetProvider>
            </>}
            {loading && <Loading />}
            {errorLoading && <h2>Error Loading site.</h2>}
        </>)
    }
}

export default connect()(LayoutLoader);