import React, { Suspense } from 'react';
import Static from '../SkiPublic/SearchProperty/Static';
import ContactForm from '../SkiPublic/ContactForm';
import PropertyInfo from '../SkiPublic/PropertyDetail/index';
import DynamicContent from '../SkiPublic/DynamicContent';
import NoPage from '../SkiPublic/NoPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProviderLogin from '../Providers/Login.js';
import { ScreenLoader } from '../Components';
import Amplify from 'aws-amplify';
import aws_exports from "../aws-exports";

import UserRoute from './UserRoute';
import AppRoute from './AppRoute';
import ProviderRoute from './ProviderRoute';
import userRoutes from './UserRoutes';
import Toastify from '../Components/Toastify';
import AmplifyEvent from './AmplifyEvent';
import ScrollToTop from './ScrollToTop';

Amplify.configure(aws_exports);

const CountryService = React.lazy(() => import('../SkiPublic/CountryService.js'));
const DestinationService = React.lazy(() => import('../SkiPublic/Destination/DestinationService.js'));
const AreaService = React.lazy(() => import('../SkiPublic/Destination/AreaService.js'));
const Group = React.lazy(() => import("../SkiPublic/Group.js"));
const ProductsOnly = React.lazy(() => import('../SkiPublic/Booking/form/ProductsOnly'));
const BookingForm = React.lazy(() => import('../SkiPublic/Booking/form/index'));
const BookingDetails = React.lazy(() => import('../SkiPublic/Booking/details/index'));
const EBookProperties = React.lazy(() => import("../SkiPublic/Property/EBookListContainer"));
const BookingPayment = React.lazy(() =>import('../SkiPublic/Booking/PaymentViaLink.js'));
const Pay4IOT = React.lazy(() =>import('../SkiPublic/Booking/Pay4IOT.js'));
const BookingPaymentResponse = React.lazy(() =>import('../SkiPublic/Booking/PaymentResponse'));
const BookingPaymentResponseIOT = React.lazy(() =>import('../SkiPublic/Booking/PaymentResponseIOT'));
const Dashboard = React.lazy(() => import('../Providers/Dashboard'));
const BookingLogin = React.lazy(() => import('../SkiPublic/Booking/BookingLogin'));
const ForgotUsername = React.lazy(() => import('../Auth/ForgotUsername'));
const CodeVerifier = React.lazy(() => import('../Auth/CodeVerifier'));
const PropertyTextSearch = React.lazy(() => import('../SkiPublic/PropertyTextSearch/index'));
const SubscribeEmail = React.lazy(() => import('../SkiPublic/Subscribe/Email'));
const UnSubscribeEmail = React.lazy(() => import('../SkiPublic/Subscribe/Unsubscribe'));
const Ebook = React.lazy(() => import('../Ebook'));
const PropertyOwner = React.lazy(() => import('../PropertyOwner'));
const InvoicePdf = React.lazy(() => import('../PropertyOwner/InvoicePdf'));
const CleaningEmailOperation = React.lazy(() => import('../SkiPublic/Cleaning/EmailLinkOperation'));
const PartnerInfo = React.lazy(() => import("../Partner/PartnerInfo"));
const PartnerHome = React.lazy(() => import('../Partner/index'));
const PriceMap = React.lazy(() => import('../SkiPublic/PriceMap/index'));
const PropertyOwnerInfo = React.lazy(() => import('../SkiPublic/PropertyOwnerInfo.js'));
const PropertyClone = React.lazy(() => import('../SkiPublic/PropertyClone/index'));
const PropertyRequest = React.lazy(() => import('../SkiPublic/PropertyClone/Request/index'));
const PropertyAdOnboarding = React.lazy(() => import('../SkiPublic/PropertyClone/PropertyAd/index'));
const CleaningPhotoUpload = React.lazy(() => import('../SkiPublic/CleaningPhotoUpload/index'));
const CleaningUploadSuccess = React.lazy(() => import('../SkiPublic/CleaningPhotoUpload/success'));

function RoutesContainer(props) {

    return (
        <Router>
            <ScrollToTop />
            <AmplifyEvent />
            <Suspense fallback={<ScreenLoader />}>
                <Switch>
                    <AppRoute exact path="/" component={DynamicContent} search={false} />
                    <AppRoute exact path="/s" component={Static} />
                    <AppRoute path="/kontakt" component={ContactForm} />
                    <AppRoute path="/group/:group" component={Group} search={false} />
                    {userRoutes.map((route, i) => {
                        return <UserRoute key={i} {...route} login={props.userType} /> 
                    })}
                    <AppRoute path="/loc/:providerKey/:country/:destination/:area" component={AreaService} search={false} header={false} footer={false} />
                    <AppRoute path="/ploc/:country/:destination/:resort/:area/:name/:key" component={PropertyInfo} header={false} footer={false} search={false} />
                    <AppRoute path="/loc/:country/:destination" component={DestinationService} />
                    <AppRoute path="/loc/:country" component={CountryService} />
                    <AppRoute path="/provider/login" component={ProviderLogin} search={false} />
                    <AppRoute path="/ebook/" component={Ebook} search={false} header={false} footer={false} />
                    <AppRoute path="/:country/:destination/:resort/:area/:name/:key" component={PropertyInfo} search={false} />
                    <AppRoute path="/partner/home" component={PartnerHome} search={false} />
                    <AppRoute path="/partner/:uid" component={PartnerInfo} search={false} />
                    <AppRoute path="/booking-details" component={BookingDetails} search={false} />
                    <AppRoute path="/book-property" component={BookingForm} search={false} />
                    <AppRoute path="/book-products" component={ProductsOnly} search={false} />
                    
                    <AppRoute path="/boende-i-fjallen" component={EBookProperties} search={false} />
                    <AppRoute path="/booking-payment/response" component={BookingPaymentResponse} search={false} />
                    <AppRoute path="/booking-payment/responseIOT" component={BookingPaymentResponseIOT} search={false} />
                    <AppRoute path="/booking-payment/:bookingNumber" component={BookingPayment} search={false} />
                    <AppRoute path="/Pay4IOT" component={Pay4IOT} search={false} />
                    <AppRoute path ="/get-my-booking" component={BookingLogin} search={false} />
                    <ProviderRoute loggedIn={props.userType} path="/provider/dashboard" component={Dashboard} search={false} />
                    <AppRoute path="/forgot-username" component={ForgotUsername} search={false} />
                    <AppRoute path="/user-auth" component={CodeVerifier} search={false} />
                    <AppRoute path="/property-text-search" component={PropertyTextSearch} search={false} />
                    <AppRoute path="/subscribe/email" component={SubscribeEmail} search={false} />
                    <AppRoute path="/cancel-property-subscription" component={UnSubscribeEmail} search={false} />
                    <AppRoute path="/cleaning-operation/:id" component={CleaningEmailOperation} search={false} />
                    <AppRoute path="/resorts-map" component={PriceMap} search={false} />
                    <AppRoute path="/ny-stugagare" component={PropertyClone} search={false} />
                    <AppRoute path="/ebook-onboarding" component={PropertyRequest} search={false} />
                    <AppRoute path="/property-ad-onboarding" component={PropertyAdOnboarding} search={false} />
                    <AppRoute path="/stugagare-extern" component={PropertyOwnerInfo} search={false} />
                    <AppRoute path="/cleaning-photos-upload/:bookingNumber" component={CleaningPhotoUpload} search={false} />
                    <AppRoute path="/cleaning-upload-success" component={CleaningUploadSuccess} search={false} />
                    <Route path="/owner" component={PropertyOwner} />
                    <Route path="/invoice" component={InvoicePdf} />
                    <AppRoute path="/:page" component={DynamicContent} search={false} />
                    <AppRoute component={NoPage} search={false} />
                </Switch>
            </Suspense>
        <Toastify />
    </Router>)
}
export default RoutesContainer;