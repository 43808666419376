import React, { Component } from 'react';
import Ajax from '../../api/Ajax';
import api from '../../config/api';
import { isEmpty } from '../../helpers';
import { Row } from '../../Theme';
import AdCell from './AdCell';

class AdDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: []
        }
    }

    componentDidMount() {
        let { primary } = this.props;
        let params = {operation: 'getAdsToDisplay', noAuth: true, adCount: primary['ad-count'], adKey: primary['ad-pool-key']};
        Ajax.call(api.adService, params)
            .then(data => {
                if(data.success) {
                    let a = this.setRows(data.ads);
                    this.setState({ ads: a });
                }
            }).catch(e => e);
    }

    setRows(ads) {
        var chunkedAds = [];
        while (ads.length) {
            chunkedAds.push(ads.splice(0, maxInRow));
        }
        return chunkedAds;
    }

    printRows() {
        let { ads } = this.state;
        var html = ads.map((adRow, i) => {
            return (<Row key={i}>
                {this.printRow(adRow)}
            </Row>);
        })
        return html;
    }

    printRow(adRow) {
        var grid = Math.ceil(12 / adRow.length);
        var row = adRow.map((row, i) => {
            return (<div className={`col-sm-${grid}`} key={i}>
                <AdCell ad={row} />
            </div>);
        });
        return row;
    }

    render() {
        let { ads } = this.state;
        if(isEmpty(ads)) {
            return null;
        }
        return (<div className="container">
            {this.printRows()}
        </div>);
    }
}
const maxInRow = 4;
export default AdDisplay;