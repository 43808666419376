import React from 'react';
import LimitType from './LimitType';

/** renders the content if no properties found */
export default function NoResult(props) {
    let { Provider, limitType, resetProviders } = props;
    return (
        <div className="container-fluid page-wrapper">
            <div className="container">
                <div className="row">
                    <div style={{backgroundColor: '#fff'}}>
                        <div id="no-result" style={{paddingTop: '25px'}}>
                            <div className="container-center">
                                <h2>Hoppsan!</h2>
                                <div className="container-inner-center">
                                    Vi kunde inte hitta några passande boenden. Vänligen försök igen med andra datum.
                                </div>
                                <div className="col-xs-12">
                                    <LimitType Provider={Provider} limitType={limitType} resetProviders={resetProviders} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}