import React, {Component} from 'react';
import { isEmpty, priceFormat } from '../../helpers';
import Ajax from '../../api/Ajax';
import api from '../../config/api';
import Slider from "react-slick";
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class PrioTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            property: [],
            propModal: {},
            isOpen: false
        }
    }

    /** fetches the priority properties after mounting */
    componentDidMount() {
        this.fetchPrioData(this.props.properties, this.props.item);
    }

    /** sets the description */
    setDescription = desc => desc.replace(/<[^>]*>?/gm, '').replace(/\s\s+/g, ' ').split(" ").splice(0,40).join(" ");

    /** handles the clickout event for the modal */
    clickOut = (e, property, prioProperty, item) => {
        let params = {
            Url: property.Url,
            Name: property.Name,
            Image: property.Image,
            ProviderKey: prioProperty.ProviderKey,
            PropertyKey: prioProperty.PropertyKey,
            IsPrio: true,
            IsPayingPartner: item.IsPayingPartner
        };
        return this.props.clickOut(params);
    }

    /** fetches the data */
    fetchPrioData(properties, item) {
        var prio = [];
        var propLength = properties.length;  
        var cnt = 0;
        properties.forEach(async (property, propKey) => {
            let requestData = {
                operation: 'getPrioPropertyDetails',
                ProviderKey: item.ProviderKey,
                PropertyKey: property.Id,
                noAuth: true
            }
            var data = await Ajax.call(api.propertyService, requestData);
            if(data.property && !isEmpty(data.property)) {
                let prioProp = data.property;
                if(propKey === 0) {
                    prio = [];
                }
                prio.push(
                    <div className="row" key={propKey}>
                        <div className='col-xs-12'>
                            <div className="prio-row">
                                <div className="row">
                                    <div className='col-xs-12'>
										<div className="" style={{textAlign:'left',paddingTop:'15px',paddingBottom:'10px'}}>
                                            <div className="row">
                                                <div className="prio-prop-title">
                                                    <div className='prio-prop-header'><span style={{cursor: 'pointer'}} 
                                                        onClick={() => this.props.addDrawer(property.Slug)}>
                                                            {property.Name} - {item.Name} &gt;</span></div>
                                                </div>
                                                <div className="prio-prop-price">
                                                    <span className="duplicate-anchor prio-prop-header" style={{fontWeight:'bold'}} 
                                                        onClick={(e) => this.addDrawer(property.Slug)}>Pris: {priceFormat(property.Price)} &gt;</span>
                                                </div>
                                            </div>
										</div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div style={{paddingBottom: '15px'}}>
                                        	{this.setDescription(prioProp.Description)}
										</div>
                                        
                                        <Slider {...slickSettings}>
                                            {prioProp.images.map((image, i) => (
                                                <div key={i} className="duplicate-anchor" 
                                                    onClick={() => this.setState({ isOpen: true, photo: image.Url })}>
                                                <img src={image.ThumbUrl} alt="" style={{height:'120px',width:'120px',objectFit:'cover'}} />
                                                </div> 
                                            ))}
                                        </Slider>
                                        
                                        <div className="col-xs-12 text-right">
                                            <span className="duplicate-anchor1" style={{fontWeight:'bold'}} 
                                                onClick={(e) => this.clickOut(e, property, data.property, item)}>Läs mer och boka &gt;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            ++cnt;
            if(propLength === cnt) {
                this.setState({property: prio})
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.property}
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.photo}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                    )} 
            </React.Fragment>
        )
    }
}
const slickSettings = {
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    focusOnSelect: true,
    responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },{
            breakpoint: 1024,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },{
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2
            }
        },{
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
};
export default PrioTemplate