import React, { useState } from 'react'
import PropertyInfoSearch from '../Components/PropertyInfoSearch';
import PriceTab from './PriceTab';
import PropertyCarousel from '../Components/PropertyCarousel'
import Tabs from './Tabs'
import { isEmpty, providerSiteFromKey } from '../../helpers';
import { Row } from '../../Theme';
import PropertyModal from '../SearchProperty/PropertyModal';
import InfoTab from './InfoTab'
import CleaningTab from './CleaningTab'
import ContactOwner from '../Components/ContactOwner'
import MapLocation from '../Components/MapLocation'

function TabContainer(props) {

    var [ state, setTheState ] = useState({
        modalProperty: {},
        prices: {},
        loading: true,
        protectedPage: false,
        verifyEntity: '',
        tabDisplay: 'INFO',
        showSearchBox: false,
        headerImage: ''
    });
    function setState(state) {
        setTheState(prev => ({ ...prev, ...state }))
    }

    function isTab(tab) {
        return state.tabDisplay === tab
    }
	
	
    function emptyModal() { setState({ modalProperty: {} }) }

    let { showSearchBox } = state
    let { property, prices, images, cleaningPrice } = props
  
    var propertyKey = '';
    if(property != null) {
        propertyKey = property.PropKey? property.PropKey: property.PropertyKey;
    }

    return (<>
        <Row>
            <div className="col-xs-12" style={{margin:'15px',display:'flex',alignItems:'center',flexDirection:'column'}}>
                <Tabs prices={prices} property={property} providerSiteFromKey={providerSiteFromKey}
                    setState={setState.bind(this)} isTab={isTab.bind(this)} />
            </div>
        </Row>
        <Row>
            <div className={`col-xs-12 col-md-${showSearchBox?'9':'12'}`}>
                <div className="property-info-panels">
                    {isTab('INFO') && <>
                        <h3 className="no">Beskrivning</h3>
                        <div className="property-info-panel row element-linebreaks" id={'property-info-0'}>
                            <InfoTab property={property} keyWords={props.keyWords} />
                        </div>
                    </>}
                    {isTab('MEDIA') &&
                        <div className="property-info-panel" id={'property-info-1'}>
                            <h3 className="no">Bilder</h3>
                        
                            Bildkälla: {providerSiteFromKey(property.ProviderKey)}
                            
                            {!isEmpty(images) && <PropertyCarousel images={images} />}
                        </div>
                    }
                    {isTab('PRICE') &&
                        <div className="property-info-panel" id={'property-info-2'}>
                            {!isEmpty(prices) && <div>
                                <PriceTab property={property} {...props.match.params} prices={prices} />
                            </div>}
                        </div>
                    }
                    {isTab('CLEANING') &&
                        <div className="property-info-panel" id={'property-info-4'}>
                            <div>
                            
                                <CleaningTab property={property} {...props.match.params} cleaningPrice={cleaningPrice} />
                            </div>
                        </div>
                    }
                    {isTab('MAP') &&
                        <div className="property-info-panel" id={'property-info-3'}>
                            <h3 className="no">Karta</h3>
                            <MapLocation property={property} />
                        </div>
                    }
                    {isTab('CONTACT') && <ContactOwner ID={property.Id} />}
                </div>
                
            </div>
            {showSearchBox && <div className="col-xs-12 col-md-3">
                <PropertyInfoSearch PropertyKey={propertyKey} ProviderKey={property.ProviderKey} PropertyName={property.Name} />				                   
            </div>}
            {(!isEmpty(state.modalProperty)) && 
                <PropertyModal property={state.modalProperty} emptyModal={emptyModal.bind(this)} />}
        </Row>
    </>)
}
export default TabContainer