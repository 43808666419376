export default function replaceSpecialChars(str) {
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAll = function(search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
    };
    let replacable = {
        'ä': 'a',
        'å': 'a',
        'ö': 'o',
        " ": "",
        "'": "",
        "+": "",
        'ø': 'o',
        "(": "",
        ")": "",
        'æ': 'a',
        ",": "",
        "%": "",
    }
    for (var rep in replacable) {
        if (replacable.hasOwnProperty(rep)) {
            str = str.replaceAll(rep, replacable[rep]);
        }
    }
    return str;
}