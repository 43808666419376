import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Row, Form, FormRow, Input, Submit, Checkbox } from '../../Theme';
import validate from '../../lib/validate';
import { isEmpty } from '../../helpers';
import Ajax from '../../api/Ajax';
import api from '../../config/api';
import { toast } from 'react-toastify';
import { LoadingButton } from '../../Components';
import { dateSplit } from '../../lib/DateZone'

class EmailServiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            loading: false,
            errors: {},
            tncCheck: true
        }
    }

    removeModal = () => {
        this.setState({ modalVisible: false, loading: false });
    }

    handleChange = (name, value) => {
        this.setState({[name]: value, errors: {}});
    }

    handleCheck = () => {
        let { tncCheck } = this.state;
        this.setState({tncCheck: !tncCheck});
    }
	
	formatDate = (date) => {
		var d = dateSplit(date);
		return d.getDate() +"/" +(d.getMonth() + 1);
	}

    handleSubmit = e => {
        e.preventDefault();
        let errors = validate(this.state, {Email: {required: true, email: true}, tncCheck: {required: true}});
        if(!isEmpty(errors)) {
            this.setState({ errors });return false;
        }
        let { destination, area, fromDate, toDate } = this.props;
        let params = {
            operation: 'savePropertyEmailSubscription',
            destination, area, fromDate, toDate, email: this.state.Email,
            noAuth: true
        }
        this.setState({ loading: true });
        Ajax.call(api.eBookUserService, params)
            .then(data => {
                if(data.success) {
                    toast.success('Tack! Din prenumeration är nu registrerad!');
					this.setState({ modalVisible: false });
                } else {
                    toast.error('Ojdå, något gick fel. Vänligen ladda om sidan och försök igen');
                }
                this.setState({ loading: false });
            }).catch(() => {
                toast.error('Ojdå, något gick fel. Vänligen ladda om sidan och försök igen');
                this.setState({ loading: false });
            });
    }

    render() {
        let{ Email, errors, loading } = this.state;
        return (<>
			Vill du få förslag på passande boenden för denna sökning via e-post? 
            <div className="duplicate-anchor1" onClick={() => this.setState({ modalVisible: true })}>
                Klicka här för att starta en prenumeration! 
            </div>
			
            <Modal open={this.state.modalVisible} blockScroll={false} center onClose={this.removeModal} modalId="infoModal">
                <div className="modal-wrapper">
                    <div className="modal-header">
                        Prenumerera på lediga boenden för dessa datum ({this.formatDate(this.props.fromDate)} - {this.formatDate(this.props.toDate)}) via e-post!
                    </div>
                    <div className="modal-body">
                        <Row>
                            <div className="col-xs-12">
                                <Form errors={errors} onSubmit={this.handleSubmit}>
                                    <FormRow>
                                        <div className="col-xs-12 col-sm-7">
                                            <Input label="E-post" name="Email" value={Email} onChange={this.handleChange} />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-xs-12" style={{marginTop:'15px'}}>											
                                            <Checkbox name="tncCheck" checked={true} onChange={this.handleCheck} title="Jag accepterar att Skinova sparar min e-postadress för att möjliggöra kommande utskick. Självklart kan jag när som helst avsluta min prenumeration." />
                                            {errors.tncCheck && <div className="help-block error">Acceptera Skinovas villkor gällande hantering av dina upgifter</div>}
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-xs-12">
                                            {!loading? <Submit label="Lägg till min prenumeration!" />:
                                                <LoadingButton />}
                                        </div>
                                    </FormRow>
                                </Form>
                            </div>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>);
    }
}
export default EmailServiceModal;