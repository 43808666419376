import React from 'react';
import { isEmpty } from '../../helpers';

function Image(props) {
    if(props === undefined) {
        return null;
    }

    var url = props.src;
    if(isEmpty(url)) {
        url = '/images/placeholder-600x400.png';
    }
    return <img className="result-img" src={url} alt={props.alt} />
}
export default Image;