import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { isEmpty } from '../helpers';
import Menubar from './Menubar';
import {connect} from 'react-redux';
import BurgerMenu from './BurgerMenu';
import { Auth } from 'aws-amplify';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { redirectTo: '' }
    }

    signOut = () => {
        Auth.signOut()
    }
    
    render() {
        var { partner } = this.props;
        return (<>
            <div id="header_main" className="container_wrap container_wrap_logo">
                <div className="container av-logo-container">
                    <div className="inner-container">
                        <span className="logo">
                            <Link to="/">
                                <img src="/images/logo.png" alt="logo" />
                            </Link>
                        </span>
                        {!isEmpty(partner) && <div className="partner-logo-mob">
                            I samarbete med: 
                            <img src={partner.LogoUrl} alt={partner.Name} />
                        </div>}
                        <Menubar partner={partner} userRole={this.props.userRole} signOut={this.signOut.bind(this)} />
                    </div>
                </div>
            </div>
            <BurgerMenu userRole={this.props.userRole} signOut={this.signOut.bind(this)} />
        </>);
    }
}
const mapStateToProps = state => {
    return { 
        header: state.layout.header, 
        partner: state.partner.partner,
        userRole: state.authUser.role
    };
}
export default connect(mapStateToProps)(Header);