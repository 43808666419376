import Ajax from '../api/Ajax';
import api from '../config/api';
import isEmpty from './isEmpty';
import { getAppCookies } from '.';

function PropertyInfo(slug, skipPrice = false, updateAnalytics = false) {    
    return new Promise((resolve, reject) => {

        return getPropertyBySlug(updateAnalytics).then(data => {
            
            if(data.responseCode === 'PROPERTY_EXISTS') {
                return setEbookProperty(data);
            } else if(data.responseCode === 'PROPERTY_NOT_EXISTS') {
                return checkWithSlug();
            } else if(data.responseCode === 'PROTECTED') {
                skipPrice = true;
                return { protectedPage: true, markResolve: true, verifyEntity: data.verifyEntity };
            } else if(data.responseCode === 'CONSTRAINT_FAILED') {
                reject(new Error('CONSTRAINT_FAILED'));
            }
        })
        .then(data => {
            if(data.markResolve) {
                return data;
            } else if(data.PROPERTY_EXISTS) {
                return getPropertyByKeys(data.property).then(data => {
                    if(data.responseCode === 'PROPERTY_EXISTS') {
                        return setEbookProperty(data);
                    } else if(data.responseCode === 'PROPERTY_NOT_EXISTS') {
                        return getPropertyInfo(slug);
                    } else if(data.responseCode === 'PROTECTED') {
                        skipPrice = true;
                        return { protectedPage: true, markResolve: true, verifyEntity: data.verifyEntity };
                    }
                }).catch(() => getPropertyInfo(slug));
            } else {
                return getPropertyInfo(slug);
            }
        })
        .then(data => {
            if(data.markResolve) {
                return data;
            } else {
                return {
                    property: data,
                    keyWords: getKeyWords(data.KeyWords),
                    images: data.Images.map(i => { return { original: i.Image, thumbnail: i.Image } })
                };
            }
        })
        .then(info => {
            if(!skipPrice) {
                getPrice(info.property).then(data => {

                    getCleaningPrice(info.property).then(cleaning=> {
                        if(cleaning.success && !isEmpty(cleaning.cleaningPrice) && cleaning.cleaningPrice.length==1) {
                            info.cleaningPrice = cleaning.cleaningPrice[0];
                        }

                        if(data.success && !isEmpty(data.prices)) {
                            info.prices = data.prices;
                            resolve(info);
                        } else {
                            resolve(info)
                        }
                    });
                });
            } else {
                resolve(info)
            }
        })
        .catch(() => reject(new Error('error')));
    })

    /** gets the ebook property by slug */
    function getPropertyBySlug(updateAnalytics) {
        let cookies = getAppCookies();
        cookies = cookies.verifiedEntities;
        var requestData = {
            operation: "getPropertyBySlug", 
            slug,
            noAuth: true,
            updateAnalytics: updateAnalytics,
            verifiedEntities: cookies,
            labels: true
        }
        return Ajax.call(api.ebspPropService, requestData);
    }

    /** sets the info for setting the state */
    function setEbookProperty(data) {
        let property = data.property;
        var images = {};
        if(!isEmpty(property.images)) {
            images = property.images.map(i => ({ original: i.Url, thumbnail: i.ThumbUrl, mediaType: i.MediaType }));
        }        
        return {
            property: property,
            keyWords: property.KeyWords.split(",").filter(k => k != null && k.length > 3),
            images: images,
            markResolve: true
        }
    }

    /** checks the propert availbale in propertyInfo table by slug */
    function checkWithSlug() {
        var requestData = {
            operation: "checkWithSlug",
            updateAnalytics: updateAnalytics,
            slug: slug,
            noAuth: true         
        }
        return Ajax.call(api.ebspPropService, requestData);
    }

    /** gets the property by keys is available by slug in infoProperty table */
    function getPropertyByKeys(property) {
        let cookies = getAppCookies();
        cookies = cookies.verifiedEntities;
        const {ProviderKey, PropertyKey} = property;
        let requestData = {operation: "_getPropertyByKeys", ProviderKey, PropertyKey, verifiedEntities: cookies};
        return Ajax.call(api.ebspPropService, requestData);
    }

    /** gets the price to display in table */
    function getPrice(property) {
        var {ProviderKey, PropertyKey} = property;
        PropertyKey = PropertyKey === undefined? property.PropKey: PropertyKey;
        var requestData = {
            operation: "getPriceToDisplayMonthly", 
            propertyID: property.Id,
            propertyType: property.PropertyType,
            ProviderKey, PropertyKey,
            noAuth: true
        }
        return Ajax.call(api.priceService, requestData)
    }

    /** gets the price to display in table */
    function getCleaningPrice(property) {
        var requestData = {
            operation: "getCleaningPrice", 
            propertyID: property.Id,
            noAuth: true
        }
        return Ajax.call(api.priceService, requestData)
    }

    /** fetches the property from the slug */
    function getPropertyInfo(slug) {
        let requestData = {
            CacheType: "getPropertyInfo",
            Args: slug
        };
        return Ajax.call(api.cacheService, requestData);
    }

    /**creates the keywords. */
    function getKeyWords(keyWords) {
        if(isEmpty(keyWords)) {
            return '';
        }
        if(keyWords.indexOf('<br') >= 0) {
            keyWords = keyWords.substring(0, keyWords.indexOf('<br'));
        }
        keyWords = keyWords.replace(/\s\s+/g, ' ');
        keyWords = keyWords.split(/[,.]+/).filter(
            k => {
                k = k.trim();
                let len = k.split(" ").length;
                return (k != null && k !== '' && (len <= 6))
            }
        );
        return keyWords;
    }
}

export default PropertyInfo;