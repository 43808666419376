import React, {Component} from 'react';
import { isEmpty, providerSiteFromKey } from '../../helpers';
import PropertyAccordion from './PropertyAccordion';
import PropertyModal from './PropertyModal';
import { PropertyDescription, PropertyImage } from '../../Components';
import PrioTemplate from './PrioTemplate';
import InfoModal from './InfoModal';
import Ajax from '../../api/Ajax';
import api from '../../config/api';
import AdInSearch from './AdInSearch';
import Drawer from '../../lib/Drawer';
import PropertyDrawer from './PropertyDrawer'

class PropertyCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            property: {},
            showInfoModal: false,
            slug: '',
            showDrawer: false
        }
    }

    /** sets the properties for the modal */
    setProperties = (item) => {
        var properties = {
            prop: [],
            prio: []
        };
        item.Properties.map((property, propKey) => {
            if(property.IsPrio && this.props.provider !== 'EBOOK') {
                properties.prio.push(property);
            } else {
                properties.prop.push(property);
            }
            return true;
        })
        return properties;
    }

    /** handles the clickout event for the modal */
    clickOut = property => {
        this.setState({property});
        this.updateAnalytics(property);
    }

    /** removes the modal */
    emptyModal = () => this.setState({property: {}})

    /** opens the property info modal */
    openInfoModal = slug => this.setState({showInfoModal: true, slug: slug})

    /**removes the info modal */
    removeInfoModal = () => this.setState({showInfoModal: false, slug: ''})

    updateAnalytics = property => {
        let { ProviderKey, PropertyKey } = property;
        let requestData = {
            operation: 'updateAnalytics', type: '3', propertyKey: PropertyKey, providerKey: ProviderKey, noAuth: true
        }
        Ajax.call(api.propAnalyticsService, requestData).catch(e => '');
    }

    addDrawer = slug => {
        this.setState({ showDrawer: true, slug });
    }

    removeDrawer = () => {
        this.setState({ showDrawer: false, slug: '' });
    }

    render() {
        var { fromDate, toDate, searchInfo } = this.props;
        var { property } = this.state;
        return (<>
		
            {searchInfo.map((item, index) => {
                let properties = this.setProperties(item)
                return (<div className="row property-search-row" key={index}>
                    <div className="col-xs-12">
                        <div style={{padding: '20px 0'}}>
							<div className="row">
	                        <div style={{position: 'relative', float: 'left'}}>
	                            <PropertyImage src={item.Image} alt={item.Name} />
	                            <div className="result-img-copyright">Bildkälla: {providerSiteFromKey(item.ProviderKey)}</div>
	                        </div>
	                        <div className="resort-logo-wrapper">
	                            <img className="resort-logo" src={`/images/${item.ProviderKey.toUpperCase()}-logo.png`} alt={item.ResortKey} />
	                        </div>
	                        <div className="resort-heading-wrapper">
	                            <img src={`/images/icons/${item.Country}-icon.png`} alt={item.Country} className="resort-country" />
	                            <h4 className="resort-heading">{item.Resort} - {item.Name}</h4>
	                        </div>
	                        <p className="more"><PropertyDescription description={item.Description} /></p>
	                        <p style={{fontSize:'12px'}}>Källa: {providerSiteFromKey(item.ProviderKey)}</p>
	                        <div style={{clear:'both'}}></div>
	                        {!isEmpty(properties.prio) && <div className="prio-properties-container">
                                    <PrioTemplate properties={properties.prio} item={item} index={index} 
                                        clickOut={this.clickOut.bind(this)} openInfoModal={this.openInfoModal.bind(this)} 
                                        addDrawer={this.addDrawer.bind(this)}/>
                                </div>}
							</div>
	                        <div style={{clear:'both'}}></div>
	                        {!isEmpty(properties.prop) && 
	                            <PropertyAccordion properties={properties.prop} index={index} count={properties.prop.length}
	                                item={item} fromDate={fromDate} toDate={toDate} addDrawer={this.addDrawer.bind(this)}
	                                openInfoModal={this.openInfoModal.bind(this)} />}
	                        <div style={{clear:'both'}}></div>
                        </div>
                    </div>
                    {index === 3 && <AdInSearch />}
                </div>)
            })}
            {(!isEmpty(property)) && 
                <PropertyModal property={property} emptyModal={this.emptyModal.bind(this)} />}
            {(this.state.showInfoModal) && <InfoModal slug={this.state.slug} removeInfoModal={this.removeInfoModal.bind(this)} />}
            <Drawer isOpen={this.state.showDrawer} 
                onClose={this.removeDrawer.bind(this)} title="Detaljerad beskrivning" 
                style={{zIndex: 999, maxWidth: '85%'}} >
                <PropertyDrawer slug={this.state.slug} />
            </Drawer>
            </>
        )
    }
}
export default PropertyCard