import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

function SkinovaPanel(props) {
    let icon = props.icon? props.icon: 'fa-arrow-circle-left';
    let iconTitle = props.iconTitle? props.iconTitle: '';
    return (
        <Group>
            <Panel>
                <Heading noMargin={props.noMargin}>{props.title}
                    {props.backLink && 
                    <Link to={props.backLink} style={{color:'#000',fontSize:'20px',float:'right'}}>
                        <i title={iconTitle} className={`fa ${icon}`}></i>
                    </Link>}
                </Heading>
                {props.children && <Body>{props.children}</Body>}
            </Panel>
        </Group>
    );
}
const Group = styled.div`
    margin-bottom: 10px;
`
const Panel = styled.div``
const Heading = styled.div`
    border-radius: 0;
    border-bottom: 2px solid #00d1ff;
    padding: 20px 0px;
    font-size: 18px;
    font-weight: bold;
    margin: ${props => props.noMargin ? ' 0;' : ' 0 15px;'}`
const Body = styled.div`padding: 15px;`
export default SkinovaPanel;