import React, {Component} from 'react';
import { RichText } from 'prismic-reactjs';
import Slider from "react-slick";
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import PropertyModal from '../SearchProperty/PropertyModal';
import { isEmpty } from '../../helpers';

const slickSettings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },{
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },{
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2
            }
        },{
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
};

class SearchProperties extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            modalOpen: false,
            timer: 0,
            property: {}
        }
        this.timeOut = '';
    }

    /** handles the clickout event of the modal */
    clickOut = (e, property) => {
        let params = {
            Url: property.PriceFromUrl,
            Name: property.Name,
            Image: property.Image,
            ProviderKey: property.ProviderKey  
        };
        this.setState({property: params});
    }

    /** removes the modal */
    emptyModal = () => {
        this.setState({property: {}})
    }
    
    render() {
        return (
            <React.Fragment>
            <div className="container-fluid search-properties-template" style={{backgroundColor:this.props.primary['background-color']}}>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="container-center">
                                    <div className="container-inner-center">
                                        {RichText.render(this.props.primary['search-properties-title'])}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <Slider {...slickSettings} className="equal search-properties-carousel search-properties-item">
                                    {this.props.searchData.map((search, i) => {
                                        return (<div key={i}>
                                            <div style={{padding:'10px'}}>
                                                <p className="area-header">{search.Resort}</p>
                                                <div style={{height:'114px'}}>
                                                    <img style={{maxHeight: '114px'}} src={search.Image} alt="" />
                                                </div>
                                                <div style={{height:'3em'}}>
                                                    <p style={{clear:'both'}}>{search.Name}</p>
                                                </div>
                                                <p style={{marginTop:'10px',marginBottom:'10px',fontWeight:'bold'}}>Pris från: {search.PriceFrom}kr</p>
                                                <div id={`${search.ProviderKey}-${i}`} provider={search.ProviderKey}  className="stdbtn clickout" style={{width:'100%'}} onClick={(e) => this.clickOut(e, search)}>Läs mer & Boka</div>
                                                <div style={{clear:'both'}}></div>
                                            </div>
                                        </div>)
                                    })}                        
                                </Slider>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isEmpty(this.state.property) && <PropertyModal property={this.state.property} emptyModal={this.emptyModal.bind(this)} />}
        </React.Fragment>);
    }
}
export default SearchProperties;