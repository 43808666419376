import api from '../config/api.js';
import Ajax from './Ajax';

class Country {
    /**
     * calls the API for fetching all the countries.
     */
    fetchAll() {
        var requestData = {
            operation: 'getCountries'
        };
        return Ajax.call(api.masterService, requestData);
    }
}
export default Country;