import api from '../config/api.js';
import Ajax from './Ajax';

class Booking {

    /** gets the booking list for the user */
    getBookingsForUser(params) {
        params.operation = 'bookingList'
        return Ajax.call(api.bookingService, params)
    }

    /** deleted the booking */
    delete(bookingNumber) {
        var requestData = {
            operation: "deleteBooking",
            bookingNumber: bookingNumber,
        };
        return Ajax.call(api.bookingService, requestData);
    }

    /** gets the booking */
    getBooking(bookingNumber) {
        var requestData = {
            operation: "getBookingInfo",
            bookingNumber: bookingNumber,
            paymentStatus: true
        };        
        return Ajax.call(api.bookingService, requestData);
    }

    /** creates the booking */
    createBooking(fields) {        
        let requestData = {
            operation: "saveBooking",
            fields
        };
        return Ajax.call(api.bookingService, requestData)
    }

    /** aborts the xhr */
    abortXHR() {
        Ajax.abort();
    }
}
export default Booking;