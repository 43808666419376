import React from 'react';

/** renders the multi campaign on the home page */
function PropertyOwner(props) {
    return (
        <div className="container-fluid property-owner">
			<div className="row equal">
				<div className="col-xs-12 col-md-6 column col1" style={{backgroundColor:'#00aae2'}}>
					<h3><span style={{color:'#02197c'}}>Hyr du redan ut ditt<br/>boende via en</span><span style={{color:'#00d1ff'}}> bokningsleverantör?</span></h3>
					<p style={{color:'#02197c'}}>Vi hjälper dig att öka din uthyrning genom marknadsföring i vår sökmotor och i våra sociala medier.</p>
					<div className="skibtn btn-cms"><img src="https://images.prismic.io/skinova/c10e9682-4463-44a9-a075-c6e7e0f292c7_house.png?auto=compress,format" alt="" /><a href="https://www.skinova.se/register" target="_blank" rel="noopener noreferrer">Kom igång &gt;</a></div>
				</div>
				<div className="col-xs-12 col-md-6 column col2" style={{backgroundColor:'#d2d2dc'}}>
					<h3><span style={{color:'#0059ff'}}>Hanterar du </span><span style={{color:'#02197c'}}>uthyrningar<br/>och bokningar</span><br/>
					<span style={{color:'#0059ff'}}>själv?</span>					
					</h3>
					<p style={{color:'#02197c'}}>Hantera bokningar, kommunikation, bilder, betalningar och mycket mer genom Skinovas enkla bokningssystem.</p>
					
					<div className="skibtn btn-cms"><img src="https://images.prismic.io/skinova/c10e9682-4463-44a9-a075-c6e7e0f292c7_house.png?auto=compress,format" alt="" /><a href="https://www.skinova.se/register" target="_blank" rel="noopener noreferrer">Kom igång &gt;</a></div>
				</div>
			</div>
		</div>
    );
}
export default PropertyOwner;