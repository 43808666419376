import React from 'react';
import styled from 'styled-components';

function ImageRemove(props) {
    return (
        <ImgWrap>
            <Close onClick={props.onRemove}><i className="fa fa-times" title="Remove" style={iconStyle} aria-hidden="true"></i></Close>
            <img src={props.src} alt={props.alt}  />
        </ImgWrap>
    )
}
const iconStyle = {
    display: 'inline-block',
    borderRadius: '60px',
    boxShadow: '0px 0px 2px #d2d2dc',
    padding: '0.5em 0.6em',
    background: '#d2d2dc'
}
const ImgWrap = styled.div`position: relative;`
const Close = styled.span`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
    cursor: pointer;`
export default ImageRemove;