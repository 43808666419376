import React from 'react';
import FormContext from './FormContext';

const Textarea = ({ label, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context.errors;
            return <>
            {label && <label>{label}</label>}
            <textarea type="text" 
                value={props.value || ''}
                className={`form-control ${errors[props.name]? 'has-error': ''}`} 
                {...props}
                onChange={changeHandler}></textarea>
            {(context.errMsgShow && errors[props.name]) && <div className="help-block error">{errors[props.name]}</div>}
            </>
        }}
    </FormContext.Consumer>
}
export default Textarea;