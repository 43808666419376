import React from 'react';

function LimitType(props) {
    let { limitType, Provider } = props;
    return (<>
        {limitType !== '' && <div>
            {limitType === 'forProvider' && <div>
                Din sökning är begränsad till 
                <span className="navigable" onClick={props.resetProviders} style={resetIconStyle}>
                    <img style={providerLogo} src={`/images/${Provider}-logo.png`} alt={Provider} />
                    <i className="fa fa-times-circle"></i>
                Återställ och visa hela sökresultatet
					</span>
                
            </div>}
            {limitType === 'forProperty' && <div>
                Din sökning är begränsad till en specifik enhet. 
                <span className="navigable" onClick={props.resetProviders} style={resetIconStyle}>
                    <img style={providerLogo} src={`/images/${Provider}-logo.png`} alt={Provider} />
                    <i className="fa fa-times-circle"></i> 
                	Återställ och visa hela sökresultatet
				</span>                
            </div>}
        </div>}
    </>)
}
const providerLogo = {
    height: '50px',
    border: '1px solid #e0e0e0',
    marginLeft: '5px',
	marginRight:'5px'
}
const resetIconStyle = {
    margin: '0px 5px'
}
export default LimitType;