import { Auth } from 'aws-amplify';
/**
 * returns the loginstate of the provider
 */
function loginState() {
    return new Promise((resolve, reject) => {                
        Auth.currentAuthenticatedUser().then(() => {
            resolve('user');
        }).catch(() => {
            let provider = JSON.parse(localStorage.getItem("provider"));
            if(provider) {
                resolve('provider');
            } else {
                let admin = JSON.parse(localStorage.getItem("admin"));
                if(admin) {
                    resolve ('admin');
                } else {
                    resolve(false);
                }
            }
        })
    })    
}
export default loginState;