import React, {Component} from 'react';
import { isEmpty } from '../../helpers';

class SearchLoading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: 0,
        }
        this.timeOut = '';		
    }

    /** mounts the component and intializes the timer */
    componentDidMount = () => this.initTimer()

    /** removes the timer before leaving the component */
    componentDidUpdate(prevProps) {
        if( prevProps.searchLoading !== this.props.searchLoading ) {            
            clearTimeout(this.timeOut);
            this.setState({timer: 0}, () => this.initTimer());
        }
    }

    /** unmounts the component */
    componentWillUnmount() {
        this.setState({timer: 0})
        clearTimeout(this.timeOut);
    }

    /** inits the timer */
    initTimer = () => {
        let { timer } = this.state;
        if(timer <= 45) {
            this.setState({timer: timer + 1})
            this.timeOut = setTimeout(this.initTimer, 1000);
        }
    }

    render() {  
        let {timer} = this.state;
        let { destination, hideLogos } = this.props
        return (
            <div className="container-fluid page-wrapper">
                <div className="container">
                    <div className="row">
                        <div style={{backgroundColor: '#fff'}}>
                            <div className="container-center" id="loader">
                                <div className="container-inner-center" style={{marginTop: '20px'}}>
                                    <div style={{width: '100%'}}>
                                        <img src="/images/ajax-loader.gif" style={{marginRight: '10px'}} alt="" />
                                        <h2>Vänligen vänta, söker ledigt boende...</h2>
										
                                        
										{((!hideLogos && timer > 5)) &&
                                        <>																		
										<p style={{marginTop: '10px'}}>Vi gör nu en djupdykning och söker hos flera skidanläggningar och bokningsleverantörer.</p>
										<h2 id="loader-ticker">{this.state.timer}</h2>
										<p style={{marginTop: '10px'}}>Skinova kontrollerar tillgänglighet och priser från följande skidanläggningar och bokningsleverantörer. Det brukar ta max 30 sekunder!</p>
										{(isEmpty(destination)) && <div id="search-logos">
                                            <div className="container-center">                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/IDRE-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: idrefjall.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/BRA-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: branas.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/GT-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: gaustatoppenbooking.no</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/SKI-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: skistar.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/GUMO-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: gumo.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/STO-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: stoten.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/FUN-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: funasfjallen.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/KLA-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: klappen.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/BM-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: bengt-martins.se</div>
                                                </div>                                                
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/HAF-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: hafjall.no</div>
                                                </div>
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/VEM-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: bokavemdalen.se</div>
                                                </div>
                                                <div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/SAF-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: safsen.se</div>
                                                </div>
												<div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/IDREH-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: idrehimmelfjall.se</div>
                                                </div>
												<div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/TANN-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: tanndalen.com</div>
                                                </div>
												<div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/UNO-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: unos.se</div>
                                                </div>
												<div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/ARE-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: mittiare.se</div>
                                                </div>
												<div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/SKIBRA-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: skibranas.se</div>
                                                </div>
												<div className="search-logo-container">
                                                    <img className="search-logo-img" src="/images/AGOVEM-logo.png" alt="" />
                                                    <div className="search-logo-img-copyright">Källa: agoivemdalen.se</div>
                                                </div>
                                            </div>											
                                        </div>}
										</>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchLoading;