import $ from 'jquery';
import '../styles/jquery-ui.css';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/i18n/datepicker-sv.js';

export default function setSearchDatePicker(fromDate, toDate, callback, defaultDate) {
    $(fromDate.current).datepicker({
        dateFormat: 'yy-mm-dd',
        showOtherMonths: true,
        firstDay: 1,
        defaultDate: defaultDate,
        beforeShowDay: date => firstPickerDays(date),
        onSelect: date => {
            var dateFromString = date.toString("yyyy-MM-dd");
            let nextDate = setSecondDate(dateFromString);
            callback({fromDate: date, toDate: nextDate.toString("yyyy-MM-dd")});
            $(toDate.current).datepicker({
                dateFormat: 'yy-mm-dd',
                showOtherMonths: true,
                firstDay: 1,
                defaultDate: nextDate,
                beforeShowDay: (dateTo) => secondPickerDays(dateTo, dateFromString),
                onSelect: date => callback({toDate: date})
            });
            setTimeout(() => $(toDate.current).datepicker("show"), 400);
        }
    });
    /**
     * sets the date for second datepicker
     */
    function setSecondDate(dateFromString) {
        if(dateFromString === '' || dateFromString === null) {
            return '';
        }
        $(toDate.current).datepicker("destroy");
        let date = Date.parse(dateFromString);
        if (date.is().thursday() || date.is().sunday()) {
            return date.next().sunday();
        } else if (date.is().saturday()) {
            return date.next().saturday();
        }
    }
    /**
     * gets the enabled dats for first datepicker
     */
    function firstPickerDays(date) {
        var isValid = (date.is().thursday() || date.is().saturday() || date.is().sunday());
        var css = isValid ? 'highlight-book' : '';
        return [isValid, css];
    }
    /**
     * gets the enabled dats for second datepicker
     */
    function secondPickerDays(dateTo, dateFromString) {
        var tmpDateFrom = Date.parse(dateFromString);
        var isValidDateTo = false;
        if (tmpDateFrom.is().thursday()) {
            isValidDateTo = dateTo.equals(tmpDateFrom.next().sunday());
        } else if (tmpDateFrom.is().saturday()) {
            isValidDateTo = dateTo.equals(tmpDateFrom.next().saturday());
        } else if (tmpDateFrom.is().sunday()) {
            isValidDateTo = dateTo.equals(Date.parse(dateFromString).next().sunday()) || dateTo.equals(Date.parse(dateFromString).next().thursday());
        }
        if (isValidDateTo) {
            return [true, ' highlight-book'];
        }
        return [false, ''];
    }
}