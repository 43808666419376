import React from 'react';
import '../styles/radio-button.css';

const RadioButton = props => {
    return(
        <label className="radio-inline">
            <span>{props.title}</span>
            <input type="radio" name={props.name} value={props.value} onChange={props.onChange} checked={props.checked} />
            <span className="checkround"></span>
        </label>
    )
}
export default RadioButton;