export default function providerSiteFromKey(key) {
    let sites = {
        BM: "bengt-martins.se",
        VEM: "bokavemdalen.se",
        BRA: "branas.se",
        EBOOK: "skinova.se",
        FUN: "funasfjallen.se",
        GT: "gaustatoppenbooking.com",
        gumo: "gumo.se",
        HAF: "hafjell.no",
        IDRE: "idrefjall.se",
		IDREH: "idrehimmelfjall.se",
		SKEI: "skeikampen.no",
        KLA: "klappen.se",
        SKI: "skistar.se",
        STO: "stoten.se",
        SAF: "safsen.se",
        EBook: "skinova.se",
		KLA2: "klappen.se",
        KLA3: "klappen.se",
		UNO: "unos.se",
		ARE: "mittiare.se",
		ORSA: "orsagronklitt.se",
		AGOVEM: "agoivemdalen.se",
		SKIBRA: "skibranas.se"
    };
    return sites[key];
}
