import React from 'react';
import Modal from 'react-responsive-modal';
import { isEmpty } from '../../helpers';

function PropertyModalContent(props) {
    var { property, modalOpen, timer, noTimerMessage } = props;
    
    return (
        <div className="container-center">
            <div className="container-inner-center">
                {!isEmpty(property) && <Modal 
                    open={modalOpen} blockScroll={false} 
                    onClose={props.closeModal} center modalId="property-modal-search">
                    <div className="feather-modal-wrapper">
                        {(noTimerMessage !== '')? 
                            <div style={{paddingTop: '20px'}}><h3>{noTimerMessage}</h3></div>:
                            <><div style={{paddingTop: '20px'}}>
                                <h3>Du länkas nu vidare!</h3>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <p>Tack för att du använt skinova.se - lycka till med jakten på boende i fjällen!</p>
                                    <p className="popup-link">
                                        <a onClick={props.clickOutClose} href={property.Url} rel="noopener noreferrer" target="_BLANK" >{property.linkShort}</a>
                                    </p>
                                    <h3 id="click-out-ticker">{timer}</h3>
                                    <h4 style={{marginTop:'20px'}}>{property.Name}</h4>
                                    <img style={{maxHeight:'100px'}} src={`/images/${property.ProviderKey}-logo.png`} alt="" />
                                    <img style={{maxHeight:'100px'}} src={property.Image} alt="" />
                                </div>
                            </div>
                        </>}
                    </div>
                </Modal>}
            </div>
        </div>
    )
}
export default PropertyModalContent;