import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { isEmpty, Loading, providerSiteFromKey, propertyInfo } from '../../helpers';
import { Row } from '../../Theme';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import PriceDisplay from '../Components/PriceDisplay';
import PropertyInfoError from '../Components/PropertyInfoError';

function createMarkup(html) {
    return { __html: html };
}

class InfoModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: true,
            slug: this.props.slug,
            error: false,
            property: {}
        }
    }

    /** gets the property infor from the URL parameter */
    componentDidMount() {
        this._isMounted = true;
        this.setState({loading: true});
        let skipPrice = true;
        propertyInfo(this.props.slug, skipPrice)
            .then(info => this.setState({ ...info, loading: false }))
            .catch(() => this.setState({error: true, loading: false}))
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** closes the modal */
    closeModal = () => {
        this.setState({modalOpen: false});
        this.props.removeInfoModal();
    }

    render() {
        const { property, prices, loading, error } = this.state;
        return (
            <Modal open={this.state.modalOpen} blockScroll={false} center onClose={this.props.removeInfoModal} modalId="infoModal">
                {loading && <div className="info-loader"><Loading /></div>}
                {(typeof property.Id !== 'undefined' && property.Id > 0 && !isEmpty(property)) && <>
                <Row>
                    <div className="col-xs-12 col-sm-9">
                        <h3 dangerouslySetInnerHTML={createMarkup(property.Name)}></h3>
                        <h5><strong>{property.Resort} - {property.Area}</strong></h5>
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <img src={"/images/icons/" + property.Country + "-icon.png"} className="resort-country" alt="" />
                        {property.Country} - {property.Destination}
                    </div>
                </Row>
                <Row>
                    <div className="col-xs-12">
                        <div dangerouslySetInnerHTML={createMarkup(property.Description)}></div>
                        {providerSiteFromKey(property.ProviderKey)}
                    </div>                                
                </Row>
                <Row>
                    <div className="col-xs-12 col-md-8">
                        {!isEmpty(this.state.images) && <ImageGallery items={this.state.images} showFullscreenButton={false} showPlayButton={false} />}
                    </div>
                    <div className="col-xs-12 col-md-4">
                        {!isEmpty(this.state.keyWords) && <ul className="list-group">
                            {this.state.keyWords.map((item, index) =>
                                <li key={index} className="list-group-item"><div dangerouslySetInnerHTML={createMarkup(item)} /></li>
                            )}
                        </ul>}
                    </div>
                </Row>
                {!isEmpty(prices) && <PriceDisplay propertyID={property.Id} prices={prices} />}
                </>}
                {(error === true || (!loading && (typeof property.Id === 'undefined' || property.Id === 0))) && <PropertyInfoError />}
            </Modal>
        )
    }
}
export default InfoModal;