import React from 'react';
import styled from 'styled-components';
import { Card, Row } from '../Theme';

/** returns the skeleton line */
function PropertySkeletion() {
    let lines = [];
    for(let i = 0; i < 4; i++) {
        lines.push(<div className="col-sm-6" key={i}>
        <Card>
            <Skeleton>
                <Avatar />
                <Line>
                    <Title></Title>                    
                </Line>
                <Icon></Icon>
            </Skeleton>
        </Card>
    </div>)
    }
    return <Row>
        {lines}
    </Row>
}
export default PropertySkeletion;
const Skeleton = styled.div`width: 100%;
    height: 115px;
    position: relative;
    @keyframes shine-lines {
        0% {
          background-position: -100px;
        }
        40%, 100% {
          background-position: 140px;
        }
    }
    @keyframes shine-avatar {
        0% {
          background-position: -32px;
        }
        40%, 100% {
          background-position: 208px;
        }
    }`;

const Avatar = styled.div`float: left;
    width: 25%;
    height: 100px;
    background-color: #ccc;
    margin: 8px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-avatar 1.6s infinite linear;`

const Line = styled.div`float: left;
    width: 60%;`

const Title = styled.div`height: 16px;
    margin-top: 12px;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines 1.6s infinite linear;`

const Icon = styled.div`position: absolute;
    right: 0;bottom: 0;
    width: 20%;
    height: 40px;
    background-color: #ccc;
    margin: 8px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-avatar 1.6s infinite linear;`