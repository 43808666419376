import React, {Component} from 'react';
import { isEmpty } from '../../helpers';
import { connect } from 'react-redux';
import PropertyModalContent from './PropertyModalContent';

class PriceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: true,
            timer: 7,
        }
        this.timeOut = '';
    }

    /** shows the modal with timer */
    componentDidMount() {
        if(!isEmpty(this.props.property)) {
            this.clickOut(this.props.property);
        } else {
            this.setState({modalOpen: false, timer: 7});
        }
    }

    /** handles the clickot event */
    clickOut = (property) => {
        var { Url, ProviderKey } = property;
        if(typeof Url !== undefined && Url !== '' && Url !== null) {
            this.trackEvent(ProviderKey, Url);

            var linkShort = Url;
            if (linkShort.length > 50) {
                var tmp = linkShort.substring(0, 25) + "/...../" + linkShort.substring(linkShort.length - 25);
                linkShort = tmp;
            }
            property.linkShort = linkShort;
            this.setState({property, modalOpen: true});
            this.initTimer();
        } else {
            property.linkShort = '';
            this.setState({property, modalOpen: true});
        }        
    }

    /** initializes the timer */
    initTimer = () => {
        let { timer } = this.state;
        if(timer > 0) {
            this.setState({timer: timer - 1})
            this.timeOut = setTimeout(this.initTimer, 1000);
        } else if(timer === 0) {
            var { Url } = this.state.property;
            this.setState({modalOpen: false, property: {}, timer: 7});
            window.location.href = Url;
        }
    }

    /** tracks the event in google tag manager */
    trackEvent = (provider, url) => {
        if(window.dataLayer) {
            window.dataLayer.push('event', 'click-out', {
                'event_category': 'link',
                'event_label': provider,
                'value': url
          });
        }
    }

    /** settings to be done before closing the modal */
    clickOutClose = () => {
        clearTimeout(this.timeOut);
        this.setState({modalOpen: false, timer: 7});
        this.props.emptyModal();
    }

    /** closes the modal */
    closeModal = () => {
        clearTimeout(this.timeOut);
        this.setState({modalOpen: false, property: {}, timer: 7});
        this.props.emptyModal();
    }

    render() {
        return (
            <PropertyModalContent {...this.state} clickOutClose={this.clickOutClose.bind(this)}
                closeModal={this.closeModal.bind(this)} noTimerMessage="" />
        )
    }
}

export default connect()(PriceModal);