import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
// import '../../styles/carousel-overide.css'
 
const PropertyCarousel = ({ images }) => {
    return (
        <Carousel>
            {images.map((image, i) => (<React.Fragment key={i}>
                {(!image.mediaType || image.mediaType === 'image') && <div>
                    <img src={image.original} alt="" />
                </div>}
                {image.mediaType === 'video' && 
                <YoutubeSlide key="youtube-1" url={image.original} />}
            </React.Fragment>))}
        </Carousel>
    );
}
const YoutubeSlide = ({ url, isSelected }) => {
    return <ReactPlayer width="100%" url={url} playing={isSelected} />
};
export default PropertyCarousel