import React, { useState, useEffect } from 'react';
import { PostLoading, propertyInfo } from '../../helpers';
import { Row, Fluid, Container, Meta } from '../../Theme';
import PropertyInfoError from '../Components/PropertyInfoError';
import HeaderImage from './HeaderImage'
import TabContainer from './TabContainer';

const PagePassword = React.lazy(() => import('../Components/PagePassword'));

function PropertyInfo(props) {

    var [ state, setTheState ] = useState({
        property: null,
        keyWords: [],
        images: [],
        error: false,
        modalProperty: {},
        prices: {},
        loading: true,
        protectedPage: false,
        verifyEntity: '',
        headerImage: ''
    });
    function setState(state) {
        setTheState(prev => ({ ...prev, ...state }))
    }

    useEffect(() => fetchProperty(), [])

    function fetchProperty() {
        let { country, destination, resort, area, name, key } = props.match.params;
        let slug = `/${country}/${destination}/${resort}/${area}/${name}/${key}`;
        setState({ loading: true });
        let updateAnalytics = true;
        propertyInfo(slug, false, updateAnalytics)
            .then(info => {
				var headerImage = ''
           
				if(info.images) {
                  
					let i = info.images.filter(i => !i.mediaType || i.mediaType===null || i.mediaType === 'image')
                  
					headerImage = i[0]? i[0].original.replace(' ','%20'): ''
                    if(!headerImage){
                        headerImage = i[0]?i[0].Image:'';
                    }
				}
				setState({...info, headerImage, loading: false})
                
			})
            .catch(() => setState({error: true, loading: false}))
    }

    function passwordVerified() {
        setState({ protectedPage: false });
        fetchProperty();
    }

    const { property, error, loading, protectedPage, verifyEntity, images, headerImage } = state;

    function noProperty() {        
        return ((property == null || property.ProviderKey === null) && !loading && !protectedPage && !error)
    }
    
    return (
        <Fluid className="page-wrapper">
            <Container>
                {protectedPage && <PagePassword verifyEntity={verifyEntity} 
                    passwordVerified={passwordVerified.bind(this)} />}
                {(property != null && property.ProviderKey != null) && <>
                    <Meta title={`${property.Name} i ${property.Resort}`}
                        fbTitle={`${property.Name} i ${property.Resort}`}
                        imageUrl={headerImage} />
                    <HeaderImage property={property} headerImage={headerImage} images={images} />
                    <TabContainer {...state} {...props} />
                </>}
                {loading && <Loading />}
                {noProperty() && <h3>No records found.</h3>}
                {error && <Row>
                    <div className="col-xs-12">
                        <PropertyInfoError />
                    </div>
                </Row>}
            </Container>
        </Fluid>
    );
}
const Loading = () => (
    <div className="container">
        <div className="row">            
            <div className="col-xs-12 text-center">
                <h3>Håll i dig, vi laddar önskat innehåll!</h3>
                <PostLoading />
            </div>
        </div>
    </div>

)

export default PropertyInfo;