import React, { Component } from 'react';
import { connect} from 'react-redux';
import { I18n } from 'aws-amplify';
import { ConfirmSignIn, RequireNewPassword, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import ConfirmRegister from '../Auth/ConfirmRegister';
import CustomForgotPassword from '../Auth/CustomForgotPassword';
import EbookUser from '../api/EbookUser';
import { fetchUserSuccess } from "../actions/userActions.js";
import { isEmpty } from '../helpers';

const dict = {
    'sv': {
        'Sign In': "Logga in",
        'Sign Out': "Logga ut",
        'Hello': "Hej",
        'or': "eller",
        'Username': "Användarnamn",
        'Password': "Lösenord",
        'Reset password': "Begär nytt lösenord",
        'Forget your password? ': "Glömt lösenord? ",
        'No account? ': "Ny användare? ",
        'Enter your username': "Ange ditt användarnamn",
        'Enter your password': "Ange ditt lösenord",
        'Sign in to your account': "Logga in på ditt konto",
        'Sign In with Facebook': "Logga in med Facebook",
        'Reset your password': "Begär nytt lösenord  (en kod skickas till den registrerade e-post adressen)",
        'Back to Sign In': "Tillbaka till inloggningen",
        'Send Code': "Begär lösenordskod",
        'Create a new account': "Skapa nytt konto",
        'Email': "E-post",
        'Phone Number': "Telefon",
        'Create account': "Skapa konto",
        'Create Account': "Skapa konto",
        'Have an account? ': "Har du redan ett konto? ",
        'Sign in': 'Logga in',
        'Code': 'Kod',
        'New Password': 'Ange nytt lösenord',
        'Resend Code': 'Skicka ny kod',
        'Submit': 'Spara',
        'Username/client id combination not found.': 'Felaktigt användarnamn.',
        'Invalid password format': 'Ogiltigt lösnord (minst 8 tecken)',
        'Invalid verification code provided, please try again.': 'Ogiltig kod, vänligen försök igen.',
        'Attempt limit exceeded, please try after some time.': 'Du har försökt för många gånger. Vänligen försök igenom en liten stund.',
        'The following fields need to be filled out: Username, Password, Email, Phone Number': 'Vänligen kontrollera följande fält: Användarnamn, Lösenord, E-post och Telefonnummer',
        'The following fields need to be filled out: Username, Password, Email': 'Vänligen kontrollera följande fält: Användarnamn, Lösenord och E-post',
        'The following fields need to be filled out: Password, Email, Phone Number': 'Vänligen kontrollera följande fält: Lösenord, E-post och Telefonnummer',
        'The following fields need to be filled out: Email, Phone Number': 'Vänligen kontrollera följande fält: E-post och Telefonnummer',
        'The following fields need to be filled out: Email': 'Vänligen kontrollera följande fält: E-post',
        'The following fields need to be filled out: Password': 'Vänligen kontrollera följande fält: Lösenord',
        'The following fields need to be filled out: Phone Number': 'Vänligen kontrollera följande fält: Telefonnummer',
        'The following fields need to be filled out: Username, Email, Phone Number': 'Vänligen kontrollera följande fält: Användarnamn, E-post och Telefonnummer',
        'The following fields need to be filled out: Username, Password, Phone Number': 'Vänligen kontrollera följande fält: Användarnamn, Lösenord och Telefonnummer',
        'The following fields need to be filled out: Username, Phone Number': 'Vänligen kontrollera följande fält: Användarnamn och Telefonnummer',
        'The following fields need to be filled out: Username': 'Vänligen kontrollera följande fält: Användarnamn',
        'The following fields need to be filled out: Password, Phone Number': 'Vänligen kontrollera följande fält: Lösenord och Telefonnummer',
        'The following fields need to be filled out: Password, Email': 'Vänligen kontrollera följande fält: Lösenord och E-post',
        'Confirm Sign Up': 'Bekräfta ditt konto',
        'Confirmation Code': 'Bekräftelsekod (skickat till din e-post)',
        'Enter your code': 'Ange kod',
        'Lost your code? ': 'Behöver du en ny kod? ',
        'Confirm': 'Bekräfta',
        'null failed with error Generate callenges lambda cannot be called..': 'Vänligen kontrollera dina uppgifter.',
        'Username cannot be empty': 'Vänligen ange användarnamn',
        'Incorrect username or password': 'Ogiltigt användarnamn eller lösenord',
        'User does not exist': 'Ogiltigt användarnamn eller lösenord'

    }
};

I18n.putVocabularies(dict);
I18n.setLanguage('sv');

class AmplifyAuth extends Component {
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if(isEmpty(this.props.authUser)) {
            let u = new EbookUser();
            u.getUserBySkiPortal()
                .then(data => {
                    if(data.success) {
                        this.props.dispatch(fetchUserSuccess(data.user))
                    }
                })
        }
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    render() {
        return (this.props.children)
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.authUser.user
    }
}
export default connect(mapStateToProps)(withAuthenticator(AmplifyAuth, false, [
    <Login />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <Register />,
    <ConfirmRegister />,
    <CustomForgotPassword />,
    <RequireNewPassword />
]));