import api from "../config/api";
import Ajax from './Ajax';

const CacheData = {
    getCacheData
};
/**
 * calls the cache API
 */
function getCacheData(cacheKey, cacheArgs) {
    var requestData = {
        CacheType: cacheKey,
        Args: cacheArgs
    };
    return Ajax.call(api.cacheService, requestData);
}
export default CacheData;
