import React from 'react';
import { isEmpty } from '../helpers';
import {Link} from 'react-router-dom';

class Menubar extends React.Component {

    signOut = () => {
        this.props.signOut();
    }
    
    render() {
    var { partner } = this.props;
    return (<>
        <nav className="main_menu" role="navigation">
            <div className="avia-menu av-main-nav-wrap">
                <ul id="avia-menu" className="menu av-main-nav">
					<li className="menu-item">
                        <Link to='/stugagare-skinova'>
                            <i className="ski-icon-stugagare_ikon menu-icon"></i>
                            <span className="avia-menu-text">Stugförmedling</span>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <Link to='/resorts-map'>
                            <i className="ski-icon-skinova_ikon menu-icon"></i>
                            <span className="avia-menu-text">Lågpriskarta</span>
                        </Link>
                    </li>  
                    <li className="menu-item">
                        <Link to='/get-my-booking'>
                            <i className="ski-icon-partner_ikon menu-icon"></i>
                            <span className="avia-menu-text">Befintlig bokning</span>
                        </Link>
                    </li>             
					<li className="menu-item">
                        <Link to='/kontakt'>
                            <i className="ski-icon-kontakt_ikon menu-icon"></i>
                            <span className="avia-menu-text">Kontakt</span>
                        </Link>
                    </li>   
                  


                    <li className="menu-item">
                     
                        <Link to='/owner/login'>                      
                            <span className="avia-menu-text" style={{border: '2px solid #00d1ff',padding: '10px',borderRadius: '30px'}}>Login stugägare</span>
                        </Link>
                    </li>    
					{/* <li className="menu-item sub-menu" aria-haspopup="true">
                        <span>
                            <i className="ski-icon-user-o menu-icon"></i>
                            {userRole === 'visitor' && <span className="avia-menu-text">Login</span>}
                            {userRole === 'user' && <span className="avia-menu-text">Mitt konto</span>}
                        </span>
                        <ul className="dropdown" aria-label="submenu">
                            {userRole === 'visitor' && <><li className="no-hover"><Link to="/login">Logga in</Link></li>                            
                            <li><Link to="register">Skapa konto</Link></li></>}

                            {userRole === 'user' && <><li><Link to="/user" className="sub-menu-item">Mitt konto</Link></li>                            
                            <li><span onClick={this.signOut} className="sub-menu-item">Logga ut</span></li></>}
                        </ul>
                    </li> */}
                </ul>
            </div>
            {!isEmpty(partner) && <div className="partner-logo">
				I samarbete med: 
				<a href={partner.WebsiteUrl} title={partner.Name} rel="noopener noreferrer" target="_blank">
                	<img src={partner.LogoUrl} alt={partner.Name} />
				</a>
            </div>}
        </nav>        
    </>)
    }
}

export default Menubar;