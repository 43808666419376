import React, { Component } from 'react';
import { Loading, loginState } from './helpers';
import Amplify from 'aws-amplify';
import aws_exports from "./aws-exports";
import { connect } from 'react-redux';
import userAPI from './api/AuthDispatch';
import LayoutLoader from './Layout/LayoutLoader.js';
import CookieConsent from "react-cookie-consent";

Amplify.configure({
    Auth: aws_exports,
    Analytics:{
        disabled:true
    }
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userType: '',
            signIn: true,
        };
    }

    componentDidMount() {
        loginState().then(userType => {
            switch(userType) {
                case 'user':
                    userType = 'user';
                    break;
                case 'admin':
                    this.props.dispatch(userAPI.setAdminDetail())
                    break;
                case 'provider':
                    this.props.dispatch(userAPI.setProviderDetail())
                    break;
                default:
                    userType = 'visitor';
                    break;
            }
            this.props.dispatch(userAPI.setRole(userType));
            this.setState({ userType });
        })
    }

    render() {
        let { userType, signIn } = this.state;
        if(userType !== '' && signIn) {
            return <><LayoutLoader userType={userType} />
                <CookieConsent
                    location="bottom"
                    buttonText="Jag förstår"
                    cookieName="cookieconsent"
                    style={{ background: "#0352e0" }}
                    buttonStyle={{ color: "#0352e0", background: "#fff", fontWeight:'bold',fontSize: "13px" }}
                    expires={8}>
                    För att förbättra användarupplevelsen så använder Skinova.se 
                    <a target="_blank" rel="noopener noreferrer" href="http://pages.skinova.se/privacy-policy/"> cookies</a>. 
                    Genom att fortsätta på sidan accepterar du användandet av 
                    <a target="_blank" rel="noopener noreferrer" href="http://pages.skinova.se/privacy-policy/"> cookies</a>.{" "}
                </CookieConsent>
            </>
        }
        return <Loading />;
    }
}

export default connect()(App);