import React from 'react';

/** renders the multi campaign on the home page */
function ImageList(props) {
    return (
        <div className="container-fluid">
			<style>
				{`@media (min-width:767px){
					.image-list-${props.primary['id']} 
						{
							width:${props.primary['image-width']==='' ? '100%' : props.primary['image-width']};
							margin-top:40px;
						}
					}
				`}
				
				{`@media (max-width:767px){
					.image-list-${props.primary['id']} 
					{
						width:${props.primary['image-width-mobile']==='' ? '100%' : props.primary['image-width-mobile']};
						margin-top:40px;
					}
				}
				`}
			</style>
            <div className="container-center">        
                {props.sliceItems.map((item, i) => (
                    <div className={`container-inner-center image-list-${props.primary['id']}`} key={i}>
	                    <a href={item.link.url} target="_blank" rel="noopener noreferrer">								
							<img src={item.image.url} alt={item.image.alt} style={{padding:'0px ' +props.primary['padding-horizontal']}} />
						</a>						
                    </div>
                ))}                          
            </div>
        </div>
    );
}
export default ImageList;