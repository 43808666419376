// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:8ec60444-9890-48ac-9a77-07af9fde41de",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jNDCUcxqZ",
    "aws_user_pools_web_client_id": "6aso35bvpfetpjasqsjl98p55o",
    "aws_content_delivery_bucket": "auth.skinova.se",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://auth.skinova.se.s3-website.eu-central-1.amazonaws.com"
};
export default awsmobile;