import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

export default function Meta(props) {

    var [ state, setTheState ] = useState({
        title: '',
        description: ''
    })
    function setState(state) {
        setTheState(prev => ({ ...prev, ...state }))
    }
    useEffect(() => {
        if(props.title && (props.title !== '' || props.title != null)) {
            setState({ title: props.title })
        }
    }, [props.title])

    useEffect(() => {
        if(props.description && (props.description !== '' || props.description != null)) {
            setState({ description: props.description })
        }
    }, [props.description])

    let { title, description } = state;
    let { fbTitle, imageUrl } = props;
    return (
        <Helmet>
            <title>{title? title: 'Skinova - sökmotor för boende i fjällen'}</title>
            {description? <meta name="description" content={description} />:
                <meta name="description" content="Hitta och jämför boende i fjällen och din skidresa." />}
            {fbTitle && <meta property="og:title" content={fbTitle} />}
            {imageUrl && <meta property="og:image" content={imageUrl} />}
        </Helmet>
    )
}