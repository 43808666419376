import React from 'react';
import { Route } from 'react-router-dom';
import {AppLayout, AmplifyBar, AmplifyAuth} from '../Layout';

const UserRoute = ({ component: Component, search, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <AppLayout search={search} location={matchProps.location.search}>
                    <AmplifyAuth>
                        <AmplifyBar location={window.location.hash} />
                        <Component {...matchProps} />
                    </AmplifyAuth>
                </AppLayout>
            )}
        />
    );
};

export default UserRoute;