export default {
    'SOMETHING_WENT_WRONG': "Ojdå, något gick fel. Vänligen ladda om sidan och försök igen",
    "PROPERTY_EXISTS": "Enheten finns redan.",
    "PROPERTY_DELETED": "Enheten är nu borttagen.",
    "PROPERTY_UPDATED": "Enheten är nu sparad.",
    "WEEK_DELETED": "Vald vecka är nu borttagen.",
    "PRICE_UPDATED": "Priset har nu uppdaterats.",
    "PROPERTY_OWNER_EXISTS": "Den här enheten är redan sammankopplad med en annan användare. Är du säker på att du valt rätt enhet? I så fall, vänligen kontakta info@skinova.se och meddela oss snarast. Om inte, vänligen försök igen med en annan enhet.",
    "PROPERTY_ALREADY_ON_USER": "Den här egenskapen har redan lagts till av dig.",
    "PROPERTY_ALREADY_CLONED": "Denna enhet är redan inlagd.",
    "PROPERTY_CLONED": "Enheten är nu tillagd.",
    "USER_UPDATED": 'Användaren är nu uppdaterad.',
    "BOOKING_CREATED": 'Bokningen har skapats.',
    "BOOKING_ALREADY_DONE": "Bokningen för detta datum är redan klar.",
    "BOOKING_DELETED": "Bokningen har tagits bort.",
    "PAYMENT_CREATED": "Betalning har skapats.",
    "PAYMENT_DELETED": "Betalningen är nu raderad.",
    "INVALID_CAPTCHA": "Kontrollera captcha.",
    "STATUS_CHANGED": "Bokningsstatusen är nu ändrad.",
    "MESSAGE_SENT": "Ditt meddelande är skickat.",
    "SALE_UPDATED": "Du har nu nu uppdaterat informationen!",
    "ADDRESS_UPDATED": "Du har nu nu uppdaterat informationen!",
    "PRICES_DELETED": "Pristlistan är nu borttagen.",
    "LINK_SENT": "Ett mail med betalningsinstruktion har nu blivit skickad till kunden!",
    "PRICE_CHANGED": "Priset är nu uppdaterat.",
    "PRICE_PAID": "Denna bokning är redan betald. Är du säker på att du vill ändra priset?",
    "INVALID_BOOKING": "This booking does not exists.",
    "PAGE_PASSWORD_UPDATED": "Lösenordet har ändrats",
    "INVALID_PAGE_PASSWORD": "Lösenordet är fel",
    "BOOKING_EMAIL_RESENT": "E-post har skickats",
    "PAGE_PASSWORD_REMOVED": "Lösenordet har tagits bort",
    "BOOKING_COMMENT_CREATED": "Kommentaren är nu sparad.",
    "BOOKING_COMMENT_DELETED": "Kommentaren har tagits bort.",
    "NO_USER": "Denna e-postadress saknas.",
    "DISCOUNT_ADDED": "Rabatten har nu tillämpats.",
    "DISCOUNT_REMOVED": "Rabatten är nu borttagen.",
    "DISCOUNT_CODE_MATCHED": "Rabattkod tillämpad.",
    "DISCOUNT_CODE_NOT_FOUND": "Den här koden är inte giltig.",
    "GUEST_UPDATED": "Gästinformationen har uppdaterats.",
    "ADMIN_NOT_ALLOWED_EDIT": "Administratörsanvändare kan inte redigera den här informationen.",
    "NOT_YOUR_PROPERTY": "Det här objektet tillhör inte dig.",
    "NO_BOOKING": "Den här bokningen finns inte.",
    "VALIDATION_FAILED": "Felaktig data som skickas till servern.",
    "BOOKING_CUSTOMER_UPDATED": "Kundinformation har uppdaterats.",
    "PAYMENT_TERM_UPDATED": "Ändringen sparades.",
    "BOOKING_DETAILS_UPDATED": "Ändringen sparades.",
    "LABELS_SAVED": "Ändringen sparades",
    "SEQUENCE_UPDATED": "Sekvensen har uppdaterats.",
    "CODE_DEPLOYED": "Distribution har lagts till i kön.",
    "GROUP_DESCRIPTION_UPDATED": "Gruppinformation uppdateras.",
    "POOL_UPDATED": 'Ad Pool har uppdaterats.',
    "POOL_DELETED": 'Annonspool har tagits bort.',
    "AD_UPDATED": 'Annonsen har uppdaterats i databasen.',
    "AD_DELETED": 'Annonsen har tagits bort från databasen.'
}