/** defines the price format for Sweden */
export default function priceFormat(n, decPlaces = 0) {
    if(n === '') {
        return '';
    }
    let decSeparator = ',',
    thouSeparator = '.',
    sign = n < 0 ? "-" : "";
    let i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "";
    let j;
    j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
}