import React from 'react';
import NewPropertyDisplay from './NewPropertyDisplay';
import '../../styles/new-property-card.css';

function NewList(props) {
    let { properties, prependLink } = props;

    return (
        <div className="container">
            <div className="search-accommodation-list">
                <div className="row">
                    {properties.map((item, i) => (
                        <NewPropertyDisplay prependLink={prependLink} property={item} key={i} />
                    ))}
                </div>
            </div>
        </div>
    )
}
export default NewList;