import React, {Component} from 'react';
import { isEmpty } from '../../helpers';
import { connect } from 'react-redux';
import PropertyModalContent from '../Components/PropertyModalContent';

class PropertyModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: true,
            timer: 7,
            noTimerMessage: ''
        }
        this.timeOut = '';
    }

    /** shows the modal with timer */
    componentDidMount() {
        let { property } = this.props;
        if(!isEmpty(property)) {
            if(true || (property.IsPayingPartner && property.IsPayingPartner === 1)) {
                this.clickOut(property);
            } else {
                this.setState({ property, noTimerMessage: 'Skinova erbjuder hela fjällvärldens utbud men då denna bokningsleverantör ännu inte anslutit sig så saknas direktlänk.' });
            }
        } else {
            this.setState({modalOpen: false, timer: 7, noTimerMessage: ''});
        }
    }

    /** handles the clickot event */
    clickOut = (property) => {
        var { Url, ProviderKey } = property;
        if(typeof Url !== undefined && Url !== '' && Url !== null) {
            this.trackEvent(ProviderKey, Url);

            var linkShort = Url;
            if (linkShort.length > 50) {
                var tmp = linkShort.substring(0, 25) + "/...../" + linkShort.substring(linkShort.length - 25);
                linkShort = tmp;
            }
            property.linkShort = linkShort;
            this.setState({property, modalOpen: true, noTimerMessage: ''});
            this.initTimer();
        } else {
            property.linkShort = '';
            this.setState({property, modalOpen: true, noTimerMessage: ''});
        }        
    }

    /** initializes the timer */
    initTimer = () => {
        let { timer } = this.state;
        if(timer > 0) {
            this.setState({timer: timer - 1})
            this.timeOut = setTimeout(this.initTimer, 1000);
        } else if(timer === 0) {
            var { Url } = this.state.property;
            this.setState({modalOpen: false, property: {}, timer: 7});
            this.dropCookie();
            window.location.href = Url;
        }
    }

    /** tracks the event in google tag manager */
    trackEvent = (provider, url) => {
        if(window.dataLayer) {
            window.dataLayer.push('event', 'click-out', {
                'event_category': 'link',
                'event_label': provider,
                'value': url
          });
			
		  if(window.gtag)
		  {
			window.gtag('event', 'conversion', {
		      'send_to': 'AW-775357102/LvpXCOzlu94BEK6F3PEC',
		      'event_callback': null
		  	});		
		  }
        }
    }

    /** settings to be done before closing the modal */
    clickOutClose = () => {
        clearTimeout(this.timeOut);
        this.setState({modalOpen: false, timer: 7});
        this.dropCookie();
        this.props.emptyModal();
    }

    /** closes the modal */
    closeModal = () => {
        clearTimeout(this.timeOut);
        this.setState({modalOpen: false, property: {}, timer: 7});
        this.props.emptyModal();
    }

    /** drops the cookie before the redirection */
    dropCookie = () => {
        let date = new Date();
        date.setDate(date.getDate() + 3);
        var c = ["providerBeforeRedirect=1", `expires=${date.toUTCString()}`, `path=/`];
        document.cookie = c.join(";");
    }

    render() {
        return (
            <PropertyModalContent {...this.state} clickOutClose={this.clickOutClose.bind(this)}
                closeModal={this.closeModal.bind(this)} />
        )
    }
}

export default connect()(PropertyModal);