import React, {Component} from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../styles/burger.css';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

class BurgerMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    signOut = () => {
        this.closeMenu();
        this.props.signOut();
    }

    handleStateChange = state => this.setState({menuOpen: state.isOpen})

    closeMenu = () => this.setState({menuOpen: false})

    render() {
        let { menuOpen } = this.state;
        return <React.Fragment>
            {this.props.menu && <div className="burger-menu right"> 
                <Menu right={true} isOpen={menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
                    
                    {/* <Link onClick={this.closeMenu} className="menu-item" to='/partner/home'>Bokningsleverantör/Partner</Link> */}
					                
                                     
					<Link onClick={this.closeMenu} to="/stugagare-skinova" className="menu-item">Stugförmedling</Link>
                    <Link onClick={this.closeMenu} className="menu-item" to='/resorts-map'>Lågprissök</Link>
                    <Link onClick={this.closeMenu} className="menu-item" to='/get-my-booking'>Befintlig bokning</Link>     
                    <Link onClick={this.closeMenu} className="menu-item" to='/kontakt'>Kontakt</Link>    
                    <Link onClick={this.closeMenu} className="menu-item" to='/owner/login'>Login stugägare</Link> 
                    {/*
                    {userRole === 'visitor' && <><Link onClick={this.closeMenu} className="menu-item" to="/login" style={{display: 'block'}}>Logga in</Link>
                        
                        <Link onClick={this.closeMenu} to="register" className="menu-item" style={{display: 'block'}}>Inte medlem än? Bli medlem här!</Link></>}
                        /*}
                    {/* {userRole === 'user' && <><Link onClick={this.closeMenu} to="/user" className="menu-item" style={{display: 'block'}}>Mitt konto</Link>                        
                        <span onClick={this.signOut} className="menu-item" style={{display: 'block'}}>Logga ut</span></>} */}
                </Menu>
            </div>}
        </React.Fragment>
    }
}

const mapStateToProps = state => {
    return { menu: state.layout.menu };
}
export default connect(mapStateToProps)(BurgerMenu);