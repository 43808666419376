import {
    PARTNER_FETCH,
} from "../actions/partnerActions";

const initialState = {
    partner: {}
};

export default function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case PARTNER_FETCH:
            return {
                ...state,
                partner: action.payload.data,
            };
            
        default:
            return state;
    }
}