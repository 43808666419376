export const USER_FETCH_BEGIN = "USER_FETCH_BEGIN";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_FAILURE = "USER_FETCH_FAILURE";
export const SET_ADMIN = "SET_ADMIN";
export const SET_PROVIDER = "SET_PROVIDER";
export const PROPERTIES = "PROPERTIES";
export const BOOKINGS = "BOOKINGS";
export const USER_ROLE = 'USER_ROLE';
export const PURCHASE_COUNT = 'PURCHASE_COUNT';
export const PACKAGE_EXPIRED = 'PACKAGE_EXPIRED';
export const SCREEN_SERVICE = 'SCREEN_SERVICE';

/** returns action for user fetch begin */
export const fetchUserBegin = () => ({
    type: USER_FETCH_BEGIN
});

/** returns action for user fetch success */
export const fetchUserSuccess = user => ({
    type: USER_FETCH_SUCCESS,
    payload: { user }
});

/** returns action for setting the admin details */
export const setAdmin = user => ({
    type: SET_ADMIN,
    payload: { user }
});

/** returns action for setting the provider details */
export const setProvider = user => ({
    type: SET_PROVIDER,
    payload: { user }
});

/** returns action for user fetch failed */
export const fetchUserFailure = error => ({
    type: USER_FETCH_FAILURE,
    payload: { error }
});

/** returns the action for user has properties */
export const userProperties = properties => ({
    type: PROPERTIES,
    payload: properties
});

/** returns the action for user bookings */
export const userBookings = bookings => ({
    type: BOOKINGS,
    payload: bookings
});

/** returns the action for user bookings */
export const userPurchaseCount = purchaseCount => ({
    type: PURCHASE_COUNT,
    payload: purchaseCount
});

/** returns the action for user bookings */
export const userPackageExpired = packageExpired => ({
    type: PACKAGE_EXPIRED,
    payload: packageExpired
});

/** returns the action for user bookings */
export const setScreenService = screenService => ({
    type: SCREEN_SERVICE,
    payload: screenService
});

/** returns the action for user has properties */
export const setUserRole = role => ({
    type: USER_ROLE,
    payload: role
});