import React, { Component } from 'react';
import Ajax from '../../api/Ajax';
import api from '../../config/api';
import { isEmpty } from '../../helpers';
import { Row } from '../../Theme';
import AdCell from '../CMS/AdCell';

class AdDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: []
        }
    }

    componentDidMount() {
        this.fetchAds();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.destination !== this.props.destination) {
            this.fetchAds();
        }
    }

    fetchAds() {
        let { destination } = this.props;
        let params = {operation: 'getAdsToDisplay', noAuth: true, adCount: 10, adKey: 'search-result-right',destination};
        Ajax.call(api.adService, params)
            .then(data => {
                if(data.success) {
                    this.setState({ ads: data.ads });
                }
            }).catch(e => e);
    }

    printRows() {
        let { ads } = this.state;
        var html = ads.map((adRow, i) => {
            return (<Row key={i}>
                <div className={`col-xs-12`} key={i}>
                    <AdCell ad={adRow} />
                </div>
            </Row>);
        })
        return html;
    }

    render() {
        let { ads } = this.state;
        if(isEmpty(ads)) {
            return null;
        }
        return this.printRows();
    }
}
export default AdDisplay;