/** returns the date in a date format */
export const dateFormat = d => {
    d = new Date(d);
    return `${d.getFullYear()}-${monthFormat(d.getMonth())}-${zeroPrefix(d.getDate())}`;
}

/** returns the date in a date format */
export const dateTimeFormat = d => {
    d = new Date(d);
    return `${d.getFullYear()}-${monthFormat(d.getMonth())}-${zeroPrefix(d.getDate())} ${zeroPrefix(d.getHours())}:${zeroPrefix(d.getMinutes())}:${zeroPrefix(d.getSeconds())}`;
}

/** returns the month by adding a prefix of zero */
export const monthFormat = n => {
    n = n + 1;
    return n >= 10? n: `0${n}`;
}

/** returns the hour by adding a prefix of zero */
export const zeroPrefix = n => {
    return n >= 10? n: `0${n}`;
}

/** convert the date from timestamp */
export const convertTimestampDate = timestamp => {
    var date = new Date(timestamp*1000);
    var day = date.getDate();
    var month = monthFormat(date.getMonth());
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
}

/** gets the week number */
export const getWeekNumber = date => {
    date = new Date(date.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    var week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

export const dateSplit = d => {
    let dd = d.split('-')
    return new Date(parseInt(dd[0]), parseInt(dd[1]) - 1, parseInt(dd[2]));
}

export const expectedPayoutDate = departureDate => {
    var payoutDate = '';
    let dDate = dateSplit(departureDate);
    let m = new Date(dDate.getFullYear(), dDate.getMonth() + 1, 0);
    m.setDate(m.getDate() - 7);
    if(dDate.getTime() < m.getTime()) {
        payoutDate = new Date(dDate);
    } else {
        let month = dDate.getMonth();
        let a = (month === 0)? dDate.setDate(dDate.getDate() + 28): dDate.setMonth(month + 1);
        payoutDate = new Date(a);
    }
    let p = new Date(payoutDate.getFullYear(), payoutDate.getMonth() + 1, 0);
    return p.toString('MMM dd, yyyy');
}