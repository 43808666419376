function validate(...args) {

    let data = args[0];
    let rules = args[1];
    let isMessage = args[2]? args[2]: false;
    let messages = args[3]? args[3]: [];
    var errors = {};

    loopFields();

    return errors;

    function loopFields() {
        for (let key in rules) {
            validField(key, rules[key]);
        };
    }

    function validField(field, fieldRules) {
        for (let rule in fieldRules) {
            if(errors[field]  === undefined) {
                processRules(field, rule, fieldRules[rule]);
            }
        }
    }

    function processRules(field, rule, value) {
        switch(rule) {
            case 'required': required(field); break;
            case 'swedishNumber': swedishNumber(field); break;
            case 'email': email(field); break;
            case 'extension': extension(field, value); break;
            case 'number': number(field); break;
            case 'decimal': decimal(field); break;
            case 'url': url(field); break;
            case 'minlength': minlength(field, value); break;
            case 'maxlength': maxlength(field, value); break;
            case 'regex': regex(field, value); break;
            case 'maxvalue': maxvalue(field, value); break;
            default:
                break;
        }
    }

    function required(field) {
        if(empty(field)) {
            setError(field, 'required');
        } else if(typeof data[field] === 'boolean' && !data[field]) {
            setError(field, 'required');
        }
    }

    function swedishNumber(field) {
        if(empty(field)) {
            return true;
        }
        if(!isMatch(/^[0-9.,-]+$/g, data[field])) {
            setError(field, 'swedishNumber');
        }
    }

    function email(field) {
        if(empty(field)) {
            return true;
        }
        let pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if(!isMatch(pattern, data[field])) {
            setError(field, 'email');
        }
    }

    function extension(field, value) {
        if(empty(field)) {
            return true;
        }
        let _file = data[field].toLowerCase();
        if(!isMatch(`(.*?).(${value})$`, _file)) {
            setError(field, 'extension');
        }
    }

    function number(field) {
        if(!isMatch(/^[0-9]+$/g, data[field])) {
            setError(field, 'number');
        }
    }

    function decimal(field) {
        if(!isMatch(/^[0-9.]+$/g, data[field])) {
            setError(field, 'decimal');
        }
    }

    function url(field) {
        if(empty(field)) {
            return true;
        }
        var pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        if(!isMatch(pattern, data[field])) {
            setError(field, 'url');
        }
    }

    function minlength(field, value) {
        if(empty(field)) {
            return true;
        }
        if(data[field].length <= value) {
            setError(field, 'minlength');
        }
    }

    function regex(field, pattern) {
        if(empty(field)) {
            return true;
        }
        if(!isMatch(pattern, data[field])) {
            setError(field, 'regex');
        }
    }

    function maxlength(field, value) {
        if(empty(field)) {
            return true;
        }
        if(data[field].length >= value) {
            setError(field, 'maxlength');
        }
    }

    function maxvalue(field, value) {
        if(empty(field)) {
            return true;
        }
        if(parseFloat(data[field]) > value) {
            setError(field, 'maxvalue');
        }
    }

    function setError(field, type) {
        errors[field] = isMessage? messages[field][type]: true;
    }

    function empty(field) {
        return (typeof data[field] === 'undefined' || data[field] === '' || data[field] == null);
    }

    function isMatch(regex, value) {
        var pattern = new RegExp(regex);
        if (pattern.test(value)) {
            return true;
        }
        return false;
    }
}
export default validate;