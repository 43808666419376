import React from 'react';

function PropertyInfoError() {
    return <div className="row">
        <div className="col-xs-12 text-center">
            <h3>Oj! Nånting gick fel</h3>
            <p>Tyvärr kunde vi inte hitta objektet. Vänligen försök igen senare eller prova med en annan enhet!</p>
        </div>
    </div>
}
export default PropertyInfoError;