export const PROPERTY_FETCH_BEGIN = "PROPERTY_FETCH_BEGIN";
export const PROPERTY_FETCH_SUCCESS = "PROPERTY_FETCH_SUCCESS";
export const PROPERTY_FETCH_FAILURE = "PROPERTY_FETCH_FAILURE";
export const PROPERTY_DELETE = "PROPERTY_DELETE";
export const RESET_PROPERTY = "RESET_PROPERTY";
/**
 * returns action for reset properties
 */
export const resetProperties = () => ({
    type: RESET_PROPERTY
});
/**
 * returns action for properties fetch init
 */
export const fetchPropertiesBegin = () => ({
    type: PROPERTY_FETCH_BEGIN
});
/**
 * returns action for properties fetch success
 */
export const fetchPropertiesSuccess = properties => ({
    type: PROPERTY_FETCH_SUCCESS,
    payload: { properties }
});
/**
 * returns action for properties fetch failed
 */
export const fetchPropertiesFailure = error => ({
    type: PROPERTY_FETCH_FAILURE,
    payload: { error }
});
/**
 * returns action for properties delete
 */
export const deleteProperty = list => ({
    type: PROPERTY_DELETE,
    payload: { list }
});