/**
 * sets the default date criteria for search filter
 */
const datus = {
    defaultDate, showFormat, actualFormat
}
/**
 * gets the default date
 */
function defaultDate() {
    var dates = {};
    if (Date.today().getMonth() > 3) {
        dates.dateFrom = new Date.next().january().second().sunday();
        dates.dateTo = new Date.next().january().second().sunday().addDays(7);
    } else {
        dates.dateFrom = Date.today().next().sunday();
        dates.dateTo = Date.today().next().sunday().addDays(7);
    }
    return dates;
}
/**
 * converts date in show format
 */
function showFormat(date) {
    date = new Date(date);
    return date.toString("ddd dd/MM");
}
/**
 * converts date in actual format
 */
function actualFormat(date) {
    date = new Date(date);
    return date.toString("yyyy-MM-dd");
}
export default datus;