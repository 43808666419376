import api from '../config/api.js';
import Ajax from './Ajax';

class SkiPortalPackage {
    /**
     * get all packages
     */
    fetchAll() {
        var requestData = {
            operation: 'getPackages'
        };
        return Ajax.call(api.spPaymentService, requestData);
    }
    /**
     * gets the user packages
     */
    getUserPackage() {
        let requestData = {
            operation: 'getUserPackage'
        };
        return Ajax.call(api.spPaymentService, requestData);
    }
}
export default SkiPortalPackage;