import {
    USER_FETCH_BEGIN,
    USER_FETCH_SUCCESS,
    USER_FETCH_FAILURE,
    SET_ADMIN,
    SET_PROVIDER,
    PROPERTIES, BOOKINGS, USER_ROLE,
    PURCHASE_COUNT, PACKAGE_EXPIRED, SCREEN_SERVICE
} from "../actions/userActions";

const initialState = {
    user: {},
    authenticating: false,
    error: null,
    properties: [],
    bookings: [],
    purchaseCount: -1,
    packageInfo: -1,
    screenService: false,
    role: 'visitor',
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_FETCH_BEGIN:
            return {
                ...state,
                authenticating: true,
                error: null
            };

        case USER_FETCH_SUCCESS:
            return {
                ...state,
                authenticating: false,
                user: action.payload.user
            };
        
        case SET_ADMIN:
            return {
                ...state,
                authenticating: false,
                user: action.payload.user
            };

        case SET_PROVIDER:
            return {
                ...state,
                authenticating: false,
                user: action.payload.user
            };

        case USER_FETCH_FAILURE:
            return {
                ...state,
                authenticating: false,
                error: true
            };

        case PROPERTIES:
            return {
                ...state,
                properties: action.payload
            };

        case BOOKINGS:
            return {
                ...state,
                bookings: action.payload
            };

        case PURCHASE_COUNT:
            return {
                ...state,
                purchaseCount: action.payload
            };

        case PACKAGE_EXPIRED:
            return {
                ...state,
                packageExpired: action.payload
            };

        case SCREEN_SERVICE:
            return {
                ...state,
                screenService: action.payload
            };

        case USER_ROLE:
            return {
                ...state,
                role: action.payload
            };

        default:
            return state;
    }
}
