import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import { isEmpty, multiSort, Loading, isSearchHash } from '../helpers';
import Ajax from '../api/Ajax';
import api from '../config/api';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../styles/content.css';
import Content from './CMS/Content';
import LargeCampaign from './CMS/LargeCampaign';
import MultiCampaign from './CMS/MultiCampaign';
import FullWidthOverlay from './CMS/FullWidthOverlay';
import ButtonSection from './CMS/ButtonSection';
import SearchProperties from './CMS/SearchProperties';
import VerticalSpace from './CMS/VerticalSpace';
import Columns from './CMS/Columns';
import PropertyOwner from './CMS/PropertyOwner';
import ImageList from './CMS/ImageList';
import RandomProperties from './CMS/RandomProperties';
import PropertySearch from './SearchProperty/PropertySearch.js';
import AdDisplay from './CMS/AdDisplay';
import ImageLightbox from './CMS/ImageLightbox';
import AccordionType from './CMS/AccordionType';
import PropertyShow from './CMS/PropertyShow';
import $ from 'jquery';
import NoPage from './NoPage';
import domains from '../config/domains';

const sequence = {
    'content': Content,
    'columns': Columns,
    'search-properties': SearchProperties,
    'multi-campaign': MultiCampaign,
    'large-campaign': LargeCampaign,
    'random-properties': RandomProperties,
	'full-width-overlay': FullWidthOverlay,
	'button-section': ButtonSection,
	'vertical-space': VerticalSpace,
	'property-owner': PropertyOwner,
    'image-list' : ImageList,
    'ad-display': AdDisplay,
    'image-lightbox': ImageLightbox,
    'accordion': AccordionType,
    'property-display': PropertyShow
}

class DynamicContent extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            content: [],
            seoData: '',
            loading: false,
            hideForSearch: false,
            hideSearch: true,
            noPage: false,
            newDomain: false
        }
    }

    /** mounts the component */
    componentDidMount() {
        this._isMounted = true;
        this.checkDomain();
        this.renderContentByUid(this.getPageUid());
        this.hideForSearch();
    }

    /** hides the search section if the route changes */
    componentDidUpdate(prevProps) {
        if(prevProps.location.hash !== this.props.location.hash) {
            this.hideForSearch();
        }
        let {hideForSearch} = this.state;
        if(prevProps.location.pathname !== this.props.location.pathname) {
            if(window.screen.width < 900 && hideForSearch) {
                $("#dynamic-page-content").slideDown(function () {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $("#dynamic-page-content").offset().top
                    }, 2000);
                });
            }
            this.renderContentByUid(this.getPageUid());
        }
    }

    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    /** hides the search section */
    hideForSearch = () => {
        const { hideForSearch } = this.state;
        if(this.props.location.hash !== "" && isSearchHash(this.props.location.hash)) {
            this.setState({hideForSearch: true});
            return true;
        } else if(hideForSearch) {
            this.setState({hideForSearch: false});
        }
        return false;
    }

    /** renders the content by ID */
    renderContentByUid = (uid) => {
        this.setState({ loading: true, content: '' });
        Prismic.getApi("https://skinova.prismic.io/api/v2")
            .then(api => api.query(Prismic.Predicates.at('my.dynamic-content.uid', uid)))
            .then(response => {
				var hideSearch = response!=null && response.results!=null && response.results.length>0 && response.results[0].data!=null && response.results[0].data['hide-search']===true;
                this.setState({ hideSearch: hideSearch, noPage: false });
                if (response == null || isEmpty(response.results)) {
                    this.setState({ hideSearch: true, loading: false, noPage: true });
                    return;
                }
                this.renderContentDynamic(response, this.setContent)
            }).catch(() => this.setState({ hideSearch: true, loading: false }));
    }

    /** sets the content */
    setContent = content => {
        let sortedContent = multiSort(content, {key: 'asc'});
        this.setState({content: sortedContent, loading: false});
    }

    /** sets the page title and meta description for SEO */
    setSEO = (item) => {
        var seo = {};
        if (item["page-title"] && item["page-title"] !== "") {
            seo.title = item["page-title"];
        } else {
            seo.title = 'Skinova - hitta och jämför pris på boende i fjällen';
        }
        if (item["meta-description"] && item["meta-description"] !== "") {
            seo.desc = item["meta-description"];
        } else {
            seo.desc = 'Hitta och jämför boende i fjällen och din skidresa.';
        }
        this.setState({seoData: seo});
    };

    /** renders the content on the screen */
    async renderContentDynamic(data, callback) {
        var content = [];
        data.results.map(async (item, i) => {
            if (item.type === 'dynamic-content') {
                this.setSEO(item.data);
                var sliceLength = item.data.body.length;
                var cnt = 0;
                item.data.body.map(async (slice, j) => {
                    var TemplateHtml = sequence[slice.slice_type];
                    var prop = {};
                    if (!isEmpty(slice.primary)) {
                        prop.primary = slice.primary;
                        if ((!slice.items || isEmpty(slice.items[0])) && 
                            (slice.slice_type !== "search-properties" && slice.slice_type !== "random-properties")) {
                            content.push(<TemplateHtml {...prop} key={j} />)
                        }
                    }
                    if (slice.slice_type === "search-properties") {
                        let promise = this.getSearchProperties();
                        let result = await promise;
                        content.push(<TemplateHtml {...prop} searchData={result} key={j} />)
                    } else if (slice.slice_type === "random-properties") {
                        let promise = this.getRandomProperties();
                        let result = await promise;
                        content.push(<TemplateHtml {...prop} data={result} key={j} />)
                    } else if (slice.slice_type === "property-display") {
                        let promise = this.getPropertiesShow(slice.items);
                        let result = await promise;
                        content.push(<TemplateHtml {...prop} data={result} key={j} />)
                    } else if (slice.items && slice.items.length > 0 && !isEmpty(slice.items[0])) {
                        prop.sliceItems = slice.items;
                        content.push(<TemplateHtml {...prop} key={j} />)
                    }
                    ++cnt;
                    if(cnt === sliceLength) {
                        callback(content);
                    }
                    return content;
                });
            }
            return content;
        });
    }

    /** gets the search properties */
    getSearchProperties() {
        var params = { CacheType: "getSearchCache" };
        return Ajax.call(api.cacheService, params).catch(e=>e);
    }

    /** gets the cached properties */
    getRandomProperties() {
        let params = { operation: "randomEbookProperties" };
        return Ajax.call(api.ebspPropService, params).catch(e=>e);
    }

    getPropertiesShow(items) {
        let keys = items.map(i => i.property_key);
        let params = { operation: "propertiesByKeys", keys, noAuth: true };
        return Ajax.call(api.ebspPropService, params).catch(e=>e);
    }

    /** gets the page by its ID */
    getPageUid = () => {
        var uid = "";
        var { location } = window;
        var pathName = location.pathname;
        if (pathName && pathName !== "") {
            pathName = pathName.replace(".html", "");
            uid = pathName.replace("/", "");
        }    
        if (!uid || uid === "")
            uid = this.getParameterByName("uid");
    
        if (!uid || uid === "" || uid === "index")
            uid = "homev2";
    
        return uid;
    }

    /** gets the parameters by the name */
    getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    checkDomain() {
        let { location } = window;
        let { href } = location;
        let domain = domains.filter(d => d.domain === href);
        if(domain.length > 0) {
            location.href = `${href}ebook/${domain[0].user}`;
        }
    }

    render() {
        const { hideForSearch, seoData, content, loading, hideSearch, newDomain } = this.state;
        if(newDomain) {return null}
        return (<>
            {!isEmpty(seoData) && <HelmetProvider>
                <Helmet>
                    {seoData.title && <title>{seoData.title}</title>}
                    {seoData.desc &&  <meta name="description" content={seoData.desc} />}
                </Helmet>
            </HelmetProvider>}
            {!hideSearch && <PropertySearch />}
            {!hideForSearch && <>
                {loading && <div style={{minHeight: '300px'}}><Loading /></div>}
                {!isEmpty(content) && <div className="hide-for-search" id="dynamic-page-content">
                    {content}
                </div>}
                {(isEmpty(content) && !loading) && <NoPage />}
            </>}
        </>);
    }
}

export default DynamicContent;