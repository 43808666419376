import { combineReducers } from "redux";
import property from "./propertyReducer";
import authUser from './userReducer';
import layout from './layoutReducer';
import searchProperty from './searchReducer';
import partner from './partnerReducer';
import alert from './alertReducer';
import bookingComment from './bookingCommentReducer';

export default combineReducers({
    property, authUser, layout, searchProperty, partner, alert, bookingComment
});
