import api from "../config/api.js";
import Ajax from "./Ajax";

const SkiPortalUser = {
    getUserPayments,
    userHasProperties,
    abortXHR
};
/**
 * gets the user payments
 */
function getUserPayments(offset, limit) {
    var requestData = {
        operation: "getUserPayments",
        offset: offset,
        limit: limit
    };
    return Ajax.call(api.spPaymentService, requestData);
}

/**
 * gets the user payments
 */
function userHasProperties() {
    var requestData = {
        operation: "userHasProperties"
    };
    return Ajax.call(api.eBookUserService, requestData);
}
/**
 * aborts the xhr
 */
function abortXHR() {
    Ajax.abort();
}
export default SkiPortalUser;