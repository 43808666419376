import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
const Toastify = props => {
 
    return <ToastContainer
        position="top-center"
        autoClose={15000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={true}
        draggable
        pauseOnHover
        transition={Zoom}
        />
}
export default Toastify;