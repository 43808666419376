import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, FormRow, Row, Fluid, Card } from '../Theme';
import { ProviderService } from "../api";
import { loginState, Loading } from "../helpers";
import { connect } from 'react-redux';
import { LoadingButton } from '../Components';
import { AuthDispatch } from '../api';
import RespTrans from '../config/server-response';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            submitted: false,
            errors: {},
            redirectTo: false,
            postLoading: false,
            postMessage: ''
        };
    }

    componentDidMount() {
        loginState().then((login) => {
            if(login === 'user') {
                this.setState({redirectTo: '/user'});
            } else if(login === 'provider') {
                this.setState({redirectTo: '/provider/dashboard'});
            } else if(login === 'admin') {
                this.setState({redirectTo: '/admin/dashboard'});
            }
        })
    }

    /**
     * handles the input fields change event
     */
    changeHandler = (e) => {
        const{name, value} = e.target;
        let newState = Object.assign({}, this.state.errors);
        delete newState[e.target.name];
        this.setState({
            [name]: value,
            errors: newState
        }); 
    }
    /**
     * handles form submission
     */
    handleFormSubmit = () => {
        if(!this.valid()) {
            return false;
        }
        this.setState({postLoading: true});
        const { username, password } = this.state;
        ProviderService.login(username, password).then((status) => {
            if(status) {
                this.props.dispatch(AuthDispatch.setProviderDetail())
                window.location.href = '/provider/dashboard';
            } else {
                this.setState({postLoading: false, postMessage: 'Invalid login credentials'});
            }
        }).catch(() => this.setState({postLoading: false, postMessage: RespTrans['SOMETHING_WENT_WRONG']}));
    }
    /**
     * validates the form
     */
    valid = () => {
        const {username, password} = this.state;
        var errors = {};
        var valid = true;
  
        if (username === '') {
            valid = false;
            errors["username"] = "Please enter the username.";
        }
        if (password === '') {
            valid = false;
            errors["password"] = "Please enter the password.";
        }
        this.setState({
            errors: errors
        });
        return valid;
    }

    render() {
        var { postLoading, errors } = this.state;
        return (
            <Fluid className="page-wrapper">
            <Container>
                {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
                <Row>
                    <div className="col-sm-8">
                        <Card>
                            {this.state.loading === true && <Loading />}
                            {!this.state.loading && <>
                                {this.state.postMessage !== '' && <div className="alert alert-danger">
                                    {this.state.postMessage}
                                </div>}
                                <FormRow>
                                    <div className="col-xs-12">
                                        <label>Username*</label>
                                        <input type="text" className="form-control" name="username" value={this.state.username || ''}
                                            onChange={this.changeHandler} />
                                        {errors.username && <div className="help-block error">{errors.username}</div>}
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        <label>Password*</label>
                                        <input type="password" className="form-control" name="password" value={this.state.password || ''}
                                            onChange={this.changeHandler} />
                                        {errors.password && <div className="help-block error">{errors.password}</div>}
                                    </div>
                                </FormRow>                
                                <FormRow>
                                    <div className="col-xs-12">                    
                                        {!postLoading && <button className='mr-10 skibtn btn-secondary' onClick={e => this.handleFormSubmit(e)} type="button">
                                            Skicka!
                                        </button>}
                                        {postLoading && <LoadingButton />}
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="col-xs-12">
                                        Please contact  
                                        <a href="mailto:info@skinova.se" target="_top"> info@skinova.se</a> with login issues
                                    </div>
                                </FormRow>
                            </>}
                        </Card>
                    </div>
                </Row>
            </Container>
        </Fluid>);
    }
}

export default connect()(Login);