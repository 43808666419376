import {
    PROPERTY_FETCH_BEGIN,
    PROPERTY_FETCH_SUCCESS,
    PROPERTY_FETCH_FAILURE,
    PROPERTY_DELETE,
    RESET_PROPERTY
} from "../actions/propertyActions";

const initialState = {
    properties: [],
    loading: false,
    error: null,
    listShouldUpdate: true,
    curPage: 1
};

export default function propertyReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_PROPERTY: 
            return {
                properties: [],
                loading: false,
                error: null,
                listShouldUpdate: true,
                curPage: 1
            }
        case PROPERTY_FETCH_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case PROPERTY_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                properties: action.payload.properties,
                listShouldUpdate: false
            };

        case PROPERTY_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                properties: [],
                listShouldUpdate: true
            };
        case PROPERTY_DELETE:
            return {
                ...state,
                properties: action.payload
            };
            
        default:
            return state;
    }
}
