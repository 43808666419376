import { COMMENT_FETCH } from "../actions/bookingCommentAction";

const initialState = {
    comments: [],
};

export default function bookingCommentReducer(state = initialState, action) {
    switch (action.type) {
        case COMMENT_FETCH:
            return {
                ...state,
                comments: action.payload.data,
            };
            
        default:
            return state;
    }
}