import Prismic from 'prismic-javascript';

function PrismicByParam(param, value) {
    return new Promise((resolve, reject) => {
        Prismic.getApi("https://skinova.prismic.io/api/v2").then(api => {
            return api.query(
                Prismic.Predicates.at(param, value)
            );
        }).then(response => resolve(response));
    })
}

export default PrismicByParam;