import React from 'react';
import FormContext from './FormContext';

const Password = ({ label, value, name, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            const errMsgShow = context? context.errMsgShow: true;
            return <>
            {label && <label>{label}</label>}
            <input type="password" 
                value={value || ''}
                className={`form-control ${errors[name]? 'has-error': ''}`} 
                name={name}
                {...props}
                onChange={changeHandler} />
            {(errMsgShow && errors[name]) && <div className="help-block error">{errors[name]}</div>}
            </>
        }}
    </FormContext.Consumer>
}
export default Password;