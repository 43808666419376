import React from 'react';

function Card(props) {
    return (
        <div {...props} className={`info-list-item ${props.className || ''}`}>
            <div className="item-body">
                {props.children}
            </div>            
        </div>
    );
}
export default Card;