import React, { Component } from 'react';

class Static extends Component {
    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default Static;
