import {
    SEARCH_FETCH
} from "../actions/searchActions";

const initialState = {
    searchData: [],
};

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_FETCH:
            return {
                ...state,
                data: action.payload.data,
            };
            
        default:
            return state;
    }
}