import api from '../config/api.js';
import Ajax from './Ajax';

class EbookPayment {
    /**
     * gets the payments for the booking
     */
    getPaymentsForBooking(bookingNumber, params) {
        let {offset, limit} = params;
        var requestData = {
            operation: 'paymentList',
            offset: offset,
            limit: limit,
            bookingNumber: bookingNumber,
        };
        return Ajax.call(api.paymentService, requestData)
    }
    /**
     * deleted the payment
     */
    deletePayment(Id) {
        let requestData = {
            operation: 'deletePayment',
            Id: Id,
        };
        return Ajax.call(api.paymentService, requestData);
    }
    /**
     * calls the payment save API
     */
    savePayment(fields) {
        var requestData = {
            operation: "savePayment",
            fields
        };
        return Ajax.call(api.paymentService, requestData);
    }
    /**
     * calls the api to get purchase counts of the user
     */
    getUserPurchaseCount() {
        let requestData = {
            operation: "userPurchaseCount",
        };
        return Ajax.call(api.spPaymentService, requestData);
    }
    /**
     * aborts the xhr
     */
    abortXHR() {
        Ajax.abort();
    }
}
export default EbookPayment;