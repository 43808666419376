import React from 'react';
import { isEmpty } from '../../helpers';

function AdCell(props) {
    function createMarkup(markup) { return {__html: markup}; };
    let { ad } = props;
    if(!isEmpty(ad.CustomCode)) {
        var scripts = ad.CustomCode.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi);
        if(scripts) {
            let script = scripts[0];
            if(script.indexOf('<script') >= 0 ) {
                script = script.substring(script.indexOf('>') + 1, script.lastIndexOf('<'));
            }
            // eslint-disable-next-line no-new-func
            var a = new Function(script);
            a.apply(null);
        }
    }
    return (<>
        {isEmpty(ad.CustomCode)? <a href={ad.LinkUrl} target="_BLANK" rel="noopener noreferrer">
            <img src={ad.ImageUrl} alt={ad.Advertiser} className="img-responsive" />
        </a>: <div dangerouslySetInnerHTML={createMarkup(ad.CustomCode)} />}
        
    </>)
}
export default AdCell;