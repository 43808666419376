import React from 'react';
import { RichText } from 'prismic-reactjs';

/** renders the multi campaign on the home page */
function MultiCampaign(props) {
    return (
        <div className="container-fluid">
            <div className="row" style={{backgroundImage:`url(${props.primary['background-image'].url})`,backgroundSize:'cover',backgroundPosition:'center',position:'relative'}}>
            	{props.primary['background-type']==='Dark' &&     
				<div style={{position:'absolute',left:'0',top:'0',bottom:'0',right:'0',backgroundColor: '#000',opacity: '0.5'}}>
				</div>}
				
                <div className="container" style={{position:'relative'}}>
                    <div className="row">
                        <div className="container multi-campaign-template" style={{backgroundColor:props.primary['header-background-color'],textAlign:props.primary['title-align']}}>
                            
						{props.primary['title'][0].text !== "" && <span style={{color:props.primary['header-color']}}>{RichText.render(props.primary['title'])}</span>}
                            <div className="row equal" data-bind="items" style={{marginBottom:'30px',marginTop:props.primary['title'][0].text !== "" ? '0px' : '30px'}}>
                                {props.sliceItems.map((item, i) => (
																		
                                    <div className={`col-xs-12 col-sm-6 col-md-${item['display-type'] === 'Double' ? '6' : '3'} multi-campaign-type-${item['display-type']}`} style={{marginBottom:'10px'}} key={i}>
									
                                        <div className="" style={{backgroundColor:item['content-background-color'],color:item['content-text-color'],height:'100%'}}>											
											<div className={`multi-campaign-image xs-col-12 ${item['display-type']==='Double' ? 'md-col-8' : ''}`}>
											
												{item['label-text']!=null && item['label-text']!=='' && <div className="multi-campaign-image-label"></div>}
												{item['label-text']!=null && item['label-text']!=='' && <div className="multi-campaign-image-label-text">{item['label-text']}</div>}
	                                            <img src={item.image.url} alt={item.image.alt} className={item['image-align']} />
											</div>
                                            <div className={`multi-campaign-content xs-col-12 ${item['display-type']==='Double' ? 'md-col-4' : ''}`} style={{padding:(item['content-padding']==='Padding' || item['content-padding']==null) ? '10px' : '0px',paddingTop:'10px'}}>
                                                {RichText.render(item['content'])}
												{item['link-type']==='Button' && <button style={{marginTop:'20px',backgroundColor: '#fff',border: '1px solid gray',width: '100%',height:'40px'}}>
                                                    <a href={item.link.url} target="_blank" rel="noopener noreferrer" style={{color:'#000'}}>Läs mer</a>
                                                </button>}
												{item['link-type']==='Text' && <div style={{marginTop:'10px'}}><a href={item.link.url} target="_blank" rel="noopener noreferrer" style={{color:item['content-text-color'], fontWeight:'bold'}}>Läs mer ></a></div>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default MultiCampaign;