import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NoPage = () => {
    return (
        <Wrapper>
            <div className="container"> 
                <ErrorContainer> 
                    <Heading>404</Heading>
                    <Divider> 
                        <SubHeading>PAGE NOT FOUND.</SubHeading>
                        <Description>We Couldn't Find This Page</Description> 
                    </Divider> 
                    <Link to='/' className="skibtn btn-secondary"><i className="fa fa-home"></i> Home</Link>
                </ErrorContainer> 
            </div> 
        </Wrapper>
    );
}
const Wrapper = styled.div`
margin 20px;`

const ErrorContainer = styled.div`
	background: rgba(0,0,0,.05);
    text-align: center;
    padding: 20px;`

const Heading = styled.h1`
	font-size: 110px;
	font-weight: 300;
	text-align: center;`

const Divider = styled.div`
	margin-top: -27px;
	padding: 30px 0;`

const SubHeading = styled.h2`
	font-size: 58px;
	font-weight: 300;
	margin: 0;
	text-transform: uppercase;`

const Description = styled.p`
	color: #d2d2dc;
	font-size: 28px;
	margin: 20px 0;`
 
export default NoPage;