import api from "../config/api.js";
import { Auth } from "aws-amplify";
import {
    fetchUserBegin,
    fetchUserSuccess,
    fetchUserFailure,
    setAdmin,
    setProvider, setUserRole
} from "../actions/userActions.js";
import Ajax from "./Ajax";
import { isEmpty } from '../helpers';

const AuthDispatch = {
    getAuth,
    fetchUser,
    emptyUser,
    setAdminDetail,
    setProviderDetail, registerUserIfNotExists, setRole
};
/**
 * gets the authenticated user token
 */
function getAuth() {
    return Auth.currentAuthenticatedUser({
        bypassCache: false
    });
}
/**
 * fetches the authenticated user
 */
function fetchUser() {
    return dispatch => {
        dispatch(fetchUserBegin());
        if(isEmpty(this.props.authUser)) {
            let requestData = { operation: "getUserBySkiPortal" };
            Ajax.call(api.eBookUserService, requestData)
                .then(data => this.props.dispatch(fetchUserSuccess(data.user)))
                .catch(error => dispatch(fetchUserFailure(error)));
        }
    };
}

function registerUserIfNotExists(user) {
    var requestData = {
        Operation: "sync",
        SessionToken: user.signInUserSession.idToken.jwtToken,
        User: {
            CognitoId: user.attributes.sub
        }
    };
    return Ajax.call(api.userService, requestData);
}
/**
 * fetches the authenticated user
 */
function emptyUser() {
    return dispatch => {
        dispatch(fetchUserSuccess({}));
    };
}

function setAdminDetail() {
    return dispatch => {
        let admin = localStorage.getItem("admin");
        admin = admin===''? {} : JSON.parse(admin);
        dispatch(setAdmin(admin));
    };
}

function setProviderDetail() {
    return dispatch => {
        let provider = localStorage.getItem("provider");
        provider = provider===''? {} : JSON.parse(provider);
        dispatch(setProvider(provider));
    };
}

function setRole(role) {
    return dispatch => {
        dispatch(setUserRole(role));
    };
}
export default AuthDispatch;