import React from 'react';

/** renders the multi campaign on the home page */
function ButtonSection(props) {
    return (
        <div className="container">
            <div className="container-center" style={{marginBottom:'50px'}}>        
                {props.sliceItems.map((item, i) => (
                    <div className="container-inner-center" style={{margin:'50px 50px 0px 50px'}} key={i}>            
	                    <div className="skibtn btn-cms" style={{backgroundColor: item['background-color'],height:item['height']!=='' ? item['height'] : 'auto',width:item['width']!=='' ? item['width'] : 'auto', borderRadius:item['width']!=='' ? item['width'] : 'default',textAlign:'left',paddingTop:'27px'}}>
							{item['icon']!=null && item['icon'].url!=='' && <img src={item['icon'].url} style={{width:'24px'}} alt=""/>	}
							<a href={item.link.url} target="_blank" rel="noopener noreferrer" style={{color:item['text-color'],marginLeft:'10px',fontWeight:'bold'}}>								
								{item.text}</a>
							</div>						
                    </div>
                ))}                          
            </div>
        </div>
    );
}
export default ButtonSection;