/** gets all the app cookies */
function getAppCookies() {
    var pairs = document.cookie.split(";");
        var cookies = {};
        for (var i=0; i<pairs.length; i++) {
          var pair = pairs[i].split("=");
          cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
        }
        return cookies;
}
export default getAppCookies;