/** generates the header of week for price table  */
const header = {
    getWeekDays, dateRange, getWeekDaysOnly, dateRangeDep
}

/** gets the monday and sunday of the week for the selected date */
function getWeekDaysOnly(date) {
    var weekMap = [6, 0, 1, 2, 3, 4, 5];
    var now = new Date(date);
    var monday = new Date(now);
    monday.setDate(monday.getDate());
    var sunday = new Date(now);
    let lastDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let setSunday = sunday.getDate() - weekMap[sunday.getDay()] + 6;    
    if(setSunday > lastDate.getDate()) {
        setSunday = lastDate.getDate();
    }
    sunday.setDate(setSunday);
    return {monday, sunday};
}

/** gets the monday and sunday of the week for the selected date */
function getWeekDays(date) {
    var weekMap = [6, 0, 1, 2, 3, 4, 5];
    var now = new Date(date);
    var monday = new Date(now);
    monday.setDate(monday.getDate() - weekMap[monday.getDay()]);
    var sunday = new Date(now);
    sunday.setDate(sunday.getDate() - weekMap[sunday.getDay()] + 6);
    return {monday, sunday};
}

/** gets the array of dates for the monday and sunday */
function dateRange(monday, sunday) {
    let addFn = Date.prototype.addDays;
    var headers = [], dateObj = {};
    var current = new Date(monday);
    var i = 0;
    while (current <= sunday) {
        let date = new Date(current);
        dateObj.date = date.getTime();
        dateObj.showDate = date.toString("ddd, MMM dd");
        headers[i] = {...dateObj};
        current = addFn.call(current, 1);
        ++i;
    }
    
    return headers;
}

/** gets the array of dates for the monday and sunday */
function dateRangeDep(monday, sunday) {
    let addFn = Date.prototype.addDays;
    var headers = [];
    var current = new Date(monday);
    var i =1;
    headers[0] = '';

    while (current <= sunday) {
        let date = new Date(current);
        headers[i] = date.toString("ddd, MMM dd");
        current = addFn.call(current, 1);
        i++;
    }
    return headers;
}
export default header;